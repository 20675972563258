import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import Carousel from 'components/Carousel'
import Benefits from 'components/Benefits'

import Layout from 'components/Common/Layout'
import Button from 'components/Common/Button'
import { H2, H3, H5, P } from 'components/Common/Typography'
import WrappedContainer from 'components/Common/WrappedContainer'
import BorderOffsetBox from 'components/Common/BorderOffsetBox'

///////////////////////////////////////////////////////////////////////////////
// Styling

const PackageSelector = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 5rem;

  @media (max-width: 800px) {
    margin-bottom: 2rem;
  }
`

const Choice = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  margin: 0.5rem 1rem;
  text-align: center;
  border: 1px solid #cccccc;
  letter-spacing: 2px;
  font-family: Acrom;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.2s ease all;

  @media (max-width: 800px) {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
  }

  ${props =>
    props.selected &&
    `
    border-color: #000000;
  `}
`

const PackageContainer = styled.div`
  display: none;
  flex-flow: row wrap;
  flex: 1;
  margin-bottom: 2rem;

  @media (max-width: 800px) {
    flex-flow: column nowrap;
    margin-bottom: 0;
  }

  ${props =>
    props.selected &&
    `
    display: flex;
  `}
`

const Package = styled.div`
  flex: 2;
  display: flex;
  align-items: flex-start;

  @media (max-width: 800px) {
    margin-bottom: 3rem;
  }
`

const PackageDetails = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding-right: 5rem;

  @media (max-width: 800px) {
    padding-right: 0;
  }
`

const Amenity = styled.div`
  margin: 1rem;

  @media (max-width: 800px) {
    margin: 0 1rem 1rem 0;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class PackagesPage extends React.Component {
  state = {
    selected: 'HOT DESK',
  }

  ///////////////
  // Functions //
  ///////////////

  selectPackage(_package) {
    this.setState({ selected: _package })
  }

  ////////////
  // Render //
  ////////////

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Packages | Common Ground</title>
        </Helmet>

        <section>
          <Carousel
            height="65vh"
            slides={[{ title: 'OUR PLANS', image: '/images/top-banner.jpg', darken: true }]}
          />
        </section>

        <section>
          <WrappedContainer background="#ffffff">
            <H5 style={{ width: '100%', textAlign: 'center' }}>FIND YOUR IDEAL PLAN</H5>

            {/* Options for user to select different Package types */}
            <PackageSelector>
              {DEFAULT_PACKAGES.map(_package => (
                <Choice
                  selected={this.state.selected === _package.title}
                  onClick={() => this.selectPackage(_package.title)}
                  key={`select-package-${_package.title}`}
                >
                  {_package.title}
                </Choice>
              ))}
            </PackageSelector>

            {/* List of the Packages (but only display the selected one) */}
            {DEFAULT_PACKAGES.map(_package => (
              <PackageContainer
                selected={this.state.selected === _package.title}
                key={`package-listing-${_package.title}`}
              >
                <Package>
                  <PackageDetails>
                    <H2>{_package.title}</H2>
                    <P>{_package.description}</P>

                    <Button style={{ marginTop: '2rem' }}>ENQUIRE</Button>
                  </PackageDetails>
                </Package>

                <BorderOffsetBox>
                  {_package.amenities.map(amenity => (
                    <Amenity key={`package-${_package.title}-amenity-${amenity.title}`}>
                      <H3>{amenity.title}</H3>
                      <P>{amenity.description}</P>
                    </Amenity>
                  ))}
                </BorderOffsetBox>
              </PackageContainer>
            ))}
          </WrappedContainer>
        </section>

        <section>
          <WrappedContainer background="#eeeeee">
            <H2>MEMBER BENEFITS</H2>
            <P>
              Every membership at Common Ground comes with these benefits to support you and your
              business.
            </P>

            <Benefits />
          </WrappedContainer>
        </section>
      </Layout>
    )
  }
}

export default PackagesPage

// data

const DEFAULT_PACKAGES = [
  {
    title: 'HOT DESK',
    description: `Ideal for remote workers and freelancers who value flexibility and social networking opportunities.\n\nAccess any desk within the hot desking zone or common area.`,
    image: '/images/hot-desk.jpg',
    price: 399,
    amenities: [
      {
        title: 'ACCESS',
        description: '8am - 7pm every day',
      },
      {
        title: 'MEETING ROOMS',
        description: '2 hours complimentary\n(additional hours at discounted rates)',
      },
      {
        title: 'PRINTING',
        description: `80 pages black & white (or)\n20 pages colour`,
      },
    ],
  },
  {
    title: 'FIXED DESK',
    description: `Small startups and teams can benefit from a collaborative open working environment with a little more privacy.\n\nReserved desks, chairs, trash cans, and lockable cabinets are accessible 24/7.`,
    image: '/images/fixed-desk.jpg',
    price: 599,
    amenities: [
      {
        title: 'ACCESS',
        description: '24/7 every day',
      },
      {
        title: 'MEETING ROOMS',
        description: '5 hours complimentary\n(additional hours at discounted rates)',
      },
      {
        title: 'PRINTING',
        description: '120 pages of black & white (or)\n30 pages of colour',
      },
      {
        title: 'ADMIN',
        description: 'Business address\nMail handling',
      },
    ],
  },
  {
    title: 'PRIVATE OFFICE',
    description: `Small or medium-sized companies and satellite teams who want a space of their own can work from secure offices that balance privacy and transparency.\n\nPrivate offices are accessible 24/7.`,
    image: '/images/private-office.jpg',
    price: 899,
    amenities: [
      {
        title: 'ACCESS',
        description: '24/7 every day',
      },
      {
        title: 'MEETING ROOMS',
        description: '12 hours complimentary\n(additional hours at discounted rates)',
      },
      {
        title: 'PRINTING',
        description: '500 pages of black & white (or)\n40 pages of colour',
      },
      {
        title: 'ADMIN',
        description: 'Business address\nMail handling\nDiscounted international calls',
      },
    ],
  },
]
