import React from 'react'
import styled from 'styled-components'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  width: 160px;
  flex-basis: 160px;
  max-width: 160px;
  padding: 1rem;
  margin: 1rem;
  box-sizing: border-box;

  @media (max-width: 800px) {
    max-width: 50%;
    margin: 0 0 1rem 0;
    text-align: center;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Tenant = props => (
  <Container>
    <Image src={props.image} />
  </Container>
)

export default Tenant
