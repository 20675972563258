import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Select from 'react-select'
import { DateTime } from 'luxon'
import ReactDatePicker from 'react-datepicker'
import { isMobile } from 'react-device-detect'
import SwipeableViews from 'react-swipeable-views'
import {
  IoIosRadioButtonOff,
  IoIosRadioButtonOn,
  IoMdCheckmark,
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
  IoIosWarning,
} from 'react-icons/io'
import querystring from 'querystring'
import { get, groupBy, mapValues } from 'lodash'
import ReactTags from 'react-tag-autocomplete'

import qrCodeMappings from './qrCodeMappings'
import { submitCovid19DeclarationCheckIn } from '../api'
import { optionify } from 'utils'

import Cookies from '../js-cookie.js'

const logoImg = require('../images/logo.png')

//
// ─── CONSTANTS ──────────────────────────────────────────────────────────────────
//

const VENUES = []
const COUNTRY_CODE_TO_FULL = { MY: 'Malaysia', PH: 'Philippines', TH: 'Thailand' }

const groupedByCountry = groupBy(qrCodeMappings, 'country')

for (let country in groupedByCountry) {
  VENUES.push({
    label: COUNTRY_CODE_TO_FULL[country],
    options: optionify(groupedByCountry[country], 'name').map(({ label, value }) => ({
      label: label,
      value: value,
    })),
  })
}

const PURPOSES_OF_VISIT = [
  {
    label: 'Member',
    value: 'Member',
  },
  {
    label: 'Guest',
    value: 'Guest',
  },
  {
    label: 'Contractor/Supplier',
    value: 'Contractor/Supplier',
  },
  {
    label: 'CG Staff',
    value: 'CG Staff',
  },
]

//
// ─── STYLING ────────────────────────────────────────────────────────────────────
//

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #403e41;
  font-family: Calibre, sans-serif;
  color: ${props => props.theme.cgGreen};
`

const SwipeableView = styled.div`
  flex: 1;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;

  h1 {
    font-family: Acrom, sans-serif;
    font-size: 1.6rem;
    letter-spacing: -1px;
  }

  p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
`

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
`

const Landing = styled.div`
  width: 100%;
  background-color: ${props => props.theme.cgYellow};
  padding: 1rem;
`

const Logo = styled.img`
  width: 120px;
  max-width: 100%;
`

const Form = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;
`

const InputField = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;

  & > label {
    font-weight: bold;
    margin-bottom: 0.5rem;

    ${props =>
      props.required &&
      `
      &:after {
        content: '*';
        color: #ff5959;
        margin-left: 0.2rem;
      }
    `};
  }

  & > input,
  & > * > input,
  & > textarea {
    border: 1px solid #c8d2d0;
    border-radius: 3px;
    padding: 0.5rem;
    font-size: 1.15rem;
    font-family: inherit;
  }
`

const DatePicker = styled(ReactDatePicker)`
  border: 1px solid #c8d2d0;
  border-radius: 3px;
  padding: 0.5rem;
  font-size: 1rem;
`

const Radio = styled.div`
  display: flex;
  flex-flow: row nowrap;

  .option {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;

    .label {
      font-size: 1.2rem;
      padding-left: 0.25rem;
    }
  }
`

const Actions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

const Action = styled.button`
  background-color: ${props => (props.back ? 'transparent' : props.theme.cgYellow)};
  border: none;
  border-radius: 3px;
  padding: 1rem 1.5rem;
  font-size: 1.3rem;
  font-family: inherit;

  &:disabled {
    opacity: 0.6;
  }
`

//
// ─── COMPONENT DEFINITION ───────────────────────────────────────────────────────
//

class Covid19CheckInPage extends React.Component {
  constructor(props) {
    super(props)

    const urlSearchParams = querystring.parse(props.history.location.search.substr(1))

    this.state = {
      fields: {
        // Declaration fields
        name: '',
        email: '',
        phone: '',
        nationality: '',
        sex: '',
        address: '',
        q1: null,
        q2: [],
        q3: null,
        q4: null,

        // Check in fields
        venue: null,
        venue_code: null,
        venue_country: null,
        temperature: null,
        date: null,
        purpose: PURPOSES_OF_VISIT[0],
      },
      step: 1,

      env: urlSearchParams.env || 'production',
      cookiedFields: Cookies.getJSON('covid-19-declaration'),
      formAcknowledged: false,
      windowHeight: window.innerHeight,
      error: null,
    }
  }

  //
  // ─── LIFECYCLE ──────────────────────────────────────────────────────────────────
  //

  componentDidMount() {
    this._prefillFields()

    // Add page refresh confirmation prompt (just as additional "feedback")
    window.addEventListener('beforeunload', event => {
      const { step } = this.state

      if (step > 1) {
        event.returnValue = 'You will need to redo Check In. Are you sure?'
      }
    })

    // Resize listener required for SwipeableViews otherwise it breaks display
    window.addEventListener('resize', () => {
      this.setState({ windowHeight: window.innerHeight })
    })
  }

  //
  // ─── METHODS ────────────────────────────────────────────────────────────────────
  //

  _sanitizeObjectValues = obj => {
    return mapValues(obj, value => {
      if (typeof value === 'string') {
        return value.trim()
      }
      return value
    })
  }

  _prefillFields = () => {
    if (!!this.state.cookiedFields) {
      this.setState({
        fields: {
          ...this.state.cookiedFields,
          // q1: null,
          // q2: [],
          // q3: null,
          // q4: null,
          // q5: null,
          // venue: undefined,
          temperature: undefined,
          date: undefined,
          purpose: PURPOSES_OF_VISIT[0],
        },
      })
    }
  }

  _onChangeField = field => async value => {
    await this.setState({ fields: { ...this.state.fields, [field]: value } })

    if (!!this.state.cookiedFields) {
      Cookies.setJSON('covid-19-declaration', this._sanitizeObjectValues(this.state.fields))
    }

    // Make sure q2 data gets "purged" if the user changes q1 answer
    // (to make sure unnecessary q2 answer doesn't get stored/sent to backend)
    if (field === 'q1' && value === 'Yes') {
      await this.setState({ fields: { ...this.state.fields, q2: [] } })
    }
  }

  _toggleCookiedFields = () => {
    if (!!this.state.cookiedFields) {
      Cookies.remove('covid-19-declaration')
    } else {
      Cookies.setJSON('covid-19-declaration', this._sanitizeObjectValues(this.state.fields))
    }

    this.setState({ cookiedFields: !this.state.cookiedFields })
  }

  _toggleFormAcknowledged = () => {
    this.setState({ formAcknowledged: !this.state.formAcknowledged })
  }

  _onClickNext = () => {
    const { fields } = this.state

    // Validate the form inputs
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape

    if (!fields.email || !fields.email.trim().match(emailRegex)) {
      return alert('Please provide a valid email address!')
    }

    this.setState({ step: 2 })
  }

  _onClickBack = () => {
    this.setState({ step: 1 })
  }

  _onClickCheckIn = async () => {
    try {
      let { fields, env } = this.state

      fields = this._sanitizeObjectValues(fields)

      await this.setState({ loading: 'checkin' })

      // Make sure temperature is a proper number
      if (isNaN(fields.temperature)) {
        await alert('Please make sure temperature is a proper number!')

        await this.setState({ loading: false })

        return null
      }

      // Prevent troll temperature values
      if (Number(fields.temperature) < 35) {
        await alert('You may be experiencing hypothermia.\n\nGet some help!')

        await this.setState({ loading: false })

        return null
      }

      // POST Covid Check In API
      await submitCovid19DeclarationCheckIn(
        {
          ...fields,
          q2: fields.q2.length > 0 ? fields.q2.map(t => t.name) : undefined, // convert from 'react-tag-autocomplete' structure
          venue: fields.venue.value.name, // convert from 'react-select' structure
          venue_code: fields.venue.value.code,
          venue_country: fields.venue.value.country,
          purpose: fields.purpose.value,
        },
        { env: env }
      )

      await this.setState({ loading: false, step: 3 })
    } catch (error) {
      await alert('Unable to check in at the moment. Please try again.')

      await this.setState({ loading: false })
    }
  }

  //
  // ─── RENDER ─────────────────────────────────────────────────────────────────────
  //

  render() {
    const { step, windowHeight } = this.state

    return (
      <>
        <Helmet>
          <title>Covid-19 Check In | Common Ground</title>
          <style>{`
            html {
              font-size: 16px;
            }
          `}</style>
        </Helmet>

        <Page>
          <SwipeableViews
            slideStyle={{
              overflowY: 'scroll',
              minHeight: windowHeight,
              maxHeight: windowHeight,
            }}
            disabled
            index={step - 1}
          >
            {this._renderDeclarationForm()}
            {this._renderCheckInForm()}
            {this._renderCheckedInResult()}
          </SwipeableViews>
        </Page>
      </>
    )
  }

  //
  // ─── RENDER DECLARATION FORM ────────────────────────────────────────────────────
  //

  _renderDeclarationForm = () => {
    const { fields, formAcknowledged } = this.state

    let submittable = true

    if (!fields.q1) submittable = false
    if (fields.q1 === 'Yes' && fields.q2.length === 0) submittable = false
    if (!fields.q3) submittable = false
    if (!fields.q4) submittable = false

    if (!fields.name) submittable = false
    if (!fields.email) submittable = false
    if (!fields.phone) submittable = false
    if (!fields.nationality) submittable = false
    if (!fields.address) submittable = false
    if (!formAcknowledged) submittable = false

    return (
      <SwipeableView>
        <Landing>
          <Container>
            <Logo src={logoImg} alt="Common Ground" />

            <h1 className="mt-5 mb-0">Self Declaration Form</h1>

            <p>
              To help prevent the spread of novel Coronavirus into our community and reduce the risk
              of exposure to our crew, members and visitors, please provide the information below as
              accurate as possible.
            </p>

            <p>
              Thank you!
              <span role="img" aria-label="Smile" aria-labelledby="" className="ml-2">
                😊
              </span>
            </p>
          </Container>
        </Landing>

        <Form>
          <Container>
            <div style={{ marginTop: '-1rem', textAlign: 'right', color: '#ff5959' }}>
              * Required
            </div>

            {/* Name */}

            <InputField required>
              <label>Name</label>
              <input
                required
                type="text"
                value={fields.name}
                onChange={({ target }) => this._onChangeField('name')(target.value)}
              />
            </InputField>

            {/* Email address */}

            <InputField required>
              <label>Email</label>
              <input
                required
                type="email"
                value={fields.email}
                onChange={({ target }) => this._onChangeField('email')(target.value)}
              />
            </InputField>

            {/* Phone number */}

            <InputField required>
              <label>Phone Number</label>
              <input
                required
                type="tel"
                value={fields.phone}
                onChange={({ target }) => this._onChangeField('phone')(target.value)}
              />
            </InputField>

            <div className="d-flex flex-row row-nowrap">
              {/* Nationality */}

              <InputField required style={{ flex: 4 }}>
                <label>Nationality</label>
                <input
                  required
                  type="text"
                  value={fields.nationality}
                  onChange={({ target }) => this._onChangeField('nationality')(target.value)}
                  className="w-100"
                />
              </InputField>

              <div style={{ width: '1rem' }} />

              {/* Sex */}

              <InputField style={{ flex: 3 }}>
                <label>Sex</label>
                <input
                  type="text"
                  value={fields.sex}
                  onChange={({ target }) => this._onChangeField('sex')(target.value)}
                  className="w-100"
                />
              </InputField>
            </div>

            {/* Address */}

            <InputField required>
              <label>Address/Residence</label>
              <textarea
                required
                rows={4}
                value={fields.address}
                onChange={({ target }) => this._onChangeField('address')(target.value)}
              />
            </InputField>

            <div style={{ height: '1.5rem' }} />

            {/* Question 1 */}

            <InputField required>
              <label>
                Have you travelled out of the country or across state within the past 15 days?
              </label>
              <Radio>
                <div className="option" onClick={() => this._onChangeField('q1')('Yes')}>
                  {fields.q1 === 'Yes' ? (
                    <IoIosRadioButtonOn size={24} />
                  ) : (
                    <IoIosRadioButtonOff size={24} />
                  )}
                  <div className="label">Yes</div>
                </div>

                <div className="option" onClick={() => this._onChangeField('q1')('No')}>
                  {fields.q1 === 'No' ? (
                    <IoIosRadioButtonOn size={24} />
                  ) : (
                    <IoIosRadioButtonOff size={24} />
                  )}
                  <div className="label">No</div>
                </div>
              </Radio>
            </InputField>

            {/* Question 2 */}

            {fields.q1 === 'Yes' && (
              <InputField required>
                <label>Please indicate all countries / states / regions travelled:</label>
                <ReactTags
                  tags={fields.q2}
                  handleAddition={tag => {
                    if (tag.name.trim() === '') return false
                    if (fields.q2.map(t => t.name).indexOf(tag.name) !== -1) return false
                    this._onChangeField('q2')([...fields.q2, tag])
                  }}
                  handleDelete={index => {
                    const tags = fields.q2.slice(0)
                    tags.splice(index, 1)
                    this._onChangeField('q2')(tags)
                  }}
                  allowNew
                  addOnBlur={true}
                  delimiterChars={[',', '/']}
                  autoresize={false}
                  placeholder="Add location..."
                />
              </InputField>
            )}

            {/* Question 3 */}

            <InputField required>
              <label>
                Have you been in contact with anyone who is suspected / tested positive for COVID-19
                or come from a COVID-19 cluster?
              </label>
              <Radio>
                <div className="option" onClick={() => this._onChangeField('q3')('Yes')}>
                  {fields.q3 === 'Yes' ? (
                    <IoIosRadioButtonOn size={24} />
                  ) : (
                    <IoIosRadioButtonOff size={24} />
                  )}
                  <div className="label">Yes</div>
                </div>

                <div className="option" onClick={() => this._onChangeField('q3')('No')}>
                  {fields.q3 === 'No' ? (
                    <IoIosRadioButtonOn size={24} />
                  ) : (
                    <IoIosRadioButtonOff size={24} />
                  )}
                  <div className="label">No</div>
                </div>
              </Radio>
            </InputField>

            {/* Question 4 */}

            <InputField required>
              <label>
                Have you had any symptoms of fever, colds, cough, sore throat, headache, diarrhea or
                difficulty in breathing over the past 14 days?
              </label>
              <Radio>
                <div className="option" onClick={() => this._onChangeField('q4')('Yes')}>
                  {fields.q4 === 'Yes' ? (
                    <IoIosRadioButtonOn size={24} />
                  ) : (
                    <IoIosRadioButtonOff size={24} />
                  )}
                  <div className="label">Yes</div>
                </div>

                <div className="option" onClick={() => this._onChangeField('q4')('No')}>
                  {fields.q4 === 'No' ? (
                    <IoIosRadioButtonOn size={24} />
                  ) : (
                    <IoIosRadioButtonOff size={24} />
                  )}
                  <div className="label">No</div>
                </div>
              </Radio>
            </InputField>

            {/* Question 5 */}

            <InputField required>
              <label>
                Have you undergone the 14-day quarantine period and/or have been tested for COVID-19
                within the past 30 days?
              </label>
              <Radio>
                <div className="option" onClick={() => this._onChangeField('q5')('Yes')}>
                  {fields.q5 === 'Yes' ? (
                    <IoIosRadioButtonOn size={24} />
                  ) : (
                    <IoIosRadioButtonOff size={24} />
                  )}
                  <div className="label">Yes</div>
                </div>

                <div className="option" onClick={() => this._onChangeField('q5')('No')}>
                  {fields.q5 === 'No' ? (
                    <IoIosRadioButtonOn size={24} />
                  ) : (
                    <IoIosRadioButtonOff size={24} />
                  )}
                  <div className="label">No</div>
                </div>
              </Radio>
            </InputField>

            <div style={{ height: '1rem' }} />

            {/* Acknowledgement */}

            <div
              className="d-flex align-items-start noselect"
              onClick={this._toggleFormAcknowledged}
            >
              <div
                style={{
                  border: '1px solid #19423a',
                  width: 24,
                  height: 24,
                  borderRadius: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                  marginTop: '0.3rem',
                }}
              >
                {!!this.state.formAcknowledged && <IoMdCheckmark size={22} />}
              </div>

              <label className="ml-2">
                <span>
                  I hereby declare that the above statements are true, accurate and complete. By
                  accomplishing this form, I hereby authorize Common Ground Works to collect and
                  process the data indicated herein for the purpose of effecting control of the
                  COVID19 crisis.
                </span>
                <br />
                <span>
                  I understand that my personal information is protected by R.A. 10173, Data Privacy
                  Act of 2012 and that I am required by R.A. 11469, Bayanihan to Heal as One Act, to
                  provide truthful information.
                </span>
              </label>
            </div>

            <div style={{ height: '1.5rem' }} />

            {/* Actions */}

            <Actions>
              <div
                className="d-flex align-items-center noselect"
                onClick={this._toggleCookiedFields}
              >
                <div
                  style={{
                    border: '1px solid #19423a',
                    width: 24,
                    height: 24,
                    borderRadius: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {!!this.state.cookiedFields && <IoMdCheckmark size={22} />}
                </div>

                <label className="ml-2">
                  <span>Remember me</span>
                  <br />
                  <span style={{ color: '#a5a5a5' }}>(Requires cookies)</span>
                </label>
              </div>

              <Action onClick={this._onClickNext} disabled={!submittable}>
                Next
              </Action>
            </Actions>
          </Container>
        </Form>
      </SwipeableView>
    )
  }

  //
  // ─── RENDER CHECK IN ────────────────────────────────────────────────────────────
  //

  _renderCheckInForm = () => {
    const { fields, loading } = this.state

    let submittable = true

    if (!fields.venue) submittable = false
    if (!fields.temperature) submittable = false
    if (!fields.date) submittable = false
    if (!fields.purpose) submittable = false

    return (
      <SwipeableView>
        <Landing>
          <Container>
            <Logo src={logoImg} alt="Common Ground" />

            <h1 className="mt-5 mb-0">Check In</h1>

            <p>
              Forms have to be accomplished on a daily basis and should be done a day before entry
              while observing the cut off time at 5PM.
            </p>

            <p>Entry will be denied to those who do not comply with the following:</p>
            <ul className="mt-0">
              <li>No Face Mask</li>

              <li>No Face Shield</li>

              <li>No Accomplished Health and Travel Declaration Form</li>
            </ul>
          </Container>
        </Landing>

        <Form>
          <Container>
            {/* Venue dropdown */}

            <InputField>
              <label>Venue</label>

              <Select
                options={VENUES}
                onChange={venue => this._onChangeField('venue')(venue)}
                value={fields.venue}
                placeholder="Select a venue..."
                isSearchable={false}
              />
            </InputField>

            {/* Body temperature */}

            <InputField>
              <label>Body temperature</label>
              <div className="d-flex justify-content-start align-items-center">
                <input
                  type="number"
                  values={fields.temperature}
                  onChange={({ target }) => this._onChangeField('temperature')(target.value)}
                  style={{ width: 90, marginRight: '0.3rem' }}
                />
                °C
              </div>
            </InputField>

            <InputField>
              <label>Date of visit</label>
              <DatePicker
                selected={fields.date}
                onChange={date => this._onChangeField('date')(date)}
                minDate={DateTime.local()
                  .startOf('day')
                  .plus({ days: 1 })
                  .toJSDate()}
                dateFormat="EEEE, dd/MM/yyyy"
                placeholderText="Pick a day"
                withPortal={isMobile ? true : false}
              />
            </InputField>

            {/* Purpose of visit */}

            <InputField>
              <label>Purpose of visit</label>
              <Select
                options={PURPOSES_OF_VISIT}
                onChange={purpose => this._onChangeField('purpose')(purpose)}
                value={fields.purpose}
                placeholder="Select a purpose..."
                isSearchable={false}
              />
            </InputField>

            <div style={{ flex: 1 }} />

            {/* Actions */}

            <Actions>
              <Action
                back
                onClick={this._onClickBack}
                disabled={loading === 'checkin'}
                style={{ paddingLeft: '0.5rem' }}
              >
                Back
              </Action>

              <Action
                onClick={this._onClickCheckIn}
                disabled={loading === 'checkin' || !submittable}
              >
                {loading === 'checkin' ? 'Loading...' : 'Check In'}
              </Action>
            </Actions>
          </Container>
        </Form>
      </SwipeableView>
    )
  }

  //
  // ─── RENDER CHECKED IN RESULT ───────────────────────────────────────────────────
  //

  _renderCheckedInResult = () => {
    const deniedRed = '#ce2434'
    const warningYellow = '#e4c51b'
    const successGreen = '#5fce0d'
    const { fields } = this.state

    let warnings = {}

    if (Number(fields.temperature) >= 37.4) warnings.temperature = true
    if (fields.q1 === 'Yes') warnings.overseasTravel = true
    if (fields.q3 === 'Yes') warnings.clusterContact = true
    if (fields.q4 === 'Yes') warnings.covidSymptoms = true

    const success = Object.keys(warnings).length === 0
    const warning =
      !!warnings.overseasTravel &&
      !!!warnings.temperature &&
      !!!warnings.clusterContact &&
      !!!warnings.covidSymptoms
    const time = fields.date ? DateTime.fromJSDate(fields.date).toFormat('cccc, dd/MM/yyyy') : '-'

    return (
      <SwipeableView>
        <Landing>
          <Container>
            <Logo src={logoImg} alt="Common Ground" />
          </Container>
        </Landing>

        <Form>
          <Container className="align-items-center">
            <div className="status">
              {success ? (
                <>
                  <IoIosCheckmarkCircleOutline size={70} color={successGreen} />
                  <h1 style={{ color: successGreen }}>Check In Successful</h1>
                </>
              ) : warning ? (
                <>
                  <IoIosCheckmarkCircleOutline size={70} color={warningYellow} />
                  <h1 style={{ color: warningYellow }}>Check In Successful</h1>
                </>
              ) : (
                <>
                  <IoIosCloseCircleOutline size={70} color={deniedRed} />
                  <h1 style={{ color: deniedRed }}>Check In Denied</h1>
                </>
              )}

              <h2>{time}</h2>
            </div>

            <div className="details">
              {/* Name */}

              <div className="detail-row">
                <label>Name:</label>
                <span>{fields.name}</span>
              </div>

              {/* Venue */}

              <div className="detail-row">
                <label>Venue:</label>
                <span>{fields.venue ? fields.venue.label : ''}</span>
              </div>

              {/* Date */}

              <div className="detail-row">
                <label>Date of visit:</label>
                <span>{time}</span>
              </div>

              {/* Purpose of visit */}

              <div className="detail-row">
                <label>Purpose of visit:</label>
                <span>{get(fields, 'purpose.value', '-')}</span>
              </div>

              {/* Body temperature */}

              <div className="detail-row">
                <label>Body temperature:</label>
                <span style={{ color: !warnings.temperature ? successGreen : deniedRed }}>
                  {fields.temperature} °C
                </span>
              </div>

              <div className="warnings">
                {/* Overseas travel */}

                {!!warnings.overseasTravel && (
                  <>
                    <div className="warning yellow">
                      <IoIosWarning color={warningYellow} />
                      {'Travelled within past 15 days to '}
                      <b>{fields.q2.map(t => t.name).join(', ')}</b>
                    </div>
                  </>
                )}

                {/* Cluster contact */}

                {!!warnings.clusterContact && (
                  <div className="warning red">*Had contact with COVID-19 person/cluster</div>
                )}

                {/* Covid symptoms */}

                {!!warnings.covidSymptoms && (
                  <div className="warning red">*Had experienced COVID-19 symptoms</div>
                )}
              </div>
            </div>

            <div style={{ flex: 1 }} />

            <div className="reminder">
              You may close this window
              {/* Please present this screen to your venue's staff upon each entry.
              <br />
              You may keep this screen open for subsequent same-day entries. */}
            </div>

            {/* <div className="reminder">
              Please present this screen to your venue's staff upon each entry.
              <br />
              You may keep this screen open for subsequent same-day entries.
            </div> */}
          </Container>
        </Form>

        <style>{`
          h1 {
            margin: 0;
          }

          h2 {
            font-weight: normal;
            margin: 0;
          }

          .status {
            width: 100%;
            text-align: center;
            border-bottom: 1px solid #e9e9e9;
            padding: 0 1rem 2rem 1rem;
          }

          .details {
            width: 100%;
            text-align: center;
            font-size: 1.2rem;
            padding: 2rem 1rem 1rem 1rem;
          }

          .detail-row {
            margin-bottom: 1rem;
          }

          .detail-row span {
            font-weight: bold;
            margin-left: 0.3rem;
          }

          .warnings {
            margin-top: 3rem;
          }

          .warning {
            font-size: 1.1rem;
            margin-bottom: 1rem;
          }

          .warning.yellow {
            color: ${warningYellow};
          }

          .warning.red {
            color: ${deniedRed};
          }

          .reminder {
            text-align: center;
            padding: 1rem;
            font-size: 1.1rem;
            line-height: 1.4rem;
            opacity: 0.8;
          }
        `}</style>
      </SwipeableView>
    )
  }
}

export default Covid19CheckInPage
