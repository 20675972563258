import React from 'react'
import styled from 'styled-components'
// import SwipeableViews from 'react-swipeable-views'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Heading = styled.div`
  font-family: Acrom;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: -0.7px;
  margin-bottom: 0.5rem;
`

// const Text = styled.div`
//   font-family: Calibre;
//   font-size: 1rem;
//   margin-bottom: 0.5rem;
//   white-space: pre-wrap;
// `

// const PricingCategories = styled.div`
//   display: flex;
//   flex-flow: row nowrap;
//   justify-content: flex-start;
//   margin: 0 ${props => props.theme.MOBILE.PAD};

//   ${Text} {
//     margin-right: 1rem;
//     margin-bottom: 1rem;
//   }
// `

// const PricingCategory = styled(Text)`
//   opacity: 0.7;
//   padding-bottom: 0.2rem;

//   ${props =>
//     props.active &&
//     `
//     opacity: 1;
//     border-bottom: 2px solid ${props.theme.cgGreen};
//     margin-bottom: -2px;
//   `}
// `

const Packages = styled.div`
  margin: 0 ${props => props.theme.MOBILE.PAD};
`

const PackageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 120px;
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: ${props => props.theme.MOBILE.BORDER_RADIUS};
  overflow: hidden;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  :after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.35));
  }
`

const Type = styled(Heading)`
  display: inline-flex;
  margin-right: 0.5rem;
`

const Price = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.5rem;
  font-weight: 100;
`

const Currency = styled.span`
  font-size: 0.8rem;
  margin: 0 0.3rem;
`

const Details = styled.div`
  position: relative;
`

const Subtitle = styled.div`
  font-size: 1rem;
  opacity: 0.9;
  margin-bottom: 0.3rem;
`

const Description = styled.div`
  font-size: 1rem;
  opacity: 0.7;
`

const MoreInfo = styled.div``

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Package = props => (
  <PackageWrapper>
    <Background image={props.image} />
    <Details>
      <Type>{props.title}</Type>
      {props.currency && props.price && (
        <Price>
          {props.price} <Currency>{props.currency}</Currency>
        </Price>
      )}
      <Subtitle>{props.subtitle}</Subtitle>
      <Description>{props.description}</Description>
      <MoreInfo />
    </Details>
  </PackageWrapper>
)

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class Pricing extends React.Component {
  state = {
    activeIndex: 0,
  }

  setActiveIndex(index) {
    this.setState({ activeIndex: index })
  }

  render() {
    // const { activeIndex } = this.state

    return (
      <>
        {/* <PricingCategories>
          <PricingCategory active={activeIndex === 0} onClick={() => this.setActiveIndex(0)}>
            Packages
          </PricingCategory>
          <PricingCategory active={activeIndex === 1} onClick={() => this.setActiveIndex(1)}>
            Meetings
          </PricingCategory>
          <PricingCategory active={activeIndex === 2} onClick={() => this.setActiveIndex(2)}>
            Passes
          </PricingCategory>
        </PricingCategories> */}

        {/* <SwipeableViews
          index={activeIndex}
          onChangeIndex={index => this.setActiveIndex(index)}
          resistance
          animateHeight
        > */}
        <Packages>
          {['hot_desk', 'fixed_desk', 'private_office']
            .filter(type => !!this.props.packages[type])
            .map(type => (
              <Package
                {...this.props.packages[type]} // Pricing, currency from API
                {...i18n[type]} // Translations from local project i18n
                key={`office-package-${type}`}
              />
            ))}
        </Packages>

        {/* <Packages>
            <Package
              {...this.props.packages['meeting_room']}
              {...i18n['meeting_room']}
              key={`office-package-meeting_room`}
            />
            <Package
              {...this.props.packages['meeting_room']}
              {...i18n['meeting_room']}
              key={`office-package-meeting_room_2`}
            />
          </Packages> */}

        {/* <Packages>
            <Package
              {...this.props.packages['prime_membership']}
              {...i18n['prime_membership']}
              key={`office-package-prime_membership`}
            />
          </Packages> */}
        {/* </SwipeableViews> */}
      </>
    )
  }
}

export default Pricing

const i18n = {
  hot_desk: {
    title: 'Hot Desk',
    subtitle: 'Any desk within a hot desking zone',
    description:
      'Perfect for: Individual workers and freelancers who want a professional, dynamic, and flexible setting to focus and network in',
    image: `/images/hot-desk.jpg`,
  },
  fixed_desk: {
    title: 'Fixed Desk',
    subtitle: 'A dedicated desk with a place to lock your belongings',
    description:
      'Perfect for: Startups and small teams who benefit from collaborating and working in an open professional environment',
    image: `/images/fixed-desk.jpg`,
  },
  private_office: {
    title: 'Private Office',
    subtitle: 'A fully furnished, private, and locked office room',
    description:
      'Perfect for: Small to medium sized companies that want a private office space to accessorize and call their own',
    image: `/images/private-office.jpg`,
  },
  meeting_room: {
    title: 'Meeting Room',
    description: 'Woah this is cool!',
  },
  prime_membership: {
    title: 'Prime Membership',
    description: 'Day passes etc. etc.',
  },
}
