import React from 'react'
import styled from 'styled-components'

import Button from 'components/Common/Button'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Wrapper = styled.div`
  position: relative;
  height: ${props => props.height || '100vh'};
  min-height: ${props => props.minHeight}
  width: 100%;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;
  padding: 3rem;
  max-width: calc(1200px + 4rem);
  margin: auto;

  @media (max-width: 700px) {
    align-items: center;
  }
`

const FallbackBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.cgGreen};
  z-index: -2;
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(${props => props.image});
  background-size: cover;
  background-position: center;
  z-index: -1;
  filter: ${props => (props.darken ? 'brightness(0.5)' : 'unset')};
`

const Pretitle = styled.div`
  position: relative;
  margin: 1rem 1.3rem;
  font-family: Acrom;
  font-size: 0.9rem;
  letter-spacing: 1px;
  color: #ffffff;
  text-align: center;
`

const Title = styled.div`
  position: relative;
  font-family: Acrom;
  font-weight: bold;
  font-size: 5rem;
  line-height: 5rem;
  color: #ffffff;
  max-width: 700px;
  margin: 1rem;

  @media (max-width: 700px) {
    font-size: 13vw;
    line-height: 1;
    text-align: center;
  }
`

const Subtitle = styled.div`
  position: relative;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #ffffff;
  max-width: 650px;
  margin: 1rem;

  @media (max-width: 700px) {
    text-align: center;
  }
`
///////////////////////////////////////////////////////////////////////////////
// Component Definitions

const Slide = props => (
  <Wrapper height={props.height} minHeight={props.minHeight}>
    <Container>
      <FallbackBackground />
      <Background image={props.image} darken={props.darken} />
      {props.pretitle && <Pretitle>{props.pretitle}</Pretitle>}
      {props.title && <Title>{props.title}</Title>}
      {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
      {props.description && <Subtitle>{props.description}</Subtitle>}
      {props.button && (
        <Button tabIndex={-1} href={props.button.url} style={{ margin: '1rem' }}>
          {props.button.text || 'BOOK A TOUR'}
        </Button>
      )}
    </Container>
  </Wrapper>
)

export default Slide
