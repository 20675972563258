import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'

import { H3 } from 'components/Common/Typography'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`

const ErrorMessage = styled.div`
  text-align: center;
`

const Status = styled.div`
  font-family: Acrom;
  font-size: 6rem;
  font-weight: bold;
  letter-spacing: -6px;
  color: #c33a2c;
  margin-bottom: 0;
`

const PageNotFound = styled.div`
  font-family: Acrom;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 0 2rem;
  color: #c33a2c;
`

const ExtraInfo = styled.div`
  font-family: Calibre;
  font-size: 1.2rem;
  margin-bottom: 2rem;
`

const Navigation = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`

const Link = styled.div`
  font-size: 1rem;
  font-family: Acrom;
  letter-spacing: 1px;

  > div,
  a {
    color: #337ab7;
    cursor: pointer;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`

const AdditionalLink = styled(Link)`
  margin-bottom: 2.5rem;

  a {
    color: #ffffff;
    background: #337ab7;
    padding: 0.5rem 1.5rem;
    border-radius: 32px;
    transition: 0.2s ease all;

    :hover {
      text-decoration: none;
      background: #6797c0;
    }
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Error404 = props => (
  <Container>
    <ErrorMessage>
      <Status>404</Status>
      <PageNotFound>PAGE NOT FOUND</PageNotFound>
    </ErrorMessage>

    {props.children && <ExtraInfo>{props.children}</ExtraInfo>}

    {/* Additional link is useful for 404 errors in specific components if you want
        to try and redirect them to a correct location */}
    {props.additionalLink && (
      <AdditionalLink>
        <a href={props.additionalLink.href}>{props.additionalLink.value}</a>
      </AdditionalLink>
    )}

    {!props.noLinks && (
      <Navigation>
        <Link>
          <div onClick={props.history.goBack} tabIndex="0">
            PREVIOUS PAGE
          </div>
        </Link>

        <H3 style={{ margin: '0 1rem' }}>·</H3>

        <Link>
          <a href="/">HOME PAGE</a>
        </Link>
      </Navigation>
    )}
  </Container>
)

export default withRouter(Error404)
