import styled from 'styled-components'

// A cute looking box that has a funky border outline offset
// to the top/left by 1rem (todo: make this value a prop maybe)

const BorderOffsetBox = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  background: #f3f3f3;
  padding-bottom: 1rem; // account for :after outline
  
  :after {
    content: '';
    position: absolute;
    border 1px solid grey;
    top: -1rem;
    left: -1rem;
    right: 1rem;
    bottom: 1rem;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    color: #505050;
  }

  @media (max-width: 800px) {
    flex-flow: row wrap;
    justify-content: space-between;
    background: none;
    padding-bottom: 0;

    :after {
      display: none;
    }
  }
`

export default BorderOffsetBox
