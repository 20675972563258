import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

const md = require('data/terms-and-conditions.m.md')

const Container = styled.div`
  margin: 16px;
`

const Image = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;

  img {
    width: 50%;
    height: auto;
    margin: auto;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition
class TermsAndConditionsMobilePage extends React.Component {
  state = {
    data: null,
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  async componentDidMount() {
    const data = await fetch(md).then(res => res.text())

    this.setState({ data })
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { data } = this.state

    return (
      <Container>
        <Helmet>
          <title>Terms & Conditions | Common Ground</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <style>{`
            #navbar.expanded {
              color: #000000;
            }
            p {
              margin: 0.5rem 0;
            }
          `}</style>
        </Helmet>

        <section id="terms-and-conditions">
          <Image>
            <img src="/images/cg-logo.png" alt="Common Ground" />
          </Image>

          <ReactMarkdown source={data} />
        </section>
      </Container>
    )
  }
}

export default TermsAndConditionsMobilePage
