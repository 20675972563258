import React, { useState } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import LocationsSection from './LocationsSection'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #ffffff;

  h1 {
    font-family: Acrom;
    font-weight: bold;
    font-size: 2.3rem;
    letter-spacing: -0.15rem;
  }

  h2 {
    font-family: Cheltenham;
    font-weight: normal;
    font-size: 1.5rem;
    letter-spacing: -0.03rem;
  }

  h3 {
    font-family: Acrom;
    font-weight: bold;
    font-size: 1.4rem;
    letter-spacing: 1px;
    text-align: center;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }

  .container {
    z-index: 5;
  }

  img {
    display: block;
    max-width: 100%;

    &.photo {
      border-radius: 20px;
    }
  }

  ul.terms {
    font-size: 0.95rem;
    line-height: 1.2rem;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.8rem;
    }
  }
`

const Header = styled.div`
  position: relative;
  width: 100%;
  padding-top: 1rem
  padding-bottom: 1rem;

  a {
    display: inline-block;

    img {
      max-height: 100%;
      max-width: 140px;
      width: auto;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`

const Footer = styled.footer`
  background: #151716;
  color: #58585a;
  padding: 1rem;
`

const FooterContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  font-size: 0.85rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  a,
  span {
    color: inherit;
    margin: 0 1rem;
  }

  a {
    margin-right: 3rem;
    text-decoration: none;

    &:hover {
      color: #fecd6f;
    }
  }

  @media (max-width: 768px) {
    span {
      width: 100%;
      margin-bottom: 1rem;
    }

    a {
      margin-right: none;
    }

    div {
      display: none;
    }
  }
`

const SeparatorImage = ({ src, backgroundColor = 'transparent' }) => (
  <img
    src={src}
    alt="-"
    className="noselect"
    draggable={false}
    style={{ position: 'relative', zIndex: 10, margin: '-2px 0', backgroundColor }}
  />
)

///////////////////////////////////////////////////////////////////////////////
// Functions

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class PromosFlexOnboardingPage extends React.Component {
  state = {
    loading: null,
    error: null,
    submitted: false,
  }

  ////////////
  // Render //
  ////////////

  render() {
    return (
      <>
        <Helmet>
          <title>CG | Corporate Flex Solution</title>
          <style>{`
            html {
              font-size: 16px;
              background: #151716;
            }
          `}</style>
        </Helmet>

        <Page>
          <Header>
            <div className="container">
              <a href="https://www.commonground.work">
                <img src="/images/cg-logo.png" alt="Common Ground" />
              </a>
            </div>
          </Header>

          <LandingSection />

          <LocationsSection />

          <AmenitiesSection />

          <SeparatorImage src={require('./images/separatorwhite.png')} backgroundColor="#19423b" />

          <FAQSection />
        </Page>

        <Footer>
          <FooterContainer>
            <span>© 2021 Common Ground Works Sdn Bhd. All rights reserved.</span>

            <div style={{ flex: 1 }} />

            <a href="https://www.commonground.work/terms-and-conditions">Terms & Conditions</a>

            <a href="https://www.commonground.work/privacy">Privacy Policy</a>
          </FooterContainer>
        </Footer>
      </>
    )
  }
}

export default PromosFlexOnboardingPage

//
// ─── LANDING SECTION ────────────────────────────────────────────────────────────
//

const LandingSection = () => {
  return (
    <section id="landing">
      <div className="row no-gutters upper">
        <div className="container">
          <div className="col-12">
            <h1>Activate your Common Ground Flex membership registration</h1>

            <h2>Welcome! The future is flexibility!</h2>

            {/* <div className="video-container">
              <video
                poster={require('./images/poster.jpg')}
                autoPlay={false}
                controls={true}
                muted
                loop
              >
                <source src={require('./images/introvideo.mp4')} />
              </video>
            </div> */}
          </div>
        </div>
      </div>

      <SeparatorImage src={require('./images/separatoryellow.png')} backgroundColor="#ffffff" />

      <div className="row no-gutters lower">
        <div className="container">
          <div className="row justify-content-center mt-4">
            <p>
              <b>Complete your registration</b>
            </p>
          </div>

          <div className="row justify-content-center mb-3">
            <p>1. Download the Common Ground app here:</p>
          </div>

          <div className="row download-buttons">
            <a
              href="https://apps.apple.com/us/app/common-ground-coworking-space/id1340699531#?platform=iphone"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('./images/downloadapple.png')} alt="Download on the App Store" />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=work.commonground.app&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require('./images/downloadgoogle.png')} alt="Get it on Google Play" />
            </a>
          </div>

          <div className="row steps mb-4">
            <img src={require('./images/steps1.png')} alt="Registration steps" />
          </div>

          <div className="row justify-content-center mb-3">
            <p className="text-center">
              Once you've completed your registration on the app, all you need to do is check in
              when you're at a Common Ground venue as follows:
            </p>
          </div>

          <div className="row steps">
            <img src={require('./images/steps2.png')} alt="Check in steps" />
          </div>
        </div>
      </div>

      <style jsx>{`
        #landing {
          padding: 0 0 3rem 0;

          p {
            margin: 0;
          }

          .row {
            &.upper {
              background-color: #fbca6f;
              padding-top: 3rem;
              margin-bottom: -15vw;

              .container {
                position: relative;
                z-index: 100;
              }

              .video-container {
                width: 100%;
                position: relative;
                z-index: 100;
                padding-top: 2rem;

                video {
                  width: 100%;
                  height: auto;
                  background-color: #111111;
                }
              }

              h1 {
                font-size: 3.2rem;
                letter-spacing: -4px;
                margin: 0;
              }

              h2 {
                font-size: 2.7rem;
                letter-spacing: -2px;
                margin: 0;
              }

              @media (max-width: 768px) {
                h1 {
                  font-size: 2.3rem;
                }

                h2 {
                  font-size: 2rem;
                }
              }
            }

            &.lower {
              background-color: #ffffff;
              padding-top: 10vw;

              h2 {
                font-size: 2rem;
              }

              @media (max-width: 768px) {
                h2 {
                  font-size: 1.4rem;
                }
              }
            }
          }

          .steps {
            align-items: center;
            max-width: 1000px;
            margin: 0 auto;
          }

          .download-buttons {
            flex-flow: row nowrap;
            max-width: 350px;
            margin: auto;

            a {
              margin: 0 0.5rem;
            }
          }
        }
      `}</style>
    </section>
  )
}

//
// ─── AMENITIES SECTION ──────────────────────────────────────────────────────────
//

const AmenitiesSection = () => {
  return (
    <section id="amenities">
      <div className="container">
        <div className="row justify-content-center mb-2">
          <h1 className="m-0 text-center">Your flexible membership</h1>
        </div>

        <div className="row justify-content-center mb-4">
          <h2 className="m-0 text-center">
            On-demand facilities, services and membership benefits you have access to.
          </h2>
        </div>

        <div className="row list mb-5">
          <img src={require('./images/amenitieslist.png')} alt="Amenities" />
        </div>

        <div className="row images">
          <div className="col-6 h-100 mb-4">
            <img src={require('./images/amenities1.jpg')} alt="Workspace on demand" />
            <b>Workspace on demand</b>
            <p>
              Get to work at any of the available desks in the allocated Hot Desk area in Common
              Ground. Check in at any venue and just pay as you go.
            </p>
          </div>

          <div className="col-6 h-100 mb-4">
            <img src={require('./images/amenities2.jpg')} alt="Meeting rooms" />
            <b>Meeting Rooms</b>
            <p>
              From small rooms for a 5 person discussion to a 20-person board meeting, bookings are
              just a few clicks away.
            </p>
          </div>

          <div className="col-6 col-md-4 h-100 mb-4">
            <img src={require('./images/amenities3.jpg')} alt="Printing and Photocopying" />
            <b>Printing and Photocopying</b>
            <p>
              Once at a Common Ground outlet, printing and photocopying is as easy as clicking a few
              buttons on the CG app. We are able to track exactly how many pages are printed or
              copied and you will be charged for only what has been used.
            </p>
          </div>

          <div className="col-6 col-md-4 h-100 mb-4">
            <img
              src={require('./images/amenities4.jpg')}
              alt="Stationeries, courier and delivery services"
            />
            <b>Stationeries, courier and delivery services</b>
            <p>
              Business support services such as stationery supplies, sending out a courier or
              delivery services are available. Just ask our friendly CG Community team on site for a
              quote.
            </p>
          </div>

          <div className="col-6 col-md-4 h-100 mb-4">
            <img src={require('./images/amenities5.jpg')} alt="Secretarial/admin services" />
            <b>Secretarial/admin services</b>
            <p>
              Need an office assistant to run an errand or carry out a task? No problem! This is
              available based on man-hour help needed.
            </p>
          </div>
        </div>
      </div>

      <style jsx>{`
        .list {
          align-items: center;

          img {
            width: 100%;
            max-width: 700px;
            margin: auto;
          }
        }

        .images {
          width: 100%;
          max-width: 1000px;
          margin: auto;
          font-size: 1.2rem;

          & > div {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;

            img {
              margin-bottom: 0.5rem;
              aspect-ratio: 1.5;
              object-fit: cover;
            }

            b {
              text-align: center;
              font-weight: 800;
            }

            p {
              text-align: center;
              margin: 0;
            }
          }
        }
      `}</style>
    </section>
  )
}

//
// ─── FAQ SECTION ────────────────────────────────────────────────────────────────
//

const FAQSection = () => {
  const categories = [
    {
      category: 'Signing up',
      items: [
        {
          q:
            'If my company signs up in Common Ground Malaysia, will I have access to Common Ground Philippines and Thailand as well?',
          a:
            'Yes, you will and charges will be based on the respective country’s pricing structures.',
        },
        {
          q: 'How long will it take to activate my account?',
          a:
            'Accounts will be activated within 24 hours of your HR/admin submitting the name list of eligible users.',
        },
        {
          q: 'Am I allowed to share my Common Ground membership access?',
          a:
            'No as each Common Ground membership account is uniquely registered to each individual’s IC, phone number and Common Ground App login. Your HR/Admin team will submit lists of eligible users to Common Ground to be registered.',
        },
      ],
    },
    {
      category: 'Termination',
      items: [
        {
          q:
            'How long in advance should my company notify Common Ground should we decide to terminate the account with Corporate Flex solution?',
          a: 'Accounts will be deactivated within 48 hours business hours.​',
        },
      ],
    },
    {
      category: 'Billings, credits and deposits',
      items: [
        {
          q: 'Is there a deposit for Corporate Flex Solution?​',
          a: 'No deposit payments are required.',
        },
        {
          q: 'What is the minimum amount of credits I can prepay or top up into my CG account?',
          a: 'The minimum amount for top-up credits is RM20.',
        },
        {
          q: 'Will the remaining credits be refunded at the end of my tenure?',
          a: 'Any unused credits at the end of your tenure will not be refunded.',
        },
        {
          q: 'Can we make withdrawals of our credit during the tenure?',
          a: 'Credits cannot be withdrawn during your tenure.',
        },
        {
          q: 'Will my credits expire?​',
          a: 'Credits will expire after 60 days of inactive use of CG facilities.',
        },
        {
          q:
            'Will I be able to access Common Ground and its services if my company has finished using its credits?',
          a:
            'Employees are able to make payment on their own on the CG App and claim from the company.',
        },
        {
          q: 'Are there any hidden charges?',
          a:
            'No, credits are only deducted upon usage and you only need to pay for what you utilise. The updated price lists can be found in the Common Ground app. T&C on meeting room bookings cancellation policy.',
        },
        {
          q: 'What happens if we forget to check out upon leaving the CG outlets?',
          a:
            'If you forget to check out before leaving the outlet, you will automatically be checked out at the end of the day’s official operating hours (6PM), and you will only be charged up until 6PM. Do remember to check out every day after usage to ensure accurate billing.',
        },
      ],
    },
    {
      category: 'Common Ground operations',
      items: [
        {
          q: 'When can my employees access Common Ground ​and its services?',
          a:
            'Common Ground and its services are available within official operating hours. Our outlets are open from Monday to Friday, 9AM to 6PM excluding public holidays. Do refer to our list of outlets in the ‘Explore’ tab of the Common Ground App for more info.',
        },
        {
          q: 'Can I visit more than one venue on the same day?',
          a: 'Yes, you can!',
        },
        {
          q: 'Am I able to check seat availability ahead of time?',
          a:
            'Our list of locations can be found on the Common Ground app under the ‘Explore’ tab. Hot Desks are available based on a first come, first serve basis. Feel free to call in advance to check if they have available Hot Desks for you to work at.',
        },
        {
          q: 'Do I get a business address and mail handling services with this package?',
          a:
            'No, not with the Corporate Flex Solution. However, special packages are available for you as add-on/an upgrade if you would require a business address and/or mail handling services. Check with our CG crew (email: flex@commonground.work) for more information on our packages.',
        },
        {
          q: 'When can I access Common Ground and its services?',
          a:
            'Common Ground and its services are available within official operating hours. Our outlets are open from Monday to Friday, 9AM to 6PM excluding public holidays. Do refer to our list of outlets in the ‘Explore’ tab of the Common Ground App for more info.',
        },
        {
          q: 'Can I reserve meeting rooms in advance?',
          a: 'Yes, you can via the Common Ground app.',
        },
        {
          q: 'What are the internet speeds in Common Ground?',
          a:
            'Every Common Ground Malaysia outlet is well-equipped with a leased line (a premium internet connectivity product) as well as a backup premium broadband line that is exclusive to Common Ground. Each user gets a minimum of 50mbps per IP. Once connected, taking concalls via Zoom, Skype or MS Teams are no issues for our Members.',
        },
        {
          q: 'Can I leave my personal belongings in the outlets?',
          a:
            'Our Corporate Flex Solution does not come with storage options therefore we do not encourage you to leave your belongings in the outlets. We also recommend that you always have your valuable items with you, at all times.',
        },
      ],
    },
  ]

  const [selectedCategory, setSelectedCategory] = useState(categories[0].category)

  return (
    <section id="faq">
      <h3>FAQs</h3>

      <div className="container">
        <div className="category-options">
          {categories.map(({ category }, index) => (
            <div
              className={`category-option ${selectedCategory === category ? 'selected' : ''}`}
              onClick={() => setSelectedCategory(category)}
              key={`category-option-${index}`}
            >
              <p>{category}</p>
            </div>
          ))}
        </div>

        {categories
          .filter(({ category }) => selectedCategory === category)
          .map(({ category, items }) => (
            <div className="category" key={`category-${category}`}>
              {items.map(({ q, a }, index) => (
                <div className="item" key={`category-${category}-item-${index}`}>
                  <p className="q">{q}</p>
                  <p className="a">{a}</p>
                </div>
              ))}
            </div>
          ))}
      </div>

      <style jsx>{`
        #faq {
          background-color: #19423b;
          color: #ffffff;
          padding: 3rem 0;
          min-height: 70vh;

          .category-options {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            margin-bottom: 2rem;

            .category-option {
              font-weight: 800;
              opacity: 0.4;
              cursor: pointer;

              p {
                margin: 0.5rem 0;
              }

              &.selected {
                opacity: 1;
              }
            }
          }

          @media (max-width: 768px) {
            .category-options {
              flex-flow: column nowrap;
            }
          }

          .category {
            text-align: center;

            .title {
              font-weight: 800;
              text-decoration: underline;
            }

            .item {
              margin-bottom: 2rem;

              p {
                margin: 0;
                line-height: 1.2;

                &.q {
                  font-weight: 800;
                }
                &.a {
                }
              }
            }
          }
        }
      `}</style>
    </section>
  )
}
