import React from 'react'
import styled from 'styled-components'

///////////////////////////////////////////////////////////////////////////////
// Constants

const FLOATING_ASSISTANT_HEIGHT = '3.5rem'

///////////////////////////////////////////////////////////////////////////////
// Styling

const EmptySpace = styled.div`
  height: ${FLOATING_ASSISTANT_HEIGHT};
  margin: ${props => props.theme.MOBILE.PAD};
`

const FloatingAssistantWrapper = styled.div`
  position: fixed;
  z-index: 99;
  bottom: ${props => props.theme.MOBILE.PAD};
  left: ${props => props.theme.MOBILE.PAD};
  right: ${props => props.theme.MOBILE.PAD};
  height: ${FLOATING_ASSISTANT_HEIGHT};
  background: ${props => props.theme.cgYellow};
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: ${props => props.theme.MOBILE.BORDER_RADIUS};
`

const Function = styled.div`
  flex: ${props => props.flex || 'unset'};
  width: ${FLOATING_ASSISTANT_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  border-right: 1px solid rgba(0, 0, 0, 0.2);

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.cgGreen};
    text-decoration: none;
  }

  img {
    width: 50%;
  }

  :last-child {
    border-right: none;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const FloatingAssistant = props => (
  <>
    {/* Empty space is used to ensure the FloatingAssistant isn't covering
    any other UI elements when user has scrolled to the bottom  */}
    <EmptySpace />

    <FloatingAssistantWrapper>
      <Function
        onClick={() => {
          if (props.openGoogleMaps) {
            props.openGoogleMaps()
          }
        }}
      >
        <img src="/icons/navigation.png" alt="Get Directions" />
      </Function>

      {props.office.contact_number && (
        <Function>
          <a href={`tel:${props.office.contact_number}`}>
            <img src="/icons/phone.png" alt="Call Us" />
          </a>
        </Function>
      )}

      <Function
        flex="1"
        onClick={() => {
          if (window) {
            // Public users shouldn't be able to book a tour for private venues,
            // so make this button just trigger the phone number instead
            if (props.office.is_private) return false

            const message = JSON.stringify({
              property_id: props.office.property_id,
            })

            // Currently just send the office's property_id
            // in the future will likely have to send phone number & directions
            if (window.postMessage) {
              window.postMessage(message, '*')
            }

            // Support newer versions of react-native-webview
            if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
              window.ReactNativeWebView.postMessage(message)
            }
          }
        }}
      >
        <a href={props.office.is_private ? `tel:${props.office.contact_number}` : undefined}>
          Contact Us
        </a>
      </Function>
    </FloatingAssistantWrapper>
  </>
)

export default FloatingAssistant
