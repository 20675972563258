import React from 'react'
import styled from 'styled-components'

// LoadingRing from: https://loading.io/css/
const LoadingRing = styled.div`
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 36px;
    height: 36px;
    margin: 6px;
    border: 4px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${props => props.color || '#ffffff'} transparent transparent transparent;

    :nth-child(1) {
      animation-delay: -0.45s;
    }

    :nth-child(2) {
      animation-delay: -0.3s;
    }

    :nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loading = props => (
  <LoadingRing color={props.color || '#ffffff'}>
    <div />
    <div />
    <div />
    <div />
  </LoadingRing>
)

export default Loading
