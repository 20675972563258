import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'
import AnimateHeight from 'react-animate-height'

import MissingPage from 'views/missing'

import LoadingRing from 'components/Common/Loading'
import Pin from 'components/Locations/GoogleMapsExplorer/Pin'

import Amenities from 'components/Office.m/Amenities'
import Features from 'components/Office.m/Features'
import Pricing from 'components/Office.m/Pricing'
import Perks from 'components/Office.m/Perks'
import Staff from 'components/Office.m/Staff'
import NearbyOffices from 'components/Office.m/NearbyOffices'
import FloatingAssistant from 'components/Office.m/FloatingAssistant'

import { parseGoogleMapsURL } from 'utils'

///////////////////////////////////////////////////////////////////////////////
// Constants

const DESCRIPTION_HEIGHT = 64 // Default height of the description box (sadly this is necessary)

///////////////////////////////////////////////////////////////////////////////
// Styling

const Loading = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  padding: 0 ${props => props.theme.MOBILE.PAD};
  color: #194239;
`

const Section = styled.section`
  margin-bottom: ${props => props.theme.MOBILE.PAD};
  overflow: hidden;

  ${props =>
    props.fullWidth &&
    `
    margin-left: -${props.theme.MOBILE.PAD};
    margin-right: -${props.theme.MOBILE.PAD};
  `}

  ${props =>
    props.shadow &&
    `
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  `}
`

const Banner = styled.div`
  position: relative;
  width: 100%;
  height: 33vh;
  background: rgba(0, 0, 0, 0.15);

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Heading = styled.div`
  font-family: Acrom;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: -0.7px;
  margin-bottom: 0.5rem;
`

//// DESCRIPTION ////

const Text = styled.div`
  font-family: Calibre;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
`

const Summary = styled.div``

const ExpandToggle = styled.div`
  position: relative;
  width: 100%;
  height: 30px;

  :after {
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border-top: 2px solid rgba(0, 0, 0, 0.6);
    border-right: 2px solid rgba(0, 0, 0, 0.6);
    margin: auto;
    margin-bottom: 0.5rem;
    transform: rotateZ(135deg);
    transition: 0.5s ease all;

    ${props =>
      props.expanded &&
      `
      transform: rotateZ(-45deg);
    `}
  }
`

const Map = styled.div`
  width: 100%;
  height: 230px;
  background: rgba(0, 0, 0, 0.3);
`

const MapOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const PinOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class OfficePage extends React.Component {
  state = {
    data: null,
    err: null,

    descriptionHeight: DESCRIPTION_HEIGHT,
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  async componentDidMount() {
    try {
      const { params } = this.props.match

      const url = `${process.env.REACT_APP_API_URL}/custom/offices/${params.office}`

      let { data } = await fetch(url).then(res => res.json())

      if (!data || data.length <= 0) {
        throw new Error('Invalid location')
      }

      // Because we're doing a filter-like query, it returns an array.
      // If a match was found, it will be the first item in the array.
      // This assumes there aren't multiple buildings with the same name.
      this.setState({ office: data })
    } catch (err) {
      console.error(err)
      this.setState({ err })
    }
  }

  ///////////////
  // Functions //
  ///////////////

  toggleDescription() {
    const { descriptionHeight } = this.state
    this.setState({ descriptionHeight: descriptionHeight === 'auto' ? DESCRIPTION_HEIGHT : 'auto' })
  }

  openGoogleMaps() {
    const { office } = this.state
    const { google_maps_url } = office

    if (window && google_maps_url) {
      const message = JSON.stringify({
        google_maps_url: google_maps_url,
      })

      if (window.postMessage) {
        window.postMessage(message, '*')
      }

      // Support newer versions of react-native-webview
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(message)
      }
    }
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { office, err } = this.state
    const { descriptionHeight } = this.state

    if (err)
      return <MissingPage additionalLink={{ href: '/locations', value: 'VIEW ALL LOCATIONS' }} />

    if (!office) {
      return (
        <Loading>
          <LoadingRing color="#194239" />
        </Loading>
      )
    }

    const officeCenter = parseGoogleMapsURL(office.google_maps_url)

    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>

        <Container>
          <Section fullWidth>
            <Banner>
              <img src={office.banner.url} alt={office.banner.title} />
            </Banner>
          </Section>

          {/* DESCRIPTION SECTION */}
          {/* todo: move this to its own component */}
          <Section>
            <Summary>
              <Heading>{office.building.toUpperCase()}</Heading>
              <Text>{office.area}</Text>
              <AnimateHeight duration={500} height={descriptionHeight}>
                <Text>{office.description}</Text>
              </AnimateHeight>
              <ExpandToggle
                expanded={descriptionHeight === 'auto'}
                onClick={() => this.toggleDescription()}
              />
            </Summary>
          </Section>

          {/* AMENITIES SECTION */}
          <Section fullWidth>
            <Amenities />
          </Section>

          {/* MAP SECTION */}
          <Section fullWidth style={{ marginTop: '16px', marginBottom: '1.5rem' }}>
            <Map>
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
                center={officeCenter}
                zoom={14}
                options={{
                  disableDefaultUI: true,
                }}
              />

              <PinOverlay>
                <Pin
                  {...officeCenter}
                  style={{ position: 'relative', top: '-18px', transform: 'scale(0.65)' }}
                  // dotColor="#ffcd66"
                  // color="#194239"
                />
              </PinOverlay>

              <MapOverlay onClick={() => this.openGoogleMaps()} />
            </Map>
          </Section>

          {/* EXPLORE NEARBY SECTION */}
          {office.features.length > 0 && (
            <>
              <Heading>Explore Nearby</Heading>

              <Section fullWidth>
                <Features features={office.features} />
              </Section>
            </>
          )}

          {/* PRICING SECTION */}
          {Object.keys(office.packages).length > 0 && (
            <>
              <Heading>Pricing</Heading>

              <Section fullWidth>
                <Pricing packages={office.packages} />
              </Section>
            </>
          )}

          {/* BUILDING PERKS SECTION */}
          {office.perks.length > 0 && (
            <>
              <Heading>Building Perks</Heading>

              <Section>
                <Perks perks={office.perks} />
              </Section>
            </>
          )}

          {/* STAFF SECTION */}
          {office.staff.length > 0 && (
            <>
              <Heading>You’re In Good Hands</Heading>
              <Text>Crew members are on-site to handle your needs.</Text>

              <Section fullWidth>
                <Staff staff={office.staff} />
              </Section>
            </>
          )}

          {/* NEARBY OFFICES SECTION */}
          {office.nearby_offices.length > 0 && (
            <>
              <Heading>Nearby Venues</Heading>
              <Text>Move freely across our outlets. Below are ones within 10km.</Text>

              <Section fullWidth>
                <NearbyOffices nearbyOffices={office.nearby_offices} />
              </Section>
            </>
          )}

          {/* ENTERPRISE NOTICE SECTION */}
          {office.is_private && (
            <>
              <Heading>Enterprise</Heading>
              <Text>
                Common Ground’s Enterprise Solutions offer large corporations the opportunity to
                work in an innovative and coworking atmosphere.
              </Text>
              <Text>
                We collaborate with large teams to custom-build, maintain, and operate a Common
                Ground location exclusive to their company, enabling their staff members to benefit
                from our community perks while enjoying the privacy of their very own venue.
              </Text>
              <Text>
                With access to Common Ground’s events, partnership benefits, social and business
                network, and the Ambition Engine portal, Enterprise Solution clients have the
                liberty of tapping into a vibrant community and enjoying an independent office
                venue.
              </Text>
            </>
          )}

          <FloatingAssistant office={office} openGoogleMaps={() => this.openGoogleMaps()} />
        </Container>
      </>
    )
  }
}

export default OfficePage
