import React from 'react'
import styled from 'styled-components'

import Package from 'components/Package'
import { H1, H5 } from 'components/Common/Typography'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  padding: 5rem 2rem;
  box-sizing: border-box;
`

const Container = styled.div`
  max-width: 1200px;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin: auto;
  justify-content: center;

  @media (max-width: 800px) {
    text-align: center;
    align-items: center;
  }
`

const PackagesWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 1rem -1vw;

  @media (max-width: 800px) {
    flex-flow: column nowrap;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const PackagesTable = props => {
  const packages = (props.office && props.office.packages) || PACKAGES_DEFAULT

  return (
    <Wrapper>
      <Container>
        <H5>PRICE THAT MATCHES YOUR NEEDS</H5>
        <H1>OUR PLANS</H1>

        <PackagesWrapper>
          {Object.keys(packages).map(packageType => {
            const _package = packages[packageType]

            // Invalid package type given
            if (!i18n[packageType]) return null

            return (
              <Package
                currency={_package.currency || ''}
                price={_package.price || 'N/A'}
                {...i18n[packageType]}
                cycle="month"
                key={`packages-${packageType}`}
              />
            )
          })}
        </PackagesWrapper>
      </Container>
    </Wrapper>
  )
}

export default PackagesTable

///////////////////////////////////////////////////////////////////////////////
// The available package types are hard coded into the front end application
// for the time being.
// This is to reduce human error when inputting data on CMS.
// It's unlikely that new packages are created often enough to warrant them
// being exposed on the front end.

const i18n = {
  hot_desk: {
    name: 'Hot Desk',
    description:
      'For those who enjoy flexibility. Grab any available seat within the hot desking zone and hustle away.',
    image: '/images/hot-desk.jpg',
    url: '/packages/hot-desk',
  },
  fixed_desk: {
    name: 'Fixed Desk',
    description:
      'For those who prefer a standing ground. You get a dedicated desk for yourself in a shared workspace.',
    image: '/images/fixed-desk.jpg',
    url: '/packages/fixed-desk',
  },
  private_office: {
    name: 'Private Office',
    description:
      "For those who value privacy. You'll enjoy a fully furnished and enclosed workspace.",
    image: '/images/private-office.jpg',
    url: '/packages/private-office',
  },
  prime_membership: {
    name: 'Prime Membership',
    description: 'Lorem ipsum.',
    image: '/images/prime-membership.jpg',
    url: '/packages/prime-membership',
  },
}

const PACKAGES_DEFAULT = {
  hot_desk: {
    currency: 'MYR',
    price: 399,
  },
  fixed_desk: {
    currency: 'MYR',
    price: 599,
  },
  private_office: {
    currency: 'MYR',
    price: 899,
  },
}
