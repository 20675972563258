import styled from 'styled-components'

export const H1 = styled.h1`
  font-family: Acrom;
  font-size: 2.4rem;
  font-weight: bold;
  margin: 0 0 2rem 0;
  letter-spacing: 4px;
  color: ${props => props.color || '#000000'};

  @media (max-width: 800px) {
    font-size: 8vw;
  }
`

export const H2 = styled.h2`
  font-family: Acrom;
  font-size: 2rem;
  font-weight: bold;
  margin: 0 0 2rem;
  letter-spacing: 3px;
  color: ${props => props.color || '#000000'};
`

export const H3 = styled.h3`
  font-family: Acrom;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0 1rem;
  letter-spacing: 1px;
  color: ${props => props.color || '#000000'};
`

export const H5 = styled.h5`
  font-family: Calibre;
  font-size: 0.9rem;
  font-weight: normal;
  margin: 0 0 1rem 0;
  letter-spacing: 2px;
  color: ${props => props.color || props.theme.cgGunmetalLight};
`

export const P = styled.p`
  font-family: Calibre;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.3px;
  margin: 0 0 0.8rem 0;
  white-space: pre-wrap;
  color: ${props => props.color || '#000000'};
`
