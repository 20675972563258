import React from 'react'
import Select from 'react-select'

import { getOfficesByCountry } from 'services/api'

// OfficePicker is just a wrapper around react-select to:
// 1. Initialize with some standard values, styles and settings
// 2. Include a hidden <input> which contains the selected value (makes it easier for native forms)

const selectStyles = (options = {}) => ({
  option: styles => ({
    ...styles,
    whiteSpace: 'nowrap',
  }),

  control: styles => ({
    ...styles,
    background: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '1px solid #000000',
    borderRadius: 0,
    borderColor: '#000000 !important',
  }),

  menu: styles => ({
    ...styles,
    marginTop: 0,
    borderRadius: 0,
    width: 'auto',
    maxWidth: 'calc(100vw - 4rem)',
    right: options.anchor === 'right' ? 0 : 'unset',
  }),
})

export class OfficePicker extends React.Component {
  state = {
    selectedOffice: '',
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  async componentDidMount() {
    try {
      let countries = await getOfficesByCountry()

      // Group offices based on cities
      let groups = []

      for (let country of countries) {
        for (let city of country.cities) {
          // Create a new 'group of offices'
          let index = groups.push({
            label: `${country.name} - ${city.name}`,
            options: [],
          })

          for (let office of city.offices) {
            // Add an office to the 'group of offices'
            groups[index - 1].options.push({
              label: `${office.building}, ${office.area}`,
              value: office.building,
            })
          }
        }
      }

      this.setState({ choices: groups })
    } catch (err) {
      console.error(err)
    }
  }

  ///////////////
  // Functions //
  ///////////////

  selectOffice(office) {
    this.setState({ selectedOffice: office })
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { required = true, name = 'selected_office', anchor = 'left' } = this.props
    const { choices, selectedOffice } = this.state

    return (
      <>
        <div style={{ width: '100%' }}>
          <Select
            options={choices}
            onChange={value => this.selectOffice(value)}
            value={selectedOffice}
            styles={selectStyles({ anchor })}
            isSearchable={false}
            inputId="office-picker"
          />
        </div>

        {/* This 'hidden input field' is here so that any form using this component can
          easily access the value like any other input field. */}
        <input
          required={required}
          name={name}
          hidden
          // readOnly
          onChange={() => true} // Add an onChange to suppress input warnings
          value={selectedOffice.value || ''}
        />
      </>
    )
  }
}

export default OfficePicker
