import styled from 'styled-components'

// These are defined in separate files because they're more comprehensive

export const DatePicker = require('./DatePicker').default
export const CountrycodePicker = require('./CountrycodePicker').default
export const OfficePicker = require('./OfficePicker').default

// These are just generic <form> and <input> related fields but with custom styling

// (The 'correct' way would be to define each of these in separate files, then
// explicitly require/export them in index.js - but that is pretty tedious)

export const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  color: #000000;
  font-size: 1rem;
  font-family: Calibre;
  letter-spacing: 0;
`

export const InputField = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.5rem;
  width: ${props => props.width || 'calc(50% - 1rem)'};
`

export const Input = styled.input`
  background: none;
  border: none;
  border-bottom: 1px solid black;
  padding: 0.5rem 0.2rem;
  font-family: Calibre;
  font-size: 1rem;
  letter-spacing: 0.3px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  width: 100%;
`

export const Label = styled.label`
  width: 100%;
  font-family: Acrom;
  font-size: 0.8rem;
  font-weight: 900;
  margin-bottom: 0;
  margin-left: 0.2rem;
`

export const Textarea = styled.textarea`
  font-family: Calibre;
  font-size: 1rem;
  letter-spacing: 0.3px;
  height: 120px;
  width: 100%;
  resize: none;
  background: none;
  border: 1px solid rgb(0, 0, 0);
  margin: 0;
  margin-top: 1rem;
  padding: 0.5rem;
`

export const Select = styled.select`
  background: transparent;
  border: 1px solid black;
  display: inline-block;
  font-family: Calibre;
  font-size: 1rem;
  letter-spacing: 1px;
  padding: 0.5em 3.5em 0.5em 1em;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);

  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;

  ${props =>
    props.lineOnly &&
    `
  border: none;
  border-bottom: 1px solid black;
  padding: 0.5rem 0.2rem;
`}
`
