import React from 'react'
import styled from 'styled-components'

import { H3, P } from 'components/Common/Typography'

///////////////////////////////////////////////////////////////////////////////
// Styling

// Testimonial component has a style collapse at width of 800px
// so the {author} value is less confusing on mobile screens

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  flex-basis: 300px;
  max-width: 300px;
  margin: 1rem;
  box-sizing: border-box;

  @media (max-width: 800px) {
    max-width: unset;
    min-width: 100%;
    margin: 0 0 1rem 0;
    text-align: center;
  }
`

const Image = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-bottom: 2rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 800px) {
    width: 130px;
    height: 130px;
    margin-bottom: 1rem;
  }
`

const Quote = styled(P)`
  width: 100%;
  margin-bottom: 2rem;

  @media (max-width: 800px) {
    order: 2;
  }
`

const Customer = styled(H3)`
  margin-top: auto;
  margin-bottom: 1rem;
  align-self: flex-start;

  @media (max-width: 800px) {
    order: 1;
    align-self: center;
    margin-top: unset;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Testimonial = props => (
  <Container>
    <Image>
      <img src={props.image} alt={props.customer} />
    </Image>
    <Quote>{props.quote}</Quote>
    <Customer>{props.customer}</Customer>
  </Container>
)

export default Testimonial
