import { get } from 'lodash'

/**
 * Use this to convert an array of objects to be in suitable form for react-select usage.
 *
 * @param {Array} data Array of items you want to show as options.
 * @param {String} key Parameter key that will be used as the 'label' value.
 */
export const optionify = (data, key = '') => {
  if (!Array.isArray(data)) throw new Error('Data must be an array')

  return data.map((option, index) => ({
    label: key ? get(option, key, `Option ${index}`) : JSON.stringify(data[index]),
    value: option,
  }))
}
