import { DateTime } from 'luxon'

import { toCamelCase } from 'utils'

const API_URL = 'https://ambition.commonground.work/staging'

//
// ─── SUBMIT CLERK FORM ──────────────────────────────────────────────────────────
//

export const submitClerkForm = async data => {
  try {
    const rate = data.selectedProducts.length > 1 ? 'discounted' : 'full'
    const ref = `CGC-${DateTime.utc().toFormat('yyyyMMdd-HHmmss')}-${data.merchantToken.substr(
      0,
      6
    )}`

    const body = {
      user_id: data.userProfile.userId,
      merchant: data.merchant,
      merchant_token: data.merchantToken,
      order_info: {
        order_ref: ref,
        company_id: data.selectedCompany.id,
        price: data.selectedProducts.reduce((sum, product) => {
          const found = data.selectedAddtionalInfo.find(info => product.pricing[rate][info.value])
          const price = found ? product.pricing[rate][found.value] : 0

          return sum + price
        }, 0),
        currency: data.userProfile.currency || 'MYR',
        products: data.selectedProducts.map(product => {
          const found = data.selectedAddtionalInfo.find(info => product.pricing[rate][info.value])
          const price = found ? product.pricing[rate][found.value] : 0

          return {
            type: product.type,
            name: product.name,
            currency: product.currency,
            price: !!price ? price : undefined,
            is_fixed_price: !!price,
          }
        }),
      },
    }

    // insert selected additional info into payload body
    data.additionalInfoOptions.forEach(
      (key, index) => (body.order_info[key] = data.selectedAddtionalInfo[index].value)
    )

    //////////////////
    // POST REQUEST //
    //////////////////
    const response = await fetch(`${API_URL}/merchant/order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(res => toCamelCase(res))

    if (response.status !== 'success') {
      throw new Error('Error submitting order request')
    }

    return response
  } catch (error) {
    throw error
  }
}
