import React from 'react'
import styled from 'styled-components'

///////////////////////////////////////////////////////////////////////////////
// Constants

const textColor = '#000000'
const linkColor = '#989898'
const arrowColor = '#4e7b71'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  font-size: 1rem;
  line-height: 1.8rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`

const Breadcrumb = styled.a`
  color: ${linkColor};
  text-decoration: none;
`

const StaticBreadcrumb = styled.div`
  color: ${textColor};
`

const RightArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid ${arrowColor};
  margin: 0 0.6rem;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Breadcrumbs = props => (
  <Container>
    {Array.isArray(props.links) &&
      props.links.map((link, index) => (
        <React.Fragment key={`breadcrumb-${props.from}-${link.href}`}>
          {link.href ? (
            <Breadcrumb href={link.href}>{link.text}</Breadcrumb>
          ) : (
            <StaticBreadcrumb>{link.text}</StaticBreadcrumb>
          )}

          {index < props.links.length - 1 && <RightArrow />}
        </React.Fragment>
      ))}
  </Container>
)

export default Breadcrumbs
