// Simple util to turn strings into slugs

// 1. Replace all spaces in strings with hyphens
// 2. Makes everything lowercase

// e.g. Hello Kuala Lumpur --> hello-kuala-lumpur

export const slugify = str => {
  return str.replace(/\s+/g, '-').toLowerCase()
}
