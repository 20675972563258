import React from 'react'
import styled from 'styled-components'

import { P } from 'components/Common/Typography'

///////////////////////////////////////////////////////////////////////////////
// Constants

const mobileCollapseWidth = '1150px'

const links = [
  {
    url: '/locations',
    text: 'LOCATIONS',
  },
  {
    url: '/packages',
    text: 'PACKAGES',
  },
  {
    url: '/enterprise',
    text: 'ENTERPRISE',
  },
  {
    url: '/agents',
    text: 'AGENTS',
  },
  {
    url: '/faq',
    text: 'FAQ',
  },
]

///////////////////////////////////////////////////////////////////////////////
// Styling

const Wrapper = styled.footer`
  position: relative;
  z-index: 99;
  background: #000000;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 5rem 3rem;

  @media (max-width: ${mobileCollapseWidth}) {
    padding: 2rem 0rem;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  color: #a7a7a7;

  @media (max-width: ${mobileCollapseWidth}) {
    flex-flow: column nowrap;
    max-width: 700px;
    align-items: center;
    text-align: center;
  }
`

const SubscribeInput = styled.div`
  flex: 3;
  padding: 1rem;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  input {
    display: inline-block;
    width: calc(100% - 100px - 2rem); // Account for parent padding & subscribe button width
    max-width: 250px;
    margin-top: 1.5rem;
    border: none;
    border-bottom: 1px solid #ffffff;
    background: none;
    padding: 0.5rem;
    font-size: 1.1rem;
    font-family: Calibre;
    letter-spacing: 1px;
    color: #ffffff;
    transition: 0.3s ease all;

    :focus {
      outline: none;
      border-color: ${props => props.theme && props.theme.cgYellow};
    }
  }

  button {
    background: none;
    border: 1px solid #ffffff;
    color: #ffffff;
    padding: 0.5rem;
    font-size: 0.8rem;
    font-family: Acrom;
    margin-left: 1rem;
    cursor: pointer;
    transition: 0.3s ease all;

    &:hover {
      border-color: ${props => (props.theme && props.theme.cgYellow) || '#ffffff'};
      background: ${props => (props.theme && props.theme.cgYellow) || '#ffffff'};
      color: #000000;
    }
  }

  @media (max-width: ${mobileCollapseWidth}) {
    order: 2;
  }
`

const Sitemap = styled.div`
  flex: 5;
  display: flex;
  flex-flow: row wrap;

  @media (max-width: ${mobileCollapseWidth}) {
    order: 1;
    margin-bottom: 1rem;
    flex-flow: column nowrap;
  }
`

const Nav = styled.nav`
  width: 100%;
  color: #ffffff;
  font-family: Acrom;
  font-weight: normal;
  font-size: inherit;
  letter-spacing: inherit;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (max-width: ${mobileCollapseWidth}) {
    justify-content: center;
  }
`

const Heading = styled.div`
  display: inline-block;
  font-family: Acrom;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: #ffffff;

  a {
    display: block;
    padding: 1rem;
    text-decoration: none;
    color: inherit;
    transition: 0.3s ease all;

    &:hover {
      color: ${props => props.theme && props.theme.cgYellow};
    }
  }
`

const Office = styled.div`
  flex: 5;
  padding: 1rem;
`

const Contact = styled.div`
  flex: 3;
  padding: 1rem;
`

const Text = styled(P)`
  color: inherit;
  margin: 0;

  @media (max-width: ${mobileCollapseWidth}) {
    font-size: 0.9rem;
  }
`

const HR = styled.hr`
  width: 100%;
  margin: 2rem 0 1rem 0;
  border-width: 0.5px;
  border-color: #5e5e5e;
`

const Copyright = styled.div`
  color: #5e5e5e;
  padding: 1rem;

  @media (max-width: ${mobileCollapseWidth}) {
    padding: 0;
  }
`

const Spacer = styled.div`
  flex: 1;
`

const Legals = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`

const Legal = styled.div`
  color: #5e5e5e;
  padding: 1rem;

  a {
    color: inherit;
    text-decoration: underline;
  }

  @media (max-width: ${mobileCollapseWidth}) {
    padding: 0.5rem;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Footer = () => (
  <Wrapper>
    <Container>
      <SubscribeInput>
        <Heading style={{ width: '100%' }}>SUBSCRIBE TO OUR COMMUNICATIONS</Heading>
        <input placeholder="Your email" type="email" />
        <button>SUBSCRIBE</button>
      </SubscribeInput>

      <Sitemap>
        <Nav>
          {links.map(link => (
            <Heading key={`footer-nav-${link.text}`}>
              <a href={link.url}>{link.text}</a>
            </Heading>
          ))}
        </Nav>

        <Office>
          <Text>
            <b>COMMON GROUND WORKS SDN BHD</b>
          </Text>
          <Text>Penthouse 16-1 Level 16, Wisma UOA Damansara II,</Text>
          <Text>No 6, Changkat Semantan, Damansara Heights,</Text>
          <Text>50490 Kuala Lumpur.</Text>
        </Office>

        <Contact>
          <Text>info@commonground.work</Text>
          <Text>+60 3-2011 9888</Text>
        </Contact>
      </Sitemap>
    </Container>

    <Container>
      <HR />
    </Container>

    <Container>
      <Copyright>
        <Text>© 2022 Common Ground Works Sdn Bhd. All rights reserved.</Text>
      </Copyright>
      <Spacer />
      <Legals>
        <Legal>
          <Text>
            <a href="/terms-and-conditions">Terms & Conditions</a>
          </Text>
        </Legal>
        <Legal>
          <Text>
            <a href="/privacy">Privacy Policy</a>
          </Text>
        </Legal>
      </Legals>
    </Container>
  </Wrapper>
)

export default Footer
