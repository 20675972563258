import React from 'react'
import styled from 'styled-components'

import 'assets/css/react-datepicker.css'

import WrappedContainer from 'components/Common/WrappedContainer'
import { H1, P } from 'components/Common/Typography'
import Button from 'components/Common/Button'
import { Input, Label, Select, DatePicker } from 'components/Common/Input'

///////////////////////////////////////////////////////////////////////////////
// Styling

const LeftBlock = styled.div`
  flex: 1;
  margin-right: 3rem;

  @media (max-width: 900px) {
    min-width: 100%;
    margin: auto auto 2rem auto;
    text-align: center;
  }
`

const RightBlock = styled.div`
  flex: 1;
`

const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

const InputField = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.5rem;
  width: calc(50% - 1rem);
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class BookATour extends React.Component {
  state = {
    locations: null,
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  // Fetch minimal list of offices in order to populate
  // 'Preferred Location' Select options
  async componentDidMount() {
    try {
      const { data } = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/items/countries?fields=name,cities.name,cities.offices.building`
      ).then(res => res.json())

      this.setState({
        locations: data,
      })

      // Update the location <Select> to a default value if one was provided
      this.locationSelect.value = this.props.defaultOffice || 'none'
    } catch (err) {
      console.error(err)
    }
  }

  ///////////////
  // Functions //
  ///////////////

  handleSubmit(e) {
    e.preventDefault && e.preventDefault()

    alert("This doesn't do anything yet!")
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { locations } = this.state

    let locationChoices = []

    if (locations) {
      for (let country of locations) {
        let cities = country.cities.filter(city => city.offices.length > 0)

        for (let city of cities) {
          let offices = city.offices

          locationChoices.push(
            <optgroup
              key={`book-a-tour-optgroup-${country.name}-${city.name}`}
              label={`${country.name} - ${city.name}`}
            >
              {offices.map(office => (
                <option
                  key={`book-a-tour-opt-${country.name}-${city.name}-${office.building}`}
                  value={office.building}
                >
                  {office.building}
                </option>
              ))}
            </optgroup>
          )
        }
      }
    }

    return (
      <WrappedContainer
        id="book-a-tour"
        background={`url('/images/book-a-tour-bg.jpg')`}
        style={{ wrapper: { minHeight: '70vh' }, container: { flexFlow: 'row wrap' } }}
      >
        <LeftBlock>
          <H1>BOOK A TOUR WITH US</H1>
          <P>
            Seeing is believing. Schedule a visit with us to experience what Common Ground is all
            about.
          </P>
        </LeftBlock>

        <RightBlock>
          <Form onSubmit={e => this.handleSubmit(e)}>
            <InputField>
              <Label>YOUR NAME</Label>
              <Input required type="text" placeholder="Enter Your Name" />
            </InputField>

            <InputField>
              <Label>EMAIL</Label>
              <Input required type="email" placeholder="Email Address" />
            </InputField>

            <InputField>
              <Label>PHONE NUMBER</Label>
              <Input required type="tel" placeholder="Phone Number" />
            </InputField>

            <InputField>
              <Label>PREFERRED LOCATION</Label>
              <Select lineOnly ref={el => (this.locationSelect = el)}>
                <option hidden disabled value="none">
                  Select a location...
                </option>
                {locationChoices}
              </Select>
            </InputField>

            <InputField style={{ width: '100%' }}>
              <Label>PREFERRED DATE & TIME</Label>
              <DatePicker required />
            </InputField>

            <Button style={{ margin: '2rem auto auto auto' }}>SUBMIT</Button>
          </Form>
        </RightBlock>
      </WrappedContainer>
    )
  }
}

export default BookATour
