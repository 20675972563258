import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import Layout from 'components/Common/Layout'
import WrappedContainer from 'components/Common/WrappedContainer'
import { H1, H2, H3, H5, P } from 'components/Common/Typography'

const md = require('data/faq.md')

///////////////////////////////////////////////////////////////////////////////
// Styling

const Selector = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: center;
  margin: -2rem 0;
`

const SelectorChoice = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 1rem;
  text-align: center;
  border: 1px solid #cccccc;
  letter-spacing: 2px;
  font-family: Acrom;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.2s ease all;

  a {
    color: inherit;
    text-decoration: none;
    padding: 1rem 2rem;
  }

  :hover {
    border-color: #000000;
  }

  @media (max-width: 800px) {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
  }

  ${props =>
    props.selected &&
    `
    border-color: #000000;
  `}
`

const LeftBlock = styled.div`
  flex: 1;
  margin-top: 100px;
  margin-right: 3rem;

  @media (max-width: 800px) {
    min-width: 100%;
    margin: auto auto 2rem auto;
  }
`

const Category = styled(H2)`
  // Padding needed because of navbar covering the # anchor links
  padding-top: 100px;
  margin-top: -100px;
  margin-bottom: 0;
`

const Q = styled(H3)`
  letter-spacing: 0.3px;
  margin-top: 2rem;
`

const A = styled(P)`
  a {
    color: #ca9018;
  }
`

const Break = styled.hr`
  border-color: #faca66;
  width: 6rem;
  margin: 4rem auto 2rem -2rem;
  box-sizing: border-box;
  border-width: 0.5rem;
  border-style: solid;
`

const MoreHelp = styled.div`
  ${P} {
    font-size: 1.2rem;

    a {
      color: inherit;
    }
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition
class FaqPage extends React.Component {
  state = {
    data: null,
    categories: [],
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  async componentDidMount() {
    const data = await fetch(md).then(res => res.text())
    const categories = data.match(/^#[^#\n]+/gm).map(val => val.replace(/# /, ''))

    this.setState({
      data,
      categories,
    })
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { data, categories } = this.state

    return (
      <Layout style={{ background: '#fff5e2' }}>
        <Helmet>
          <title>FAQ | Common Ground</title>
          <style>{`
            #navbar.expanded {
              color: #000000;
            }
          `}</style>
        </Helmet>

        <section id="header">
          <WrappedContainer
            background="transparent"
            style={{
              wrapper: { minHeight: '50vh' },
              container: { flexFlow: 'row wrap', alignItems: 'center' },
            }}
          >
            <LeftBlock>
              <H5>FREQUENTLY ASKED</H5>
              <H1 style={{ marginBottom: '0.5rem' }}>QUESTIONS</H1>
              {/* <P style={{ marginBottom: '0' }}>Build the perfect office for your company</P> */}
            </LeftBlock>
          </WrappedContainer>
        </section>

        <section id="faq">
          <WrappedContainer>
            <Selector>
              {categories.map(category => (
                <SelectorChoice key={`faq-category-${category}`}>
                  <a href={`#${category}`.toLowerCase()}>{category}</a>
                </SelectorChoice>
              ))}
            </Selector>

            <ReactMarkdown
              source={data}
              renderers={{
                heading: ({ level, children }) => {
                  if (level === 1) {
                    const category = children[0].props.value.toLowerCase()
                    return <Category id={category}>{children}</Category>
                  }
                  if (level === 3) {
                    return <Q>{children}</Q>
                  }
                },
                paragraph: ({ children }) => <A>{children}</A>,
                thematicBreak: () => <Break />,
              }}
            />
          </WrappedContainer>
        </section>

        <section id="more-help">
          <WrappedContainer background="#f5f0e8">
            <MoreHelp>
              <H2>NEED ADDITIONAL HELP?</H2>
              <P>Feel free to reach out to us:</P>
              <P>
                <a href="mailto:info@commonground.work">info@commonground.work</a>
              </P>
              <P>
                <a href="tel:+60320119888">+60 3201 9888</a>
              </P>
            </MoreHelp>
          </WrappedContainer>
        </section>
      </Layout>
    )
  }
}

export default FaqPage
