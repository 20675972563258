import React from 'react'
import TelInput from 'react-intl-tel-input'

import 'assets/css/react-intl-tel-input.css'

// CountrycodePicker is just a wrapper around react-intl-tel-input to:
// 1. Initialize with some standard values and settings
// 2. Include a hidden <input> which contains the selected value (makes it easier for native forms)

export class CountrycodePicker extends React.Component {
  state = {
    area: '60', // todo: update this based on user locale/geo-location
  }

  ///////////////
  // Functions //
  ///////////////

  selectCountry(country) {
    this.setState({ area: country.dialCode })
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { required = true, name = 'country_code', options } = this.props
    const { area } = this.state

    return (
      <>
        <div style={{ display: 'flex', width: '100%', fontFamily: 'Calibre' }}>
          <TelInput
            containerClassName="intl-tel-input cg-custom"
            preferredCountries={['my', 'ph']}
            separateDialCode
            telInputProps={{ hidden: true, readOnly: true, tabIndex: '-99' }}
            onSelectFlag={(tel, country) => this.selectCountry(country)}
            {...options}
          />
        </div>

        {/* This 'hidden input field' is here so that any form using this component can
          easily access the value like any other input field. */}
        <input
          required={required}
          hidden
          readOnly
          name={name}
          onChange={() => true} // Add an onChange to suppress input warnings
          value={area}
        />
      </>
    )
  }
}

export default CountrycodePicker
