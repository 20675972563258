import React from 'react'
import styled from 'styled-components'

import Testimonial from 'components/Testimonial'
import { H1, H5 } from 'components/Common/Typography'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff0f0;
  padding: 5rem 2rem;
  box-sizing: border-box;
`

const Container = styled.div`
  min-height: 300px;
  max-width: 1200px;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin: auto;

  @media (max-width: 800px) {
    align-items: center;
  }
`

const TestimonialsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`

///////////////////////////////////////////////////////////////////////////////
// Styling

const Testimonials = props => (
  <Wrapper>
    <Container>
      <H5>UNEDITED FROM THE FRONTLINE</H5>
      <H1>TESTIMONIALS</H1>

      <TestimonialsWrapper>
        {props.testimonials &&
          props.testimonials.map((testimonial, index) => (
            <Testimonial {...testimonial} key={`testimonial-${index}`} />
          ))}
      </TestimonialsWrapper>
    </Container>
  </Wrapper>
)

export default Testimonials
