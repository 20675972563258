import React from 'react'
import Helmet from 'react-helmet'

import Layout from 'components/Common/Layout'
import Carousel from 'components/Carousel'
import TextBlock from 'components/TextBlock'
import Packages from 'components/Packages'
import Tenants from 'components/Tenants'
import Testimonials from 'components/Testimonials'
import BookATour from 'components/BookATour'
import LocationsCarousel from 'components/Locations/LocationsCarousel'

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class HomePage extends React.Component {
  state = {
    slides: null,
    testimonials: null,
    tenants: null,
    locationsSlides: null,
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  async componentDidMount() {
    try {
      let { data } = await fetch(process.env.REACT_APP_API_URL + '/custom/home').then(res =>
        res.json()
      )

      // todo: currently the home page API just returns a boolean value, but it should be an object
      // with more meaning information for the button.
      // for now the functionality is limited to just either showing/not showing the booking button -
      // no ability to create customized url buttons.

      for (let slide of data.carousel) {
        if (slide.button) {
          slide.button = {
            text: 'BOOK A TOUR',
            url: '#book-a-tour',
          }
        }
      }

      this.setState({
        slides: data.carousel,
        testimonials: data.testimonials,
        tenants: data.tenants,
        locationsSlides: data.locations_slides,
      })
    } catch (err) {
      console.error(err)
    }
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { slides, locationsSlides, testimonials, tenants } = this.state

    return (
      <Layout>
        <Helmet>
          <title>Home | Common Ground</title>
        </Helmet>

        <section id="header">
          <Carousel slides={slides} />
        </section>

        <section id="mission">
          <TextBlock
            maxWidth="800px"
            title="AMBITION LIVES HERE"
            text="AT COMMON GROUND, OUR MISSION IS TO REDEFINE THE CONCEPT OF A WORKSPACE - WITH COMMUNITY AT ITS CORE AND SUPPORT SERVICES TO FOSTER THE SUCCESS OF EVERY BUSINESS"
            style={{
              container: {
                textAlign: 'center',
                padding: '5rem 2rem',
              },
              text: {
                letterSpacing: '1px',
              },
            }}
          />
        </section>

        <section id="locations">
          <LocationsCarousel slides={locationsSlides} />
        </section>

        <section id="packages">
          <Packages minimal />
        </section>

        <section id="tenants">
          <Tenants tenants={tenants} />
        </section>

        <section id="testimonials">
          <Testimonials testimonials={testimonials} />
        </section>

        <section id="book-a-tour">
          <BookATour />
        </section>
      </Layout>
    )
  }
}

export default HomePage
