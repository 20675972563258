import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import MissingPage from 'views/missing'
import Carousel from 'components/Carousel'
import TextBlock from 'components/TextBlock'
import Packages from 'components/Packages'
import BookATour from 'components/BookATour'

import Layout from 'components/Common/Layout'
import LoadingRing from 'components/Common/Loading'

import OfficeDescription from 'components/Office/Description'
import OfficeAmenities from 'components/Office/Amenities'
import OfficeNeighborhood from 'components/Office/Neighborhood'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Loading = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class OfficePage extends React.Component {
  state = {
    data: null,
    err: null,
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  async componentDidMount() {
    try {
      const { params } = this.props.match

      const url = `${process.env.REACT_APP_API_URL}/custom/offices/${params.office}`

      let { data } = await fetch(url).then(res => res.json())

      if (!data || data.length <= 0) {
        throw new Error('Invalid location')
      }

      // Because we're doing a filter-like query, it returns an array.
      // If a match was found, it will be the first item in the array.
      // This assumes there aren't multiple buildings with the same name.
      this.setState({ office: data })
    } catch (err) {
      console.error(err)
      this.setState({ err })
    }
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { office, err } = this.state

    if (err)
      return <MissingPage additionalLink={{ href: '/locations', value: 'VIEW ALL LOCATIONS' }} />

    return (
      <Layout>
        {office ? (
          <>
            <Helmet>
              <title>{office.building} | Common Ground</title>
            </Helmet>

            <section>
              <Carousel
                height="65vh"
                slides={[
                  {
                    pretitle: `COMMON GROUND @ ${office.area}`.toUpperCase(),
                    title: `${office.building}`.toUpperCase(),
                    image: (office.banner && office.banner.url) || null,
                    button: { url: '#book-a-tour', text: 'BOOK A TOUR' },
                  },
                ]}
              />
            </section>

            <section>
              <OfficeDescription office={office} />
            </section>

            <section>
              <OfficeAmenities office={office} />
            </section>

            <section>
              <Packages office={office} />
            </section>

            <section>
              <OfficeNeighborhood office={office} />
            </section>

            {/* todo: render all the staff instead of just the first */}
            {office.staff.length > 0 && (
              <section>
                <TextBlock
                  pretitle="WE'VE GOT YOUR BACK"
                  title="COMMUNITY MANAGER"
                  subtitle={`${office.staff[0].first_name} ${
                    office.staff[0].last_name
                  }`.toUpperCase()}
                  image={
                    (office.staff[0].image && office.staff[0].image.url) ||
                    '/images/c-placeholder.png'
                  }
                  style={{
                    subtitle: { letterSpacing: '1px', marginBottom: 0 },
                    container: { alignItems: 'center' },
                  }}
                >
                  <div style={{ fontSize: '1rem', color: '#3e3e3e', marginBottom: '1.5rem' }}>
                    {office.staff[0].email}
                  </div>
                  {/* todo: better way to display this */}
                  {/* <div>{office.manager.description || ''}</div>  */}
                </TextBlock>
              </section>
            )}

            <section id="book-a-tour">
              <BookATour office={office} defaultOffice={office.building} />
            </section>
          </>
        ) : (
          <Loading>
            <Helmet>
              <style>{`
                #navbar.expanded {
                  color: #000000;
                }
            `}</style>
            </Helmet>

            <LoadingRing color="#ffcd66" />
          </Loading>
        )}
      </Layout>
    )
  }
}

export default OfficePage
