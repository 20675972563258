import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import emailjs from 'emailjs-com'
import { FaExclamationCircle } from 'react-icons/fa'

import CountryCodes from './CountryCodes'
import Locations from './LocationsWithJohorBranch'

const background = require('./images/promos-kakitangan.jpg')
const kakitanganThumbnail = require('./images/thumbnail-kakitagan.png')

///////////////////////////////////////////////////////////////////////////////
// Styling

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #111111;
  background: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: 1200px;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  padding: 3rem 2rem;
  z-index: 10;

  @media (max-width: 900px) {
    flex-flow: column nowrap;
    padding: 1rem;
  }
`

const Header = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  margin-bottom: 3rem;

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }

  @media (max-width: 900px) {
    text-align: center;
    height: 50px;
  }
`

const Content = styled.div`
  flex: 7;
  width: 100%;
  color: #ffffff;
  font-family: Calibre;
  margin-right: 2rem;

  h1 {
    font-size: 3rem;
    margin-top: 0;
  }

  a {
    color: #fecd6f;
  }

  h5 {
    font-weight: normal;
    color: #b1b1b1;
  }

  p {
    line-height: 1.3rem;
    letter-spacing: 0.5px;
  }

  li {
    margin-bottom: 0.4rem;
  }

  @media (max-width: 900px) {
    max-width: 600px;
    margin: auto auto 2rem auto;

    h1 {
      font-size: 2.2rem;
    }

    h2 {
      font-size: 1.7rem;
    }
  }
`

const KakitanganImage = styled.div`
  width: 100%;
  height: 100%;
  min-height: 60px;
  background: #111111;
  background: url(${kakitanganThumbnail});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
`

const Survey = styled.div`
  flex: 4;
  width: 100%;
  padding: 2rem;
  margin-top: 1rem;
  background: rgba(255, 248, 234, 0.8);
  border-radius: 16px;

  @media (max-width: 900px) {
    padding: 1rem;
    margin: auto;
    margin-bottom: 3rem;
    max-width: 500px;
  }
`

const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

const Input = styled.input`
  width: 100%;
  margin-bottom: 1rem;
  padding: 6px 12px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  ${props =>
    props.width &&
    `
    width: ${props.width}
  `}
`

const Select = styled.select`
  width: 100%;
  margin-bottom: 1rem;
  padding: 6px 12px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  ${props =>
    props.width &&
    `
    width: ${props.width}
  `}
`

const SubmitButton = styled.button`
  background: #fecd6f;
  padding: 13px;
  width: 100%;
  border: none;
  cursor: pointer;
  transition: 0.2s ease all;

  :hover {
    background: #fec458;
  }

  :disabled {
    background: #e5c993;
    cursor: default;
  }
`

const ErrorWarning = styled.div`
  color: #ffffff;
  text-align: center;
  width: 100%;
  margin: 0.5rem;

  div {
    display: inline-block;
    background: #e84f4f;
    border-radius: 16px;
    padding: 0 12px;
    height: 1.8rem;
    line-height: 1.8rem;
  }
`

const Footer = styled.footer`
  background: #151716;
  color: #58585a;
  padding: 1rem;
`

const FooterContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  font-size: 0.85rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  a,
  span {
    color: inherit;
    margin: 0 1rem;
  }

  a {
    margin-right: 3rem;
    text-decoration: none;

    &:hover {
      color: #fecd6f;
    }
  }

  @media (max-width: 900px) {
    span {
      width: 100%;
      margin-bottom: 1rem;
    }

    a {
      margin-right: none;
    }

    div {
      display: none;
    }
  }
`

///////////////////////////////////////////////////////////////////////////////
// Functions

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class PromosKakitanganPage extends React.Component {
  state = {
    loading: null,
    error: null,
    submitted: false,
  }

  ///////////////
  // Functions //
  ///////////////

  async submitForm(e) {
    try {
      e.preventDefault && e.preventDefault()

      this.setState({ loading: 'Submitting...', error: null })

      /////////////////////////////////
      // Send an email using Emailjs //
      /////////////////////////////////

      // Change these values if this page is duplicated
      const PROMO_NAME = 'Big Wins For SME Memberships Kakitangan'
      const PROMO_ENDPOINT = 'big-wins-for-sme-memberships-kakitangan'

      // Don't touch these values
      const userID = 'user_j0kmOkHQpkxYeRD9S0OZH'
      const serviceID = 'service_mailgun'
      const templateID = 'template_basic'

      const templateParams = {
        emailjs_title: PROMO_NAME,
        emailjs_url: `https://promos.commonground.work/${PROMO_ENDPOINT}`,
        first_name: e.target.first_name.value,
        last_name: e.target.last_name.value,
        email: e.target.email.value,
        phone_number: `+${e.target.contact_country_code.value}${e.target.contact_number.value}`,
        company_name: e.target.company_name.value,
        product_type: e.target.product_type.value,
        location: e.target.location.value,
        pax: e.target.pax.value,
      }

      await emailjs.send(serviceID, templateID, templateParams, userID)

      this.setState({ submitted: true })

      // Redirect to 'thank you' page (for analytic usage)
      if (window.location.hostname.includes('promos.commonground.work')) {
        window.location.href = `/${PROMO_ENDPOINT}/thank-you`
      } else {
        window.location.href = `/promos/${PROMO_ENDPOINT}/thank-you`
      }
    } catch (err) {
      console.error(err)
      this.setState({ loading: null, error: err.message })
    }
  }

  generateRef(prefix = '') {
    // Legacy ref generation function which uses
    // Mersenne Twister PHP (mt_rand) and substring length
    // http://locutus.io/php/math/mt_rand/
    let min = parseInt(0, 10)
    let max = new Date().getTime()
    let rand = Math.floor(Math.random() * (max - min + 1)) + min
    rand = `${rand}`.substring(0, 10)
    return prefix + rand
  }

  ////////////
  // Render //
  ////////////

  render() {
    return (
      <>
        <Helmet>
          <title>Kakitangan.com x Common Ground</title>
          <style>{`
      html {
        font-size: 16px;
      }
    `}</style>
        </Helmet>

        <Page>
          <DarkOverlay />

          <Container>
            <Header>
              <a href="https://www.commonground.work">
                <img src="/images/cg-logo.png" alt="Common Ground" />
              </a>
            </Header>

            <Content>
              <h1>Big Wins for SMEs</h1>
              <h2>
                Get a coworking membership and enjoy RM1,000 credits on Kakitangan’s HR modules
              </h2>

              <KakitanganImage />

              <p>
                Get a coworking membership and enjoy RM1,000 credits on Kakitangan’s HR modules! Buy
                a Hot Desk, Fixed Desk or Private Office membership and end your troubles of tedious
                operations for payroll, leave, claims, or even attendance recording. SMEs will
                benefit from a high security and business continuity solution that enables faster
                and more efficient payroll and HR processing with Kakitangan.com.
              </p>

              <h3>Coworking Membership Benefits (Included Monthly):</h3>

              <ul>
                <li>
                  Access from 8:00 AM–6:00 PM, Monday to Friday (Closed for public holidays and
                  weekends)
                </li>
                <li>Stylish Interiors & Conducive Workspaces</li>
                <li>Complimentary High-Speed Internet</li>
                <li>Complimentary Coffee, Tea and Water</li>
                <li>Members-only pricing for Weekly Events, Workshops, Talks</li>
                <li>500+ Lifestyle, F&B, Services Discounts/Benefits via Common Ground app</li>
                <li>In-House Cafe and CG Snack Corner (Additional charges apply</li>
                <li>
                  On Duty Staff: Dedicated front desk staff and Community Manager (Mon-Fri 9am-6pm
                </li>
                <li>
                  Business Support Services (Members rates for Accounting, HR, Legal, Insurance
                  services)
                </li>
                <li>Daily Professional Cleaning Service</li>
              </ul>

              <h3>Hot Desk Benefits:</h3>
              <ul>
                <li>Choose to work from any desk in the shared workspace area</li>
                <li>
                  Complimentary 2 hours access to Meeting Rooms (Additional hours at discounted
                  rates)
                </li>
                <li>
                  Business Class Printers: 80 Black & White or 16 Colour pages (Additional printing
                  at discounted rates)
                </li>
              </ul>

              <h3>Fixed Desk Benefits:</h3>
              <ul>
                <li>
                  Enjoy a preferred fixed desk with a lockable drawer of your own with more privacy
                </li>
                <li>
                  Complimentary 5 hours access to Meeting Rooms (Additional hours at discounted
                  rates)
                </li>
                <li>
                  Business Class Printers: 120 Black & White or 24 Colour pages (Additional printing
                  at discounted rates)
                </li>
              </ul>

              <h3>Private Office Benefits:</h3>
              <ul>
                <li>Gain 24/7 access to a fully furnished enclosed and lockable office space</li>
                <li>
                  Complimentary 12 hours access to Meeting Rooms (Additional hours at discounted
                  rates)
                </li>
                <li>
                  Business Class Printers: 500 Black & White or 100 Colour pages (Additional
                  printing at discounted rates)
                </li>
              </ul>

              <p>Limited to first 30 SME sign-ups for a Common Ground coworking membership!</p>

              <div style={{ color: '#eeeeee', fontSize: '0.8rem' }}>
                <p>*Terms and conditions apply as below:</p>

                <ul>
                  <li>Applicable for new Hot Desk, Fixed Desk, Private Office memberships only.</li>
                  <li>Valid for first 30 SME sign ups to a Common Ground coworking membership.</li>
                  <li>
                    Must sign up for a minimum 3-month Hot Desk/Fixed Desk/Private Office
                    membership.
                  </li>
                  <li>Published membership rates apply as listed on www.commonground.work.</li>
                  <li>
                    This promotion is only valid for membership in Common Ground venues in Malaysia,
                    with the exception of enterprise locations that are ‘powered by Common Ground’.
                    Locations can be viewed here:{' '}
                    <a href="https://www.commonground.work/locations">
                      www.commonground.work/locations
                    </a>
                    .
                  </li>
                  <li>
                    This promotion cannot be combined with any other ongoing offers/promotions.
                  </li>
                  <li>
                    Common Ground reserves the right to change these terms and conditions at any
                    time without prior notice.
                  </li>
                </ul>
              </div>
            </Content>

            <Survey>
              <Form onSubmit={e => this.submitForm(e)}>
                <Input
                  required
                  name="first_name"
                  type="text"
                  width="48%"
                  placeholder="First Name"
                />

                <Input required name="last_name" type="text" width="48%" placeholder="Last Name" />

                <Input required name="email" type="email" placeholder="Email Address" />

                <Select required name="contact_country_code" defaultValue="" width="38%">
                  <option disabled value="">
                    Country Code
                  </option>
                  <CountryCodes />
                </Select>

                <Input
                  required
                  name="contact_number"
                  type="number"
                  placeholder="Phone Number"
                  width="58%"
                />

                <Input
                  required
                  name="company_name"
                  type="text"
                  placeholder="Company Name"
                  autoComplete="off"
                />

                <Select required name="location" defaultValue="">
                  <option disabled value="">
                    Common Ground Venue
                  </option>
                  <Locations />
                </Select>

                <Select required name="pax" title="I Need a Space For (pax)" defaultValue="">
                  <option disabled value="">
                    I Need a Space For
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="10+">10+</option>
                </Select>

                <Select required name="product_type" title="Membership Type" defaultValue="">
                  <option disabled value="">
                    Membership Type
                  </option>
                  <option value="HOT DESK">Hot Desk</option>
                  <option value="FIXED DESK">Fixed Desk</option>
                  <option value="PRIVATE OFFICE">Private Office</option>
                </Select>

                <SubmitButton disabled={this.state.loading}>
                  {this.state.loading || 'Submit'}
                </SubmitButton>

                {this.state.error && (
                  <ErrorWarning>
                    <div>
                      <FaExclamationCircle style={{ marginBottom: '-3px', marginRight: '6px' }} />
                      {this.state.error}
                    </div>
                  </ErrorWarning>
                )}
              </Form>
            </Survey>
          </Container>
        </Page>

        <Footer>
          <FooterContainer>
            <span>© 2019 Common Ground Works Sdn Bhd. All rights reserved.</span>

            <div style={{ flex: 1 }} />

            <a href="https://www.commonground.work/terms-and-conditions">Terms & Conditions</a>

            <a href="https://www.commonground.work/privacy">Privacy Policy</a>
          </FooterContainer>
        </Footer>
      </>
    )
  }
}

export default PromosKakitanganPage
