import React from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'
import SimpleBar from 'simplebar-react'
import { MdLocationOn } from 'react-icons/md'
import { FaMapMarkedAlt } from 'react-icons/fa'

import Pin from 'components/Locations/GoogleMapsExplorer/Pin'
import { H1, H5, P } from 'components/Common/Typography'
import Button from 'components/Common/Button'

import { parseGoogleMapsURL } from 'utils'

import 'assets/css/simplebar.css'

///////////////////////////////////////////////////////////////////////////////
// Constants

// https://developers.google.com/maps/documentation/javascript/reference/#MapOptions
const googleMapOptions = {
  disableDefaultUI: true,
  clickableIcons: false,
}

///////////////////////////////////////////////////////////////////////////////
// Styling

const Container = styled.div`
  position: relative;
  width: 100%;
  background: #fff5e2;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row nowrap;
  transition: 0.3s ease all;

  @media (max-width: 800px) {
    flex-flow: column nowrap;
  }
`

const ContentWrapper = styled.div`
  width: 50%;
  margin-right: 50%;
  height: 100%;

  @media (max-width: 800px) {
    width: 100%;
  }
`

const Heading = styled.div`
  max-width: 580px;
  height: 100%;
  flex: 1;
  padding: 3rem 2rem 1rem 2rem;
  margin: 0 auto;
  border-bottom: 1px solid rgba(100, 100, 100, 0.1);

  h1 {
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    max-width: unset;
    text-align: center;
  }
`

const Address = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 420px;
  padding: 0.5rem;
  text-align: left;

  @media (max-width: 800px) {
    max-width: unset;
    padding: 1rem;
  }
`

const AddressLine = styled(P)`
  margin: 0;

  a {
    color: inherit;
  }
`

const Map = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  max-width: 50%;
  height: 100%;
  flex: 1;
  background: grey;

  @media (max-width: 800px) {
    display: none;
  }
`

const GoogleMapURL = styled.div`
  position: absolute;
  bottom: 1rem;
  width: 50%;
  margin-left: 50%;
  z-index: 1;
  text-align: center;

  @media (max-width: 800px) {
    position: relative;
    width: 100%;
    margin: 1rem auto;
  }
`

const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
`

const Listing = styled.div`
  max-height: 500px;
  min-height: 400px;
  width: 100%;

  @media (max-width: 800px) {
    max-height: unset;
  }
`

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  margin: 2rem auto;
`
const Left = styled.div`
  flex: 1;
  padding: 0.1rem 1rem;
  text-align: right;
  font-size: 0.9rem;
  letter-spacing: 2px;
  font-weight: bold;
  text-transform: uppercase;
  word-break: break-word;
  font-family: Acrom;
  border-right: 2px solid;
  border-color: ${props => props.theme && props.theme.cgYellow};
`

const Right = styled.div`
  flex: 1;
  font-family: Calibre;
  padding: 0.1rem 1rem;
`

const Item = styled.div`
  font-family: Calibre;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class OfficeNeighborhood extends React.Component {
  ////////////
  // Render //
  ////////////

  render() {
    const { office } = this.props
    // const { features } = this.state

    if (!office) return null

    const { lat, lng, place } = parseGoogleMapsURL(office.google_maps_url)

    return (
      <Container>
        <ContentWrapper>
          <Heading>
            <H5>{`${office.building}`.toUpperCase()}</H5>

            <H1>THE NEIGHBORHOOD</H1>

            <Address>
              <MdLocationOn style={{ flexShrink: 0, margin: '0.2rem 1rem 0.2rem 0' }} />
              <AddressLine>
                <a href={office.google_maps_url}>{office.address}</a>
              </AddressLine>
            </Address>
          </Heading>

          <Content>
            <Listing>
              <SimpleBar
                id="simple-bar"
                data-simplebar-auto-hide={false}
                data-simplebar-force-visible="y"
              >
                {office.features.map(feature => (
                  <Row key={`feature-row-${feature.name}`}>
                    <Left>{feature.name}</Left>
                    <Right>
                      {feature.list.split(',').map(item => (
                        <Item key={`feature-${feature.name}-${item.trim()}`}>{item.trim()}</Item>
                      ))}
                    </Right>
                  </Row>
                ))}
              </SimpleBar>
            </Listing>
          </Content>
        </ContentWrapper>

        <GoogleMapURL>
          <Button openInNewTab href={`https://www.google.com/maps/dir/Current+Location/${place}`}>
            <FaMapMarkedAlt size="1.2rem" style={{ margin: '0 0.5rem -0.25rem -0.2rem' }} /> GET
            DIRECTIONS
          </Button>
        </GoogleMapURL>

        <Map>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
            center={{ lat, lng }}
            zoom={15}
            options={googleMapOptions}
          >
            <Pin lat={lat} lng={lng} text={office.building} />
          </GoogleMapReact>
        </Map>
      </Container>
    )
  }
}

export default OfficeNeighborhood
