import React from 'react'
import styled from 'styled-components'
import Flickity from 'react-flickity-component'

///////////////////////////////////////////////////////////////////////////////
// Constants

const flickitySettings = {
  freeScroll: true,
  prevNextButtons: false,
  contain: true,
  pageDots: false,
  cellAlign: 'left',
}

// Ermmm this is kinda dodgy
const API_UPLOADS = process.env.REACT_APP_API_UPLOADS_URL

///////////////////////////////////////////////////////////////////////////////
// Styling

const AmenitiesWrapper = styled.div`
  padding: 0 ${props => props.theme.MOBILE.PAD};
`

const Amenity = styled.div`
  // width: calc(25% - ${props => props.theme.MOBILE.PAD});
  width: ${props => props.theme.MOBILE.ICON_WIDTH};
  margin-right: ${props => props.theme.MOBILE.PAD};
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 26px;
    height: 26px;
    margin-bottom: 0.5rem;
  }

  span {
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  :focus {
    outline: none;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Amenities = () => (
  <AmenitiesWrapper>
    <Flickity options={flickitySettings}>
      <Amenity>
        <img src={`${API_UPLOADS}/originals/wifi.png`} alt="Wifi" />
        <span>High speed internet</span>
      </Amenity>

      <Amenity>
        <img src={`${API_UPLOADS}/originals/coffee.png`} alt="Coffee" />
        <span>In house coffee, tea and water</span>
      </Amenity>

      <Amenity>
        <img src={`${API_UPLOADS}/originals/printer.png`} alt="Printer" />
        <span>Printers, copiers and scanners</span>
      </Amenity>

      <Amenity>
        <img src={`${API_UPLOADS}/originals/phone.png`} alt="Phone" />
        <span>Call booths</span>
      </Amenity>

      <Amenity>
        <img src={`${API_UPLOADS}/originals/support.png`} alt="SUpport" />
        <span>Support services</span>
      </Amenity>

      <Amenity>
        <img src={`${API_UPLOADS}/originals/calendar.png`} alt="Calendar" />
        <span>Weekly events</span>
      </Amenity>

      <Amenity>
        <img src={`${API_UPLOADS}/originals/couch.png`} alt="Couch" />
        <span>Stylish interiors</span>
      </Amenity>
    </Flickity>
  </AmenitiesWrapper>
)

export default Amenities
