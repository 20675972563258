import React from 'react'
import styled from 'styled-components'
import Flickity from 'react-flickity-component'
import { FaEnvelope } from 'react-icons/fa'

///////////////////////////////////////////////////////////////////////////////
// Constants

const flickitySettings = {
  freeScroll: true,
  prevNextButtons: false,
  contain: true,
  pageDots: false,
  cellAlign: 'left',
}

///////////////////////////////////////////////////////////////////////////////
// Styling

const StaffWrapper = styled.div`
  padding: 0 ${props => props.theme.MOBILE.PAD};
  margin: 0.5rem 0 1rem 0;
`

const StaffMemberWrapper = styled.div`
  width: calc(50% - ${props => props.theme.MOBILE.PAD});
  margin-right: ${props => props.theme.MOBILE.PAD};
  min-height: 100%;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: ${props => props.theme.MOBILE.BORDER_RADIUS};
`

const Image = styled.div`
  position: relative;
  width: 66%;
  height: 0;
  padding-bottom: 66%;
  margin-bottom: 0.5rem;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: 50%;
  }
`
const Name = styled.div`
  font-family: Acrom;
  font-weight: bold;
  font-size: 0.9rem;
  letter-spacing: -1px;
  text-align: center;
`

const Role = styled.div`
  font-size: 0.8rem;
  text-align: center;
`

const Contact = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: calc(100% + 1rem);
  margin: 0.25rem -0.5rem -0.5rem -0.5rem;
  // border-top: 1px solid rgba(0, 0, 0, 0.2);
`

const ContactItem = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0.6rem 0.3rem;
  color: inherit;
  line-height: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);

  :last-child {
    border-right: none;
  }
`

const Spacer = styled.div`
  flex: 1;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const StaffMember = props => (
  <StaffMemberWrapper>
    <Image>
      <img
        src={(props.image && props.image.url) || '/images/user-placeholder.png'}
        alt={`${props.first_name} ${props.last_name}`}
      />
    </Image>

    <Name>
      {props.first_name} {props.last_name}
    </Name>

    <Role>{i18n[props.role] || props.role}</Role>

    <Spacer />

    <Contact>
      <ContactItem href={`mailto:${props.email}`}>
        <FaEnvelope />
      </ContactItem>
    </Contact>
  </StaffMemberWrapper>
)

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Staff = props => (
  <StaffWrapper>
    <Flickity options={flickitySettings}>
      {props.staff.map(staffMember => (
        <StaffMember key={`staff-${staffMember.email}`} {...staffMember} />
      ))}
    </Flickity>
  </StaffWrapper>
)

export default Staff

const i18n = {
  'Community Manager': 'Community Manager',
  'Assistant Community Manager': 'Assistant Community Manager',
  'Community Officer': 'Community Officer',
}
