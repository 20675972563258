import React from 'react'
import styled from 'styled-components'

import Tenant from 'components/Tenant'
import { H1 } from 'components/Common/Typography'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fffbf5;
  padding: 5rem 2rem;
  box-sizing: border-box;
`

const Container = styled.div`
  max-width: 1200px;
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin: auto;
  text-align: center;
  justify-content: center;

  @media (max-width: 800px) {
    align-items: center;
  }
`

const TestimonialsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Tenants = props => (
  <Wrapper>
    <Container>
      <H1 style={{ margin: '2rem 0' }}>HAPPY TENANTS</H1>

      <TestimonialsWrapper>
        {props.tenants &&
          props.tenants.map((tenant, index) => <Tenant {...tenant} key={`tenant-${index}`} />)}
      </TestimonialsWrapper>
    </Container>
  </Wrapper>
)

export default Tenants
