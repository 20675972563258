const mappings = [
  {
    code: 'CGRW',
    name: '8 Rockwell',
    country: 'PH',
  },
  {
    code: 'CGIBP_10F',
    name: 'IBP Tower (10th Floor)',
    country: 'PH',
  },
  {
    code: 'CGIBP_PENTHOUSE',
    name: 'IBP Tower (Penthouse)',
    country: 'PH',
  },
  {
    code: 'CGACPT_20F',
    name: 'Arthaland Century Pacific Tower (20th Floor)',
    country: 'PH',
  },
  {
    code: 'CGACPT_29F',
    name: 'Arthaland Century Pacific Tower (29th Floor)',
    country: 'PH',
  },
  {
    code: 'CGACPT_30F',
    name: 'Arthaland Century Pacific Tower (30th Floor)',
    country: 'PH',
  },
]

export default mappings
