import React from 'react'
import _ from 'lodash'
import querystring from 'querystring'
import styled from 'styled-components'
import Modal from 'react-modal'
import Helmet from 'react-helmet'
import Select from 'react-select'
import Markdown from 'react-markdown'
import AnimateHeight from 'react-animate-height'
import SwipeableViews from 'react-swipeable-views'
import { IoIosCheckmarkCircle, IoIosRadioButtonOff, IoMdAlert } from 'react-icons/io'
import { Buffer } from 'buffer'

import Fade from './components/Fade'
import FloatingButton from './components/FloatingButton'
import LoadingRing from 'components/Common/Loading'

import { submitClerkForm } from './api'

import { toCurrency, optionify } from 'utils'

//
// ─── CONSTANTS ──────────────────────────────────────────────────────────────────
//

const NUM_DESCRIPTION_LINES = 2

const selectStyles = {
  control: styles => ({ ...styles, margin: '0.25rem' }),
  option: styles => ({
    ...styles,
    padding: '1rem',
  }),
}

const selectTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: 'rgba(24, 58, 51, 0.85)',
    primary75: 'rgba(24, 58, 51, 0.75)',
    primary50: 'rgba(24, 58, 51, 0.50)',
    primary25: 'rgba(24, 58, 51, 0.05)',
  },
})

// https://github.com/reactjs/react-modal
const modalStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  content: {
    background: 'none',
    border: 'none',
    borderRadius: 0,
    padding: 0,
    width: 'auto',
    maxWidth: 'calc(100% - 2rem)',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

//
// ─── STYLING ────────────────────────────────────────────────────────────────────
//

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  min-height: 100%;
  max-width: 1200px;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  z-index: 10;

  @media (max-width: 900px) {
    flex-flow: column nowrap;
  }
`

const Content = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  color: #183a33;
  font-family: Calibre;
  margin: auto;

  h1 {
    font-family: Acrom;
    font-size: 1.4rem;
    letter-spacing: -2px;
    margin-bottom: 0.8rem;
  }

  h2 {
    font-size: 1.7rem;
    font-family: Cheltenham;
    font-weight: normal;
  }
`

const SwipeableView = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  max-width: 1200px;
  padding: 1rem;
  overflow: auto;
`

const BetaWarning = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: -1rem -1rem 0 -1rem;
  padding: 1rem;
  background-color: #eeeeee;
  color: #666666;

  svg {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }
`

const ProductOptionsList = styled.div`
  margin-top: 2rem;
`

const Option = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;
  margin: 1rem 0;
  color: rgba(255, 255, 255, 0.8);
  background: ${props => props.background || 'rgba(0, 0, 0, 0.1)'};
  box-shadow: 0 2px 5px 0 rgba(178, 175, 175, 0.3);
  border: 0.8px solid #dcdcdc;
  transition: 0.2s ease all;

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background: transparent;
    transition: 0.2s ease all;
  }

  ${props =>
    props.selected &&
    `
    :after {
      background: rgba(255,255,255,0.35);
      box-shadow: 0 2px 8px 0 rgba(178, 175, 175, 0.70);
    }
  `}

  input[type=checkbox] {
    transform: scale(1.5);
    margin-left: 1rem;
  }

  .heading {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: Acrom;
    letter-spacing: -1px;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }

  .description {
    position: relative;
    padding-right: 2rem;
    line-height: 1rem;
    white-space: pre-line;
    overflow: hidden;

    ul {
      padding-left: 1.5rem;

      li {
        margin-bottom: 0.25rem;
      }
    }

    p,
    ul {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    ${props =>
      props.expanded &&
      `
      padding-right: 0;
    `}
  }

  .expand-toggle {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0.75rem;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
  }
`

const Checkbox = styled.div`
  align-self: flex-start;
  justify-self: flex-end;
  margin: -0.3rem;
  color: #222222;
`

const ExpandToggleArrow = styled.div`
  position: relative;
  height: 12px;
  width: 12px;
  margin-right: 6px;

  :after {
    content: '';
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    width: 8px;
    height: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    border-right: 1px solid rgba(255, 255, 255, 0.7);
    margin: auto;
    transform: rotateZ(135deg);
    transition: 0.5s ease all;

    ${props =>
      props.expanded &&
      `
      transform: rotateZ(-45deg);
      top: calc(50% - 3px);
      left: calc(50% - 3px);
    `}
  }
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.5rem;
  }

  tr.total {
    border-top: 1px solid rgba(0, 0, 0, 0.7);
    font-weight: bold;
  }
`

const SubmitDisclaimer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 0;

  span {
    margin-right: 0.25rem;
  }
`

const TermsAndConditions = styled.div`
  ul {
    font-size: 1.2rem;
    padding-left: 1rem;

    li {
      margin-bottom: 0.4rem;
    }
  }
`

const PopupModal = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: #ffffff;
  padding: 1rem;
  border-radius: 3px;
  font-family: Calibre;

  & > div {
    margin-bottom: 1rem;
  }

  button {
    border: none;
    border-radius: 2px;
    padding: 4px 18px;
    color: #ffffff;
    background-color: ${props => props.theme.cgGreen};
    align-self: flex-end;
  }
`

const Error = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: Cheltenham;
  font-size: 1.4rem;
  color: #cecece;
  text-align: center;
`

//
// ─── IMPORTED DATA ──────────────────────────────────────────────────────────────
//

const PRODUCT_OPTIONS = require('./data/productOptions')
const ADDITIONAL_INFO_OPTIONS = require('./data/additionalInfoOptions')
const TERMS_AND_CONDITIONS = require('./data/termsAndConditions.json')

//
// ─── COMPONENT DEFINITION ───────────────────────────────────────────────────────
//

class PromoClerksPage extends React.Component {
  state = {
    data: null,

    merchant: null,
    merchantToken: null, // The merchant user token generated by the mobile front end by calling: POST /merchant/agos/token
    userProfile: null,
    companies: [],

    selectedCompany: null,
    selectedProducts: [],
    selectedAddtionalInfo: [],

    agreedToTerms: false,

    step: 1,
    loading: 'init',
    popupMessage: null,
    error: null,
  }

  // This is needed to animate description height
  computedFontSize = parseInt(getComputedStyle(document.documentElement).fontSize)

  //
  // ─── LIFECYCLE ──────────────────────────────────────────────────────────────────
  //

  async componentDidMount() {
    await this._checkQueryToken(this.props.history.location.search.substr(1))

    this.unlisten = this.props.history.listen(async location => {
      this._parseQueryParams(location.search.substr(1))
    })
  }

  // Checks the URL for user & access tokens - which is used to authorize when
  // submitting the POST request to /orders endpoint

  _checkQueryToken = async query => {
    try {
      await this.setState({ loading: true })

      const { data } = querystring.parse(query)

      // If data wasn't provided, assume they are trying to access this page incorrectly
      // by displaying an error and do not proceed further
      if (!data) {
        console.log('data not found')
        await this.setState({ loading: false, error: 'Not authorized to view this page' })
        return false
      }

      const decodedBuffer = Buffer.from(data, 'base64')
      const { merchant, merchantToken, userProfile, companies } = JSON.parse(
        decodedBuffer.toString()
      )

      // If not able to extract merchat token, user profile info, and list of companies after decoding,
      // assume they are trying to access this page incorrectly by displaying an error and do not proceed further

      if (!merchant || !merchantToken || !userProfile || !companies) {
        console.log('unable to extract required info from data')
        await this.setState({ loading: false, error: 'Not authorized to view this page' })
        return false
      }

      await this.setState({
        loading: false,
        data: data,
        merchant: merchant,
        merchantToken: merchantToken,
        userProfile: userProfile,
        companies: companies.map(company => ({
          id: company.companyId,
          name: company.companyName,
        })),
      })

      // Auto select the first company so the page doesn't look so empty
      if (companies.length > 0) {
        await this.setState({
          selectedCompany: { id: companies[0].companyId, name: companies[0].companyName },
        })
      }

      // Auto generate selected value with NULL based on the number of options
      if (ADDITIONAL_INFO_OPTIONS[userProfile.country].length > 0) {
        await this.setState({
          selectedAddtionalInfo: ADDITIONAL_INFO_OPTIONS[userProfile.country].map(() => null),
        })
      }

      // Replace the history {search} to strip away any unwanted query parameters (e.g. step)
      // so that reloading the page will restart the flow from step 1
      this.props.history.replace({ search: `?data=${data}` })
    } catch (error) {
      console.log(error)
      await this.setState({ loading: false, error: 'Not authorized to view this page' })
    }
  }

  _parseQueryParams = async query => {
    const { step = 1 } = querystring.parse(query)

    await this.setState({ step: Number(step) })
  }

  //
  // ─── METHODS ────────────────────────────────────────────────────────────────────
  //

  _handleSelectProduct = async product => {
    const { userProfile, selectedProducts } = this.state

    let exists = selectedProducts.findIndex(p => _.isEqual(p.type, product.type))

    if (exists === -1) {
      selectedProducts.push({
        type: product.type,
        name: product.name,
        description: product.description,
        pricing: product.pricing[userProfile.currency],
        currency: userProfile.currency,
      })
    } else {
      selectedProducts.splice(exists, 1)
    }

    await this.setState({
      selectedProducts: selectedProducts,
      selectedAddtionalInfo: ADDITIONAL_INFO_OPTIONS[userProfile.country].map(() => null),
    })
  }

  _handleSelectCompany = async company => {
    await this.setState({ selectedCompany: company.value })
  }

  _handleSelectAddtionalInfo = async (index, info) => {
    const { selectedAddtionalInfo } = this.state
    selectedAddtionalInfo[index] = info.value
    await this.setState({ selectedAddtionalInfo: selectedAddtionalInfo })
  }

  _onClickNext = async () => {
    const { data } = this.state
    const nextStep = Number(this.state.step) + 1

    // {title} is used in the mobile app's react-navigation to update the nav bar title
    const title =
      nextStep === 2
        ? 'Company details'
        : nextStep === 3
        ? 'Summary'
        : nextStep === 4
        ? 'Terms and Conditions'
        : ''

    this.props.history.push({ search: `?data=${data}&step=${nextStep}&title=${title}` })
  }

  _onClickSubmit = async () => {
    try {
      const { userProfile } = this.state

      await this.setState({ loading: 'submit' })

      await submitClerkForm({
        ...this.state,
        additionalInfoOptions: ADDITIONAL_INFO_OPTIONS[userProfile.country].map(
          info => info.propertyName
        ),
      })

      await this._webviewPostMessage({ completed: true })

      // Fallback to a 'thank you' page in case the webview trigger didn't
      // succeed or wasn't handled correctly
      window.location.href = '/promos/clerks/thank-you'
    } catch (error) {
      await this.setState({ loading: false })
      this.setState({ popupMessage: 'Error occurred during submission. Please try again later.' })
      console.log(error)
    }
  }

  // Return action to React Native webview so it can proceed (e.g. close the webview)
  _webviewPostMessage = async data => {
    if (window) {
      const message = JSON.stringify(data)

      if (window.postMessage) {
        window.postMessage(message, '*')
      }

      // Support newer versions of react-native-webview
      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
        window.ReactNativeWebView.postMessage(message)
      }
    }
  }

  //
  // ─── RENDER ─────────────────────────────────────────────────────────────────────
  //

  render() {
    return (
      <>
        <Helmet>
          <title>Common Ground Clerks</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <style>{`
            html {
              font-size: 16px;
              background: #ffffff;
            }

            html, body {
              height: 100%;
              min-height: 100%;
            }

            * {
              -webkit-overflow-scrolling: touch;
            }
          `}</style>
        </Helmet>

        <Page>{this.state.error ? this._renderError() : this._renderForm()}</Page>
      </>
    )
  }

  _renderError = () => (
    <Container>
      <Error>{this.state.error}</Error>
    </Container>
  )

  _renderForm = () => {
    const { step, loading, popupMessage } = this.state

    if (loading === 'init') {
      return (
        <Container>
          <Error>
            <LoadingRing color="#194239" />
          </Error>
        </Container>
      )
    }

    return (
      <Container>
        <Content>
          <SwipeableViews
            slideStyle={{
              overflow: 'scroll',
              minHeight: window.innerHeight,
              maxHeight: window.innerHeight,
            }}
            disabled
            index={step - 1}
          >
            {this._renderStepOne()}
            {this._renderStepTwo()}
            {this._renderStepThree()}
            {this._renderStepFour()}
          </SwipeableViews>
        </Content>

        <Modal
          isOpen={!!popupMessage}
          onRequestClose={() => this.setState({ popupMessage: null })}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          shouldReturnFocusAfterClose
          style={modalStyles}
        >
          <PopupModal>
            <div>{popupMessage}</div>
            <button onClick={() => this.setState({ popupMessage: null })}>Ok</button>
          </PopupModal>

          <style>{`
            .ReactModal__Overlay {
              opacity: 0;
              transition: opacity 200ms ease-in-out;
            }
            
            .ReactModal__Overlay--after-open{
              opacity: 1;
            }
            
            .ReactModal__Overlay--before-close{
              opacity: 0;
            }

            .ReactModal__Content {
              margin-top: 500px;
              transition: margin-top 200ms ease-in-out;
            }
            
            .ReactModal__Content--after-open {
              margin-top: 0px;
            }
            
            .ReactModal__Content--before-close {
              margin-top: 50px;
            }
          `}</style>
        </Modal>
      </Container>
    )
  }

  //
  // ─── STEP ONE ───────────────────────────────────────────────────────────────────
  // - Select company
  // - Select products

  _renderStepOne = () => {
    const {
      userProfile,
      companies,
      selectedCompany,
      selectedProducts,
      expanded,
      loading,
    } = this.state

    let content = null

    if (loading || !selectedCompany || !PRODUCT_OPTIONS[userProfile.country]) {
      content = null
    } else {
      content = (
        <ProductOptionsList>
          <h1>Select the modules you want</h1>

          {PRODUCT_OPTIONS[userProfile.country].map(option => {
            const selected = selectedProducts.some(o => _.isEqual(o.type, option.type))

            return (
              <Option
                key={`product-option-${option.type}`}
                selected={selected}
                expanded={expanded === option.type}
                background={option.background}
              >
                <div className="heading">
                  <h3 style={{ margin: '0 0 0.25rem 0' }}>{option.name}</h3>

                  <Checkbox onClick={() => this._handleSelectProduct(option)}>
                    {selected ? (
                      <IoIosCheckmarkCircle color="#ffffff" size="2rem" />
                    ) : (
                      <IoIosRadioButtonOff color="#ffffff" size="2rem" />
                    )}
                  </Checkbox>
                </div>

                <AnimateHeight
                  duration={250}
                  height={
                    expanded === option.type
                      ? 'auto'
                      : this.computedFontSize * NUM_DESCRIPTION_LINES
                  }
                >
                  <Markdown source={option.description} className="description" />
                </AnimateHeight>

                <div
                  className="expand-toggle"
                  onClick={event => {
                    event.stopPropagation()

                    this.setState({
                      expanded: this.state.expanded === option.type ? null : option.type,
                    })
                  }}
                >
                  <ExpandToggleArrow expanded={expanded === option.type} />
                  {expanded === option.type ? 'Show less' : 'Show more'}
                </div>
              </Option>
            )
          })}
        </ProductOptionsList>
      )
    }

    return (
      <SwipeableView id="step-1">
        <BetaWarning>
          <IoMdAlert size={28} />

          <div>
            This feature is in early beta and available to members in Malaysia and Thailand only.
          </div>
        </BetaWarning>

        <h1>Company</h1>

        <Select
          options={optionify(companies, 'name')}
          value={selectedCompany ? optionify([selectedCompany], 'name') : null}
          onChange={this._handleSelectCompany}
          styles={selectStyles}
          theme={selectTheme}
          isSearchable={false}
        />

        <Fade in={!loading}>{content}</Fade>

        {selectedCompany && !selectedCompany.isRegistered && (
          <FloatingButton
            label="Next"
            onClick={this._onClickNext}
            disabled={
              !selectedCompany || selectedCompany.isRegistered || selectedProducts.length === 0
            }
          />
        )}
      </SwipeableView>
    )
  }

  //
  // ─── STEP TWO ───────────────────────────────────────────────────────────────────
  // - Select addtional info

  _renderStepTwo = () => {
    const { userProfile, selectedProducts, selectedAddtionalInfo } = this.state

    const { country = 'MY' } = userProfile || {}

    // Check on which products the user has selected,
    // If it is NOT necessary - we should provide them a 'Not sure' option.
    const addtionalInfoOptions = ADDITIONAL_INFO_OPTIONS[country].map(addtionalInfo => ({
      ...addtionalInfo,
      options: !selectedProducts.some(product =>
        addtionalInfo.requiredInProduct.includes(product.type)
      )
        ? [{ title: 'Not sure', value: 'Not sure' }, ...addtionalInfo.options]
        : addtionalInfo.options,
    }))

    return (
      <SwipeableView id="step-2">
        {addtionalInfoOptions.map((additionalInfo, index) => (
          <div key={`additional-info-${index}`} style={{ marginBottom: '1rem' }}>
            <h1>{additionalInfo.title}</h1>

            {!!additionalInfo.description && (
              <p style={{ marginTop: '-0.5rem' }}>{additionalInfo.description}</p>
            )}

            <Select
              options={optionify(additionalInfo.options, 'title')}
              value={
                selectedAddtionalInfo[index]
                  ? optionify([selectedAddtionalInfo[index]], 'value')
                  : null
              }
              onChange={option => this._handleSelectAddtionalInfo(index, option)}
              styles={selectStyles}
              theme={selectTheme}
              isSearchable={false}
            />
          </div>
        ))}
        <FloatingButton
          label="Next"
          onClick={this._onClickNext}
          disabled={
            selectedAddtionalInfo.length === 0 ||
            selectedAddtionalInfo.filter(info => info === null).length > 0
          }
        />
      </SwipeableView>
    )
  }

  //
  // ─── STEP THREE ─────────────────────────────────────────────────────────────────
  // - Summary
  // - Pricing
  // - Terms & conditions button
  // - Submit button

  _renderStepThree = () => {
    const {
      userProfile,
      selectedCompany,
      selectedProducts,
      selectedAddtionalInfo,
      agreedToTerms,
      loading,
    } = this.state

    const { country = 'MY' } = userProfile || {}
    const addtionalInfoOptions = ADDITIONAL_INFO_OPTIONS[country]
    const rate = selectedProducts.length > 1 ? 'discounted' : 'full'

    if (
      !selectedCompany ||
      !selectedProducts ||
      selectedAddtionalInfo.length === 0 ||
      selectedAddtionalInfo.filter(info => info === null).length > 0
    ) {
      return <></>
    }

    const totalPrice = selectedProducts.reduce((sum, product) => {
      const found = selectedAddtionalInfo.find(info => product.pricing[rate][info.value])
      const price = found ? product.pricing[rate][found.value] : 0

      return sum + price
    }, 0)

    return (
      <SwipeableView id="step-3">
        <div style={{ marginBottom: '1rem' }}>
          <h1>Company details</h1>

          <Table>
            <tbody>
              <tr>
                <td align="left">Name</td>
                <td align="right">
                  <b>{selectedCompany.name}</b>
                </td>
              </tr>

              {addtionalInfoOptions.map((info, index) => (
                <tr key={`selected-info-${index}`}>
                  <td align="left">{info.title}</td>
                  <td align="right">
                    <b>{selectedAddtionalInfo[index].value}</b>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div style={{ marginBottom: '1rem' }}>
          <h1>Pricing</h1>

          <Table>
            <thead>
              <tr>
                <th align="left">Service</th>
                <th align="right">Amount</th>
              </tr>
            </thead>

            <tbody>
              {selectedProducts.map(product => {
                const found = selectedAddtionalInfo.find(info => product.pricing[rate][info.value])
                const price = found ? product.pricing[rate][found.value] : 0

                return (
                  <tr key={`summary-product-row-${product.type}`}>
                    <td align="left">{product.name}</td>
                    <td align="right">
                      {price ? toCurrency(price, product.currency) : <i>Custom Quote</i>}
                    </td>
                  </tr>
                )
              })}

              <tr className="total">
                <td align="left">Total</td>
                <td align="right">
                  ~{!!totalPrice ? toCurrency(totalPrice, userProfile.currency) : 'Custom Quote'}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div style={{ flex: 1 }} />

        <SubmitDisclaimer>
          <span onClick={() => this.setState({ agreedToTerms: !agreedToTerms })}>
            I agree to the
          </span>
          <span onClick={this._onClickNext} style={{ textDecoration: 'underline' }}>
            Terms & Conditions
          </span>

          <Checkbox
            onClick={() => this.setState({ agreedToTerms: !agreedToTerms })}
            style={{ margin: 0, marginLeft: 'auto' }}
          >
            {agreedToTerms ? (
              <IoIosCheckmarkCircle size="2rem" />
            ) : (
              <IoIosRadioButtonOff size="2rem" />
            )}
          </Checkbox>
        </SubmitDisclaimer>

        <FloatingButton
          label={loading === 'submit' ? 'Submitting...' : 'Submit Request'}
          onClick={this._onClickSubmit}
          disabled={
            loading === 'submit' ||
            selectedProducts.length === 0 ||
            selectedAddtionalInfo.length === 0 ||
            selectedAddtionalInfo.filter(info => info === null).length > 0 ||
            !agreedToTerms
          }
        />
      </SwipeableView>
    )
  }

  //
  // ─── STEP FOUR ──────────────────────────────────────────────────────────────────
  // - Terms & conditions

  _renderStepFour = () => {
    const { userProfile } = this.state
    const { country = 'MY' } = userProfile || {}
    const terms = TERMS_AND_CONDITIONS[country]

    return (
      <SwipeableView id="step-4">
        <TermsAndConditions>
          <ul>
            {terms.map((term, index) => (
              <li key={`term-${index}`}>{term}</li>
            ))}
          </ul>
        </TermsAndConditions>
      </SwipeableView>
    )
  }
}

export default PromoClerksPage
