import React from 'react'

const Locations = () => (
  <>
    <optgroup label="Malaysia - Kuala Lumpur">
      <option value="Menara BT, Bangsar South">Menara BT, Bangsar South</option>
      <option value="Menara Worldwide, Bukit Bintang">Menara Worldwide, Bukit Bintang</option>
      <option value="Wisma Mont Kiara, Mont Kiara">Wisma Mont Kiara, Mont Kiara</option>
      <option value="KL Eco City, Mid Valley">KL Eco City, Mid Valley</option>
      <option value="Wisma UOA Damansara II, Damansara Heights">
        Wisma UOA Damansara II, Damansara Heights
      </option>
      <option value="Q Sentral, KL Sentral">Q Sentral, KL Sentral</option>
      <option value="Jalan Kolam Air Lama, Ampang">Jalan Kolam Air Lama, Ampang</option>
      <option disabled value="Menara Ken TTDI, TTDI">
        Menara Ken TTDI, TTDI
      </option>
    </optgroup>

    <optgroup label="Malaysia - Petaling Jaya">
      <option value="Citta Mall, Ara Damansara">Citta Mall, Ara Damansara</option>
      <option value="Nucleus Tower, Mutiara Damansara">Nucleus Tower, Mutiara Damansara</option>
      <option value="Jaya One, Petaling Jaya">Jaya One, Petaling Jaya</option>
    </optgroup>

    <optgroup label="Malaysia - Penang">
      <option value="Moulmein Rise Shoppes, Penang">Moulmein Rise Shoppes, Penang</option>
    </optgroup>
  </>
)

export default Locations
