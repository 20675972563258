import React from 'react'

import {
  Form,
  InputField,
  Input,
  Textarea,
  Select,
  DatePicker,
  CountrycodePicker,
  OfficePicker,
  Label,
} from 'components/Common/Input'
import Button from 'components/Common/Button'

import { sleep } from 'utils'

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class LeadForm extends React.Component {
  state = {
    loading: null,
    error: null,
  }

  ///////////////
  // Functions //
  ///////////////

  async handleSubmit(e) {
    try {
      e.preventDefault && e.preventDefault()

      this.setState({ loading: 'SUBMITTING...', error: null })

      const data = {
        // "Book a Visit" form parameters (/booking endpoint)
        type: 1,

        name: e.target.first_name.value + ' ' + e.target.last_name.value,
        email: e.target.email.value,
        contact_country_code: e.target.contact_country_code.value,
        contact_number: e.target.contact_number.value,
        company: e.target.company_name.value,
        pax: e.target.pax.value,
        office: e.target.package_type.value,
        property_id: e.target.office.value,
        schedule: e.target.selected_date.value,
        request: e.target.additional_notes.value,
      }

      // todo: POST form data to API endpoint
      console.log(data, null, 2)

      // todo: Remove this once POST API is implemented
      await sleep(1000)

      // Callback
      this.props.onSubmitSuccess && this.props.onSubmitSuccess(data)
    } catch (err) {
      console.error(err)
      this.setState({ loading: null, error: err.message })
      this.props.onSubmitFailed && this.props.onSubmitFailed(err)
    }
  }

  ////////////
  // Render //
  ////////////

  render() {
    return (
      <Form onSubmit={e => this.handleSubmit(e)}>
        <InputField>
          {/* FIRST NAME */}
          <Label>FIRST NAME</Label>
          <Input required name="first_name" type="text" />
        </InputField>

        {/* LAST NAME */}
        <InputField>
          <Label>LAST NAME</Label>
          <Input required name="last_name" type="text" />
        </InputField>

        {/* EMAIL */}
        <InputField width="100%">
          <Label>EMAIL</Label>
          <Input required name="email" type="email" />
        </InputField>

        {/* COUNTRY CODE */}
        <InputField width="calc(30% - 1rem)">
          <Label>COUNTRY CODE</Label>
          <CountrycodePicker required name="contact_country_code" />
        </InputField>

        {/* PHONE NUMBER */}
        <InputField width="calc(70% - 1rem)">
          <Label>PHONE NUMBER</Label>
          <Input required name="contact_number" type="number" />
        </InputField>

        {/* COMPANY NAME */}
        <InputField width="100%">
          <Label>COMPANY NAME</Label>
          <Input required name="company_name" type="text" />
        </InputField>

        {/* PAX */}
        <InputField width="calc(50% - 1rem)">
          <Label>NEED A SPACE FOR</Label>
          <Select required name="pax" lineOnly>
            <option>1 - 5 pax</option>
            <option>5 - 10 pax</option>
            <option>10 - 25 pax</option>
            <option>25 - 50 pax</option>
            <option>50+ pax</option>
          </Select>
        </InputField>

        {/* PACKAGE (SEAT) TYPE */}
        <InputField width="calc(50% - 1rem)">
          <Label>INTERESTED IN</Label>
          <Select required name="package_type" lineOnly>
            <option>Hot Desk</option>
            <option>Fixed Desk</option>
            <option>Private Office</option>
          </Select>
        </InputField>

        {/* SCHEDULE DATE */}
        <InputField width="calc(50% - 1rem)">
          <Label>PREFERRED DATE & TIME</Label>
          <DatePicker required name="selected_date" />
        </InputField>

        {/* LOCATION */}
        <InputField width="calc(50% - 1rem)">
          <Label>LOCATION</Label>
          <OfficePicker required name="office" anchor="right" />
        </InputField>

        {/* ADDITIONAL NOTES */}
        <Label>ADDITIONAL NOTES</Label>
        <Textarea name="additional_notes" placeholder="Optional" />

        <Button disabled={this.state.loading} style={{ width: '75%', margin: '1rem auto' }}>
          {this.state.loading || 'SUBMIT'}
        </Button>
      </Form>
    )
  }
}

export default LeadForm
