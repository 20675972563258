import React from 'react'
import styled from 'styled-components'

import Breadcrumbs from 'components/Common/Breadcrumbs'
import { H3, P } from 'components/Common/Typography'
import WrappedContainer from 'components/Common/WrappedContainer'
import BorderOffsetBox from 'components/Common/BorderOffsetBox'

import { slugify } from 'utils'

///////////////////////////////////////////////////////////////////////////////
// Styling

const DescriptionContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  align-items: center;
  padding: 2rem 0;

  @media (max-width: 800px) {
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-bottom: 0;
    padding: 0;
  }
`

const Description = styled.div`
  flex: 2;
  margin: 1rem 0;

  @media (max-width: 800px) {
    margin-bottom: 3rem;
  }
`

const DescriptionDetails = styled.div`
  padding-right: 4rem;

  @media (max-width: 800px) {
    padding-right: 0;
  }
`

const Item = styled.div`
  margin: 1rem;

  @media (max-width: 800px) {
    margin: 0 1rem 1rem 0;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const OfficeDescription = props => {
  const { office } = props

  if (!office) return null

  return (
    <WrappedContainer background="#ffffff" style={{ container: { flexFlow: 'row wrap' } }}>
      <DescriptionContainer>
        <Description>
          <Breadcrumbs
            from="location-description"
            links={[
              {
                text: 'All Locations',
                href: '/locations',
              },
              {
                text: office.city.country.name,
                href: slugify(`/locations/${office.city.country.name}`),
              },
              {
                text: office.city.name,
                href: slugify(`/locations/${office.city.country.name}/${office.city.name}`),
              },
              {
                text: office.building,
              },
            ]}
          />

          <DescriptionDetails>
            <P>{office.description}</P>
          </DescriptionDetails>
        </Description>

        <BorderOffsetBox>
          <Item>
            <H3>ADDRESS</H3>
            <P>{office.address}</P>
          </Item>

          {office.contact && (
            <Item>
              <H3>CONTACT</H3>
              <P>+60 12 312 5922</P>
            </Item>
          )}
        </BorderOffsetBox>
      </DescriptionContainer>
    </WrappedContainer>
  )
}

export default OfficeDescription
