import axios from 'axios'
import { upperCase } from 'lodash'

const ENVIRONMENTS = {
  PRODUCTION: {
    AMBITION_API_URL: 'https://ambition.commonground.work/v1',
    AMBITION_X_API_KEY: 'OEnRt57ttZ3sUuYZeB5ch13g2nKO28wy3hBRjyE5',
  },

  STAGING: {
    AMBITION_API_URL: 'https://ambition.commonground.work/staging',
    AMBITION_X_API_KEY: 'T8ECdsIW7s4krybzc8Xgh55FgpqHLtzC3hoVLQaA',
  },
}

export const submitCovid19DeclarationCheckIn = async (data, options = {}) => {
  try {
    const { env = 'production' } = options
    const { AMBITION_API_URL, AMBITION_X_API_KEY } = ENVIRONMENTS[upperCase(env)]

    const result = await axios.post(`${AMBITION_API_URL}/forms/covid19declaration`, data, {
      headers: { 'X-Api-Key': AMBITION_X_API_KEY, 'Content-Type': 'application/json' },
    })
    return result
  } catch (err) {
    if (err.response.data) {
      throw err.response.data
    }
    throw err
  }
}
