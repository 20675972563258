import React from 'react'
import styled from 'styled-components'

import WrappedContainer from 'components/Common/WrappedContainer'
import { H3 } from 'components/Common/Typography'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Title = styled(H3)`
  @media (max-width: 800px) {
    text-align: center;
    margin: 0 auto 1rem auto;
  }
`

const AmenitiesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 1rem 0;

  @media (max-width: 800px) {
    flex-flow: column nowrap;
    margin: 1rem auto;
  }
`

const Amenity = styled.div`
  width: 100%;
  max-width: 33%;
  min-width: 180px;
  padding: 1rem;
  font-size: 1.2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 800px) {
    max-width: 600px;
    padding: 0.2rem;
    margin-bottom: 1rem;
  }
`

const Icon = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border: 2px solid transparent; //${props => props.theme.cgYellow};
  border-radius: 50%;
  padding: 4px;
  margin-right: 1rem;
  background: ${props => props.theme.cgGreen};
  flex-shrink: 0;

  :before,
  :after {
    content: '';
    position: absolute;
    background-color: white; //${props => props.theme.cgYellow};
    transition: transform 0.25s ease-out;
  }

  // Vertical line
  :before {
    top: 25%;
    left: 50%;
    width: 3px;
    height: 50%;
    margin-left: -1.5px;
  }

  // Horizontal line
  :after {
    top: 50%;
    left: 25%;
    width: 50%;
    height: 3px;
    margin-top: -1.5px;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const OfficeAmenities = props => {
  const { office } = props

  if (!office || !office.amenities) return null

  const amenities = office.amenities.split('\n').filter(value => value !== '')

  return (
    <WrappedContainer maxWidth="1200px" background="#fff0f0">
      <Title>BUILDING AMENITIES</Title>

      <AmenitiesContainer>
        {amenities.map(amenity => (
          <Amenity key={`amenity-${amenity}`}>
            <Icon />
            {amenity}
          </Amenity>
        ))}
      </AmenitiesContainer>
    </WrappedContainer>
  )
}

export default OfficeAmenities
