import React from 'react'
import styled from 'styled-components'
import emailjs from 'emailjs-com'
import { MdThumbUp } from 'react-icons/md'
import { get } from 'lodash'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import CountryCodes from './CountryCodes'
import Locations from './Locations'
import { checkFlexPromoCodeIsValid } from 'services/api'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Survey = styled.div`
  padding: 2rem;
  margin-top: 1rem;
  background: rgba(255, 248, 234, 0.8);
  border-radius: 16px;

  @media (max-width: 768px) {
    max-width: 500px;
    padding: 1rem;
    margin: auto;
  }
`

const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  h5 {
    font-weight: normal;
    color: #5f5f5f;

    a {
      color: #5f5f5f;
    }
  }
`

const Input = styled.input`
  width: 100%;
  margin-bottom: 1rem;
  padding: 6px 12px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  ${props =>
    props.width &&
    `
    width: ${props.width}
  `}
`

const Textarea = styled.textarea`
  width: 100%;
  margin-bottom: 1rem;
  padding: 6px 12px;
  font-family: inherit;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  ${props =>
    props.width &&
    `
    width: ${props.width}
  `}
`

const Select = styled.select`
  width: 100%;
  margin-bottom: 1rem;
  padding: 6px 12px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  ${props =>
    props.width &&
    `
    width: ${props.width}
  `}
`

const SubmitButton = styled.button`
  background: #fecd6f;
  padding: 15px 40px;
  width: 100%;
  border: none;
  cursor: pointer;
  transition: 0.2s ease all;
  font-size: 0.9rem;

  :hover {
    background: #fec458;
  }

  :disabled {
    background: #e5c993;
    cursor: default;
  }
`

const Submitted = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000000;
`

export const LandingForm = () => {
  const [loading, setLoading] = React.useState(false)
  const [submitted, setSubmitted] = React.useState(false)

  const [productType, setProductType] = React.useState()
  const [other, setOther] = React.useState('')

  //
  // ─── METHODS ────────────────────────────────────────────────────────────────────
  //

  const useFocus = () => {
    const htmlElRef = React.useRef(null)
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus()
    }
    return [htmlElRef, setFocus]
  }

  const submitForm = async e => {
    e.preventDefault && e.preventDefault()
    setLoading('Submitting...')

    // Send an email using Emailjs //
    /////////////////////////////////
    // Change these values if this page is duplicated
    const PROMO_NAME = 'Corporate Flex Solution'
    const PROMO_ENDPOINT = 'flex'
    // Don't touch these values
    const userID = 'user_j0kmOkHQpkxYeRD9S0OZH'
    const serviceID = 'service_mailgun'
    const templateID = 'template_basic'
    // Probably will need to change some of these fields dependending on the promo requirements
    const templateParams = {
      emailjs_title: PROMO_NAME,
      emailjs_url: `https://promos.commonground.work/${PROMO_ENDPOINT}`,
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      email: e.target.email.value,
      phone_number: `+${e.target.contact_country_code.value}${e.target.contact_number.value}`,
      company_name: e.target.company_name.value,
      promo_code: e.target.promo_code.value,
      product_type:
        e.target.product_type.value + (productType === 'Something else' ? `: ${other}` : ''),
      location: get(e, 'target.location.value', 'N/A'),
      pax: get(e, 'target.pax.value', 'N/A'),
    }

    try {
      if (!!e.target.promo_code.value) {
        let flexPromoCodeParams = {
          promo_code: e.target.promo_code.value,
          email: e.target.email.value,
          first_name: e.target.first_name.value,
          last_name: e.target.last_name.value,
        }

        let isPromoCodeValid = await checkFlexPromoCodeIsValid(flexPromoCodeParams)
        if (isPromoCodeValid.code !== 200) {
          toast.error(
            isPromoCodeValid.errors ? isPromoCodeValid.errors[0] : 'This promo code is invalid!',
            {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
          setLoading(false)
          setPromoCodeInput()
          return
        }
      }

      ////////////////////////////////
      // Send the form
      await emailjs.send(serviceID, templateID, templateParams, userID)
      // Redirect to 'thank you' page (for analytic usage)
      if (window.location.hostname.includes('promos.commonground.work')) {
        window.location.href = `/${PROMO_ENDPOINT}/thank-you`
      } else {
        window.location.href = `/promos/${PROMO_ENDPOINT}/thank-you`
      }
      // In case the redirect doesn't work
      setTimeout(() => {
        setLoading(false)
        setSubmitted(true)
      }, 500)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  //
  // ─── RENDER ─────────────────────────────────────────────────────────────────────
  //

  // for reference input to focus field
  const [promoCodeInputRef, setPromoCodeInput] = useFocus()

  let submittable = true

  if (!!loading) submittable = false
  if (productType === 'Something else' && other === '') submittable = false

  return (
    <Survey className="order-2 order-md-1">
      {!submitted ? (
        <Form onSubmit={submitForm}>
          <Input
            oninput="setCustomValidity('')"
            oninvalid="this.setCustomValidity('Please Enter valid email')"
            required
            name="first_name"
            type="text"
            width="48%"
            placeholder="First Name"
          />
          <ToastContainer
            style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <Input required name="last_name" type="text" width="48%" placeholder="Last Name" />

          <Input required name="email" type="email" placeholder="Email Address" />

          <Select required name="contact_country_code" defaultValue="" width="38%">
            <option disabled value="">
              Country Code
            </option>
            <CountryCodes />
          </Select>

          <Input
            required
            name="contact_number"
            type="number"
            placeholder="Phone Number"
            width="58%"
          />

          <Input
            name="company_name"
            type="text"
            placeholder="Company Name (optional)"
            autoComplete="off"
          />

          <Select required name="location" defaultValue="" width="100%">
            <option disabled value="">
              Location
            </option>
            <Locations />
          </Select>

          <Select
            required
            name="product_type"
            defaultValue=""
            width="100%"
            onChange={({ target }) => setProductType(target.value)}
          >
            <option disabled value="">
              I'm enquiring about
            </option>

            {[
              'Book a Tour',
              'Get in touch with a Sales executive / Speak to us',
              'Something else',
            ].map(value => (
              <option key={`product-type-option-${value}`} value={value}>
                {value}
              </option>
            ))}
          </Select>

          <Input
            ref={promoCodeInputRef}
            name="promo_code"
            autoComplete="off"
            type="text"
            placeholder="Promo Code"
          />

          {productType === 'Something else' && (
            <Textarea name="other" onChange={({ target }) => setOther(target.value)} />
          )}

          <SubmitButton disabled={!submittable}>
            {!!loading ? 'Submitting...' : 'Submit'}
          </SubmitButton>

          <h5>
            <i>
              {`When you click on the above button, you acknowledge that you have read and accept our `}
              <a href="https://www.commonground.work/privacy">Privacy Policy</a>
              {`, and agree to our `}
              <a href="https://www.commonground.work/terms-and-conditions">Terms and Conditions</a>
              {`.`}
            </i>
          </h5>
        </Form>
      ) : (
        <Submitted id="thank-you">
          <MdThumbUp size="4rem" />

          <h1 style={{ letterSpacing: '-3px' }}>Thank You</h1>

          <p>Your submission has been successfully sent. We look forward to meeting you!</p>
          <p>
            You can close this page or return{' '}
            <a
              href="https://www.commonground.work"
              style={{
                color: '#111111',
              }}
            >
              home
            </a>
            .
          </p>
        </Submitted>
      )}
    </Survey>
  )
}

export default LandingForm
