import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import { push as Menu } from 'react-burger-menu'

import BookingSidebar from 'components/Common/Layout/BookingSidebar'

///////////////////////////////////////////////////////////////////////////////
// Constants

const mobileCollapseWidth = '1150px'
const navbarColor = '#fffbf5'

const links = [
  {
    url: '/locations',
    text: 'LOCATIONS',
  },
  {
    url: '/packages',
    text: 'PACKAGES',
  },
  {
    url: '/enterprise',
    text: 'ENTERPRISE',
  },
]

///////////////////////////////////////////////////////////////////////////////
// Styling

const Wrapper = styled.div`
  font-family: 'Acrom';
  font-weight: bold;
  letter-spacing: 2px;
  position: fixed;
  box-sizing: content-box;
  top: 0;
  transition: 0.4s ease all;
  width: 100%;
  z-index: 999;
  will-change: transform;
  height: 80px;
  background: ${props => (props.filledNav ? navbarColor : 'transparent')};
  color: #ffffff;
  padding: 2rem 0;

  ${props =>
    props.scrolled &&
    `
    height: 65px;
    background: ${navbarColor};
    color: #000000;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 0;
  `}

  @media (max-width: ${mobileCollapseWidth}) {
    height: 50px;
    max-height: 50px;
    padding: 0;
    font-size: 1rem;
    letter-spacing: 2px;
    will-change: unset;
  }
`

const DesktopMenu = styled.div`
  width: 100%;
  max-width: 1200px;
  height: inherit;
  margin: auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${mobileCollapseWidth}) {
    display: none;
  }
`

const Logo = styled.div`
  width: auto;
  height: 50%;
  margin: 0 3rem 0 2rem;

  img {
    width: auto;
    height: 100%;
    transition: 0.4s ease all;
  }
`

const Nav = styled.nav`
  color: inherit;
  font-family: Acrom;
  font-weight: normal;
  font-size: inherit;
  letter-spacing: inherit;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const Link = styled.a`
  position: relative;
  padding: 1rem;
  margin-right: 0.75rem;
  text-decoration: none;
  color: inherit;

  :after {
    position: absolute;
    content: '';
    height: 4px;
    left: 1.5rem;
    right: 1.5rem;
    bottom: 0.5rem;
    background: transparent;
    transition: 0.2s ease all;
  }

  :hover {
    :after {
      background: ${props => !props.scrolled && props.theme && props.theme.cgYellow};
    }
  }

  ${props =>
    props.active &&
    `
    font-weight: bold;

    :after {
      height: ${props.scrolled && '6px'};
      width: ${props.scrolled && '6px'};
      left: ${props.scrolled && '0'};
      right: ${props.scrolled && 'unset'};
      top: ${props.scrolled && 'calc(50% - 3px)'};
      border-radius: ${props.scrolled && '50%'};
      
      background: ${props.theme && props.theme.cgYellow};
    }
    
  `}

  @media(max-width: ${mobileCollapseWidth}) {
    :after {
      display: none;
    }
  }
`

const Spacer = styled.div`
  flex: 1;
`

const MobileMenu = styled.div`
  display: none;
  max-height: inherit;
  text-align: center;

  @media (max-width: ${mobileCollapseWidth}) {
    display: inherit;
  }

  & > a,
  img {
    max-height: inherit;
    height: 50px;
  }

  img {
    box-sizing: border-box;
    padding: 0.5rem;
  }
`

const BookingButton = styled.button`
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  letter-spacing: inherit;
  padding: 1rem;
  margin: 0 3rem;
  text-decoration: none;
  color: ${props => props.theme.cgYellow};
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
  }

  @media (max-width: ${mobileCollapseWidth}) {
    margin: 0;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class Navbar extends React.Component {
  state = {
    scrolled: 0,
    mobileMenuOpen: false,
  }

  ///////////////////////
  // Lifecycle methods //
  ///////////////////////

  componentDidMount() {
    this.checkScroll()
    window.addEventListener('scroll', () => this.checkScroll())
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.checkScroll())
  }

  ///////////////
  // Functions //
  ///////////////

  // Check the user's scroll position to determine whether to collapse nav bar
  checkScroll() {
    if (typeof document === undefined) return

    const { scrollTop } = document.documentElement

    this.setState({ scrolled: scrollTop > 20 })
  }

  // The burger menu will automatically focus on the first item when it's opened
  // which looks ugly and is pointless for mobile experience anyway, so we disable it

  // https://github.com/negomi/react-burger-menu/issues/290
  handleMenuInteraction(newState) {
    if (!window || !document) return

    window.setTimeout(() => {
      if (newState.isOpen) {
        document.activeElement.blur()
      }
    })
  }

  openBookingSidebar() {
    this.setState({ mobileMenuOpen: false })

    if (this.bookingSidebar && this.bookingSidebar.toggle) {
      this.bookingSidebar.toggle()
    }
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { scrolled, mobileMenuOpen } = this.state
    const { forceCollapse, filledNav, match } = this.props

    return (
      <Wrapper
        id="navbar"
        className={scrolled ? 'collapsed' : 'expanded'}
        filledNav={filledNav}
        scrolled={scrolled || forceCollapse}
      >
        <DesktopMenu>
          <Logo>
            <a href="/">
              <img src="/images/cg-logo.png" alt="Common Ground Logo" />
            </a>
          </Logo>

          <Nav>
            {links.map(link => (
              <Link
                href={link.url}
                active={match.url.includes(link.url)}
                scrolled={scrolled || forceCollapse}
                key={`mobile-nav-url-${link.text}`}
              >
                {link.text}
              </Link>
            ))}

            <Spacer />

            <BookingButton onClick={() => this.openBookingSidebar()}>BOOK A TOUR</BookingButton>
          </Nav>

          <Spacer />
        </DesktopMenu>

        <MobileMenu>
          <Menu
            styles={styles}
            pageWrapId="page-wrap"
            outerContainerId="root"
            isOpen={mobileMenuOpen}
            onStateChange={newState => this.handleMenuInteraction(newState)}
          >
            <Link href="/">HOME</Link>

            {links.map(link => (
              <Link
                href={link.url}
                active={match.url.includes(link.url)}
                scrolled={scrolled || forceCollapse}
                key={`mobile-nav-url-${link.text}`}
              >
                {link.text}
              </Link>
            ))}

            <Spacer />

            <BookingButton onClick={() => this.openBookingSidebar()}>BOOK A TOUR</BookingButton>
          </Menu>
          <a href="/">
            <img src="/images/cg-logo.png" alt="Common Ground Logo" />
          </a>
        </MobileMenu>

        <BookingSidebar ref={el => (this.bookingSidebar = el)} />
      </Wrapper>
    )
  }
}

export default withRouter(Navbar)

// https://github.com/negomi/react-burger-menu
const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '26px',
    height: '22px',
    left: '14px',
    top: '14px',
  },
  bmBurgerBars: {
    background: '#ffcd66',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
  },
  bmMenu: {
    background: '#194239',
    padding: '2.5em 1.5em',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
    display: 'flex',
    WebkitFlexFlow: 'column nowrap',
    MsFlexFlow: 'column nowrap',
    flexFlow: 'column nowrap',
  },
  bmItem: {
    display: 'inline-block',
    width: '100%',
    textAlign: 'left',
    padding: '1rem',
    cursor: 'pointer',
  },
  bmOverlay: {
    top: 0,
    background: 'rgba(0, 0, 0, 0.3)',
  },
}
