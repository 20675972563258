import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

const backgroundImg = require('./images/background.jpg')
const logoImg = require('./images/logo.png')

///////////////////////////////////////////////////////////////////////////////
// Styling

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #ffcc65;
  background: url(${backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-family: Calibre;
  color: ${props => props.theme.cgGreen};
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  max-width: 900px;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  z-index: 10;
  flex-flow: column nowrap;
  padding: 0rem;
`

const Content = styled.div`
  flex: 1;
  width: 100%;
  min-height: 75vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const Logo = styled.img`
  width: 140px;
  max-width: 100%;
`

const Title = styled.div`
  font-size: 1.8rem;
  font-family: Acrom;
  font-weight: bold;
  letter-spacing: -2px;
`

const Text = styled.div`
  font-family: Cheltenham;
  font-size: 1.1rem;
  line-height: 1.6rem;
  letter-spacing: -0.33px;
  padding: 0 1.5rem;
`

const Email = styled.a`
  font-size: 1.3rem;
  font-weight: bold;
  font-family: Cheltenham;
  color: ${props => props.theme.cgGreen};
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class EngagementRankPage extends React.Component {
  state = {
    error: null,
  }

  ////////////
  // Render //
  ////////////

  render() {
    return (
      <>
        <Helmet>
          <title>COVID-19 | Common Ground</title>
          <style>{`
            html {
              font-size: 16px;
            }
          `}</style>
        </Helmet>

        <Page>
          <Container>
            <Content>
              <div style={{ marginTop: '2rem' }} />

              <Logo src={logoImg} alt="Common Ground" />

              <div style={{ marginBottom: '1rem' }} />

              <Title>COVID-19 HOTLINE</Title>

              <div style={{ marginBottom: '2rem' }} />

              <Text>
                In light of the COVID-19 pandemic, Common Ground opens its line of communication for
                members and staff to address any concerns regarding the situation.
              </Text>

              <div style={{ marginBottom: '2rem' }} />

              <Text>Email us</Text>

              <Email href="mailto:covid19@commonground.work">covid19@commonground.work</Email>

              <div style={{ marginBottom: '2rem' }} />

              <Text>
                We will be working hand in hand with our team and management to answer your
                inquiries. Please stay safe and healthy. #FlattenTheCurve
              </Text>
            </Content>
          </Container>
        </Page>
      </>
    )
  }
}

export default EngagementRankPage
