import React from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router'

import Error404 from 'components/Error404'
import Layout from 'components/Common/Layout'

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class MissingPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Page Not Found | Common Ground</title>
          <style>{`
            #navbar.expanded {
              color: #000000;
            }
          `}</style>
        </Helmet>

        <Error404 {...this.props} />
      </Layout>
    )
  }
}

export default withRouter(MissingPage)
