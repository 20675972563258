import axios from 'axios'
import { upperCase, replace } from 'lodash'

import { toCamelCase } from 'utils'

const ENVIRONMENTS = {
  PRODUCTION: {
    AMBITION_API_URL: 'https://ambition.commonground.work/v1',
    AMBITION_X_API_KEY: 'OEnRt57ttZ3sUuYZeB5ch13g2nKO28wy3hBRjyE5',
  },

  STAGING: {
    AMBITION_API_URL: 'https://ambition.commonground.work/staging',
    AMBITION_X_API_KEY: 'T8ECdsIW7s4krybzc8Xgh55FgpqHLtzC3hoVLQaA',
  },
}

export const getLeaderboardUsers = async (options = {}) => {
  try {
    const { env = 'production' } = options
    const { AMBITION_API_URL, AMBITION_X_API_KEY } = ENVIRONMENTS[upperCase(env)]

    await new Promise(resolve => setTimeout(resolve, 200))

    const response = await axios
      .get(`${AMBITION_API_URL}/contest/1/leaderboard`, {
        headers: { 'X-Api-Key': AMBITION_X_API_KEY, 'Content-Type': 'application/json' },
      })
      .then(res => toCamelCase(res.data))

    if (response.status !== 200) {
    }

    const result = {
      users: response.data.participants.map((participant, index) => ({
        ...participant,
        imageUrl: replace(participant.imageUrl, '/sm/', '/md/'),
        rank: index + 1,
      })),
    }

    return result
  } catch (err) {
    if (err.response.data) {
      throw err.response.data
    }
    throw err
  }
}
