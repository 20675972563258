import React from 'react'
import styled from 'styled-components'

///////////////////////////////////////////////////////////////////////////////
// Constants

const FLOATING_ASSISTANT_HEIGHT = '3.5rem'

///////////////////////////////////////////////////////////////////////////////
// Styling

const FloatingButtonWrapper = styled.div`
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  width: 100%;
  height: ${FLOATING_ASSISTANT_HEIGHT};
  background: ${props => props.theme.cgYellow};
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  box-shadow: 0 -1px 7px 0 rgba(0, 0, 0, 0.2);
  border-radius: ${props => props.theme.MOBILE.BORDER_RADIUS};
`

const Button = styled.div`
  flex: ${props => props.flex || 'unset'};
  width: ${FLOATING_ASSISTANT_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;

  ${props =>
    props.disabled &&
    `
    color: #808080;
    background: #e5c993;
    cursor: default;
  `}
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const FloatingButton = props => (
  <FloatingButtonWrapper>
    <Button
      flex="1"
      onClick={() => {
        if (props.disabled) return false

        if (props.onClick) props.onClick()

        if (window) {
          const message = JSON.stringify({
            label: props.label,
          })

          if (window.postMessage) {
            window.postMessage(message, '*')
          }

          // Support newer versions of react-native-webview
          if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
            window.ReactNativeWebView.postMessage(message)
          }
        }
      }}
      disabled={props.disabled}
    >
      {props.label || 'Next'}
    </Button>
  </FloatingButtonWrapper>
)

export default FloatingButton
