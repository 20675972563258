export const toFloat = value => {
  return Math.round(Number.parseFloat(value) * 100) / 100
}

export const toCurrency = (value, currency = 'MYR') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
  })

  return formatter.format(value)
}
