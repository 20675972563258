import React from 'react'
import styled from 'styled-components'

import { H2, H3, H5, P } from 'components/Common/Typography'

import { slugify } from 'utils'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Container = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-basis: 290px;
  flex-flow: column nowrap;
  margin-bottom: 3rem;

  @media (max-width: 800px) {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  // Spacefill is used to prevent items in the last row from stretching
  ${props =>
    props.spacefill &&
    `
    margin: 0;
    border: none;
  `}
`

const Image = styled.div`
  position: relative;
  max-width: 100%;
  padding: 66% 0 0 0;
  line-height: 66%;
  overflow: hidden;
  background: #e0e0e0;
  margin-bottom: 1rem;

  img {
    position: absolute;
    display: inline-block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    vertical-align: middle;
    transition: 0.2s ease all;

    // Object fit (hope this doesn't break randomly)
    width: 100%;
    height: 100%;
    margin-left: 0;
    object-fit: cover;
  }

  :hover {
    img {
      width: 106%;
      height: 106%;
      margin-left: -3%;
      filter: brightness(1.2);
    }
  }
`

const Details = styled.div`
  padding: 0.2rem;

  @media (max-width: 800px) {
    padding: 0.5rem;
  }
`

const City = styled(H5)`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`

const Name = styled(H2)`
  letter-spacing: 0;
  margin-bottom: 0;
  font-size: 1.5rem;

  a {
    color: #000000;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`

const Building = styled(H3)`
  letter-spacing: 0;
  font-weight: normal;
`

const Address = styled(P)`
  letter-spacing: 0;
  margin-bottom: 1rem;
  color: #777777;
`

// const Pricing = styled.div`
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: flex-end;
//   margin: auto -0.5rem;

//   display: none;
// `

// const Desk = styled.div`
//   display: flex;
//   flex-flow: column nowrap;
//   justify-content: flex-start;
//   margin: 0 0.5rem;
//   width: auto;
// `
// const Type = styled.div`
//   font-size: 0.8rem;
//   font-weight: bold;
//   line-height: 0.8rem;
//   color: #e08a5c;
//   padding: 0.15rem 0;
//   margin-bottom: 0.3rem;
//   letter-spacing: 1px;
//   text-align: right;
// `
// const Price = styled.div`
//   display: flex;
//   flex-flow: row wrap;
//   justify-content: space-between;
//   margin-left: auto;
// `

// const Currency = styled.div`
//   margin-right: 0.5rem;
//   font-size: 0.7rem;
// `

// const Amount = styled.div`
//   font-size: 1.4rem;
//   font-weight: 100;
// `

// const PerSeat = styled.div`
//   font-size: 0.7rem;
//   text-align: right;
//   margin-bottom: 0.3rem;
//   color: #777777;
// `

// const NoPricing = styled.div`
//   align-self: flex-end;
// `

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const LocationsListItem = props => {
  if (props.spacefill) {
    return <Container spacefill />
  }

  return (
    <Container>
      <a href={props.slug} onMouseEnter={() => props.onOfficeHover(props)}>
        <Image>{props.thumbnail && <img src={props.thumbnail.url} alt={props.building} />}</Image>
      </a>

      <Details>
        <City
          onClick={() =>
            props.updateFilter({
              label: props.city.name,
              value: slugify(`/locations/${props.city.country.name}/${props.city.name}`),
            })
          }
        >
          {`${props.city.name}, ${props.city.country.name}`.toUpperCase()}
        </City>

        <Name>
          <a href={`${props.slug}`}>{props.area.toUpperCase()}</a>
        </Name>

        <Building>{props.building}</Building>

        <Address>{props.address}</Address>

        {/* {props.packages && props.packages.length > 0 ? (
          <Pricing>
            {props.packages.map(_package => (
              <Desk key={`locations-list-item-desk-${props.area}-${_package.type}`}>
                <Type>{_package.type.toUpperCase()}</Type>

                <Price>
                  <Currency>{_package.currency}</Currency>
                  <Amount>{_package.price}</Amount>
                </Price>
                <PerSeat>/ {_package.cycle.toUpperCase()}</PerSeat>
              </Desk>
            ))}
          </Pricing>
        ) : (
          <NoPricing>
            <Type style={{ color: '#f6f6f6', background: '#a2a2a2' }}>PRICING NOT AVAILABLE</Type>
          </NoPricing>
        )} */}
      </Details>
    </Container>
  )
}

export default LocationsListItem
