import React from 'react'
import styled from 'styled-components'

import Carousel from 'components/Carousel'
import WrappedContainer from 'components/Common/WrappedContainer'
import { H1, H3, H5 } from 'components/Common/Typography'
import Button from 'components/Common/Button'
import { slugify } from 'utils'

///////////////////////////////////////////////////////////////////////////////
// Styling

const LeftBlock = styled.div`
  flex: 3;
`

const CarouselContainer = styled.div`
  padding: 3rem 2rem;

  @media (max-width: 800px) {
    padding: 0;
  }
`

const RightBlock = styled.div`
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LocationsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem;

  @media (max-width: 800px) {
    padding: 1rem;
  }
`

const Country = styled.div`
  margin-bottom: 2rem;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class LocationsCarousel extends React.Component {
  state = {
    locations: null,
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  // Fetch minimal list of offices in order to populate
  // 'Preferred Location' dropdown options
  async componentDidMount() {
    try {
      const { data } = await fetch(
        `${process.env.REACT_APP_API_URL}/items/countries?fields=name,cities.name`
      ).then(res => res.json())

      this.setState({
        locations: data,
      })
    } catch (err) {
      console.error(err)
    }
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { slides } = this.props
    const { locations } = this.state

    // Remove 'title' property from slides (so Carousel doesn't render the text)
    slides && slides.map(slide => (slide.title = undefined))

    // todo: mobile dimensions hehe
    // todo: mobile dimensions hehe
    // todo: mobile dimensions hehe
    // todo: mobile dimensions hehe
    // todo: mobile dimensions hehe

    return (
      <WrappedContainer
        maxWidth="1200px"
        background="#fff5e2"
        style={{ container: { flexFlow: 'row wrap', alignItems: 'center' } }}
      >
        <LeftBlock>
          <CarouselContainer>
            <Carousel height="65vh" depthMode background="transparent" slides={slides} />
          </CarouselContainer>
        </LeftBlock>

        <RightBlock>
          <LocationsContainer>
            <H5>DIVINE WORKSPACES. SUPPORTING COMMUNITY.</H5>
            <H1>OUR LOCATIONS</H1>

            {locations &&
              locations.map(country => (
                <Country key={`locations-carousel-country-${country.name}`}>
                  <H3>{`${country.name}`.toUpperCase()}</H3>
                  <H5>
                    {country.cities.map((city, index) => (
                      <React.Fragment key={`locations-carousel-city-${city.name}`}>
                        <a href={slugify(`/locations/${country.name}/${city.name}`)}>
                          {`${city.name}`.toUpperCase()}
                        </a>
                        {index < country.cities.length - 1 && ' / '}
                      </React.Fragment>
                    ))}
                  </H5>
                </Country>
              ))}

            <Button href="/locations">VIEW ALL LOCATIONS</Button>
          </LocationsContainer>
        </RightBlock>
      </WrappedContainer>
    )
  }
}

export default LocationsCarousel
