import React from 'react'
import Helmet from 'react-helmet'
import ReactMarkdown from 'react-markdown'

import Layout from 'components/Common/Layout'
import WrappedContainer from 'components/Common/WrappedContainer'

const md = require('data/privacy.md')

///////////////////////////////////////////////////////////////////////////////
// Component Definition
class PrivacyPage extends React.Component {
  state = {
    data: null,
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  async componentDidMount() {
    const data = await fetch(md).then(res => res.text())

    this.setState({ data })
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { data } = this.state

    return (
      <Layout filledNav>
        <Helmet>
          <title>Privacy Policy | Common Ground</title>
          <style>{`
            #navbar.expanded {
              color: #000000;
            }
            p {
              margin: 0.5rem 0;
            }
          `}</style>
        </Helmet>

        <section id="privacy">
          <WrappedContainer navPad>
            <ReactMarkdown source={data} />
          </WrappedContainer>
        </section>
      </Layout>
    )
  }
}

export default PrivacyPage
