import React from 'react'
import styled from 'styled-components'

import { H3 } from 'components/Common/Typography'

///////////////////////////////////////////////////////////////////////////////
// Styling

const BenefitsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 1rem -1.5rem;
  width: calc(100% + 3rem);

  @media (max-width: 800px) {
    flex-flow: column nowrap;
    margin: 1rem auto;
    width: 100%;
  }
`

const Benefit = styled.div`
  position: relative;
  width: 100%;
  max-width: 33%;
  min-width: 180px;
  padding: 1.5rem;
  font-size: 1.2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 800px) {
    max-width: 100%;
    padding: 1rem 1.5rem;
  }
`

const Title = styled(H3)`
  padding-left: 2.2rem;
  padding-right: 3rem;
  letter-spacing: 1px;
  font-size: 1.1rem;
`

const Description = styled.div`
  padding-left: 2.2rem;
  font-size: 1rem;
  color: #505050;
`

const Icon = styled.div`
  position: absolute;
  left: 1rem;
  top: 1.4rem;
  width: 24px;
  height: 24px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Benefits = () => {
  return (
    <BenefitsContainer>
      {benefits.map(benefit => (
        <Benefit key={`amenity-${benefit.title}`}>
          <Icon>
            <img src={benefit.icon} alt={benefit.title} />
          </Icon>
          <Title>{benefit.title}</Title>
          <Description>{benefit.description}</Description>
        </Benefit>
      ))}
    </BenefitsContainer>
  )
}

export default Benefits

// todo: fetch this from API (?)
// todo: language translations

const benefits = [
  {
    title: 'GLOBAL COWORKING ACCESS',
    description: 'Work from any hot desk in any Common Ground location around the world',
    icon: '/images/icons/network-links.png',
  },
  {
    title: 'HIGH-SPEED INTERNET',
    description: 'Super-fast wireless and wired connection',
    icon: '/images/icons/signal.png',
  },
  {
    title: 'BUSINESS-CLASS PRINTERS',
    description: 'Multiple top-of-the-range printers, scanners, and copiers at every location',
    icon: '/images/icons/page.png',
  },
  {
    title: 'BUSINESS SUPPORT SERVICES',
    description:
      'Members-only rates for top service providers (accounting, legal, courier, HR, insurance, graphics design, etc.)',
    icon: '/images/icons/chat.png',
  },
  {
    title: 'WEEKLY EVENTS',
    description:
      'Regular workshops, talks from business experts, happy hours and more—plus room to host your own',
    icon: '/images/icons/calendar.png',
  },
  {
    title: 'LIFESTYLE PERKS',
    description: 'Discounts for gym memberships, travel, and more with our corporate partners',
    icon: '/images/icons/diamond.png',
  },
  {
    title: 'FREE REFRESHMENTS',
    description:
      'Complimentary coffee, tea, and water (micro-roasted coffee and professional baristas at selected locations)',
    icon: '/images/icons/coffee.png',
  },
  {
    title: 'STYLISH INTERIORS',
    description: 'Highly designed workspaces that balance professionalism and style',
    icon: '/images/icons/couch.png',
  },
  {
    title: 'ON-DUTY STAFF',
    description:
      'Dedicated front desk staff and Community Managers available 9AM-6PM, Monday to Friday',
    icon: '/images/icons/phone.png',
  },
  {
    title: 'CLEANING SERVICES',
    description: 'Daily professional cleaning',
    icon: '/images/icons/leaf.png',
  },
]
