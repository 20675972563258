import React from 'react'
import styled from 'styled-components'

///////////////////////////////////////////////////////////////////////////////
// Styling

const RedLocationPin = styled.div`
position: absolute;
border-radius: 50%;
border: 12px solid ${props => props.color || '#e61a23'};
background-color: ${props => props.dotColor || 'transparent'};
width: 8px;
height: 8px;
top: 0;
left: 0;
box-sizing: content-box;

${props =>
  props.bouncy &&
  `
  animation-name: bounce;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
`}

:after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  bottom: -40px;
  left: -9px;
  border: 13px solid transparent;
  border-top: 22px solid ${props => props.color || '#e61a23'};
}
}

@keyframes bounce {
0% {
  top: 0;
}

100% {
  top: -18px;
}
}
`

const OfficeBuilding = styled.div`
  background: #fffbf5;
  display: inline-block;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  font-size: 1rem;
  top: -45px;
  left: 25px;
  position: absolute;
  box-shadow: 0 0 4px #00000047;
  border-radius: 25px;
  margin-left: -50%;
  transition: 0.3s ease all;

  a {
    padding: 2rem 1rem;
    margin: 0 -1rem;
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    background: ${props => props.theme.cgYellow};
    animation: none;
  }

  ${props =>
    props.bouncy &&
    `
    animation-name: bounce2;
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-delay: 0.1s;
  `}

  @keyframes bounce2 {
    0% {
      top: -45px;
    }

    100% {
      top: -65px;
    }
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Pin = props => (
  <>
    <RedLocationPin bouncy={props.bouncy} {...props} />
    {props.text && (
      <OfficeBuilding bouncy={props.bouncy}>
        <a href={props.href}>{props.text}</a>
      </OfficeBuilding>
    )}
  </>
)

export default Pin
