import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const AMBITION_API_URL = process.env.REACT_APP_AMBITION_API_URL

export const getOffices = async () => {
  try {
    const result = await axios.get(API_URL + '/custom/offices').then(res => res.data)
    return result.data
  } catch (err) {
    throw err
  }
}

export const getOfficesByCountry = async () => {
  try {
    const result = await axios.get(API_URL + '/custom/offices/by-country').then(res => res.data)
    return result.data
  } catch (err) {
    throw err
  }
}

export const postSiteVisitRequest = async data => {
  try {
    const result = await axios.post(`${AMBITION_API_URL}/bookings`, data)
    return result
  } catch (err) {
    if (err.response.data) {
      throw err.response.data
    }
    throw err
  }
}

export const checkFlexPromoCodeIsValid = async checkFlexPromoCodeParams => {
  let body = JSON.stringify(checkFlexPromoCodeParams)

  return fetch(`${AMBITION_API_URL}/flexi/promos/apply`, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(res => res.json())
    .then(res => {
      return res
    })
    .catch(e => {
      console.log(e)
      throw e
    })
}
