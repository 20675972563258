import React from 'react'

class WordPressRedirect extends React.Component {
  componentDidMount() {
    const { search } = this.props.location

    if (!!search) {
      // bring forward the params if there is any
      window.location.href = `https://www.commonground.work/my-en/book-a-tour${search}`
    } else {
      window.location.href = 'https://www.commonground.work/my-en/book-a-tour'
    }
  }

  render() {
    return <div />
  }
}

export default WordPressRedirect
