import React from 'react'
import Select, { components } from 'react-select'

//////////////////////////////////////////////
// Override react-select styles and options //
// https://react-select.com/styles          //
//////////////////////////////////////////////

const STYLES = {
  option: (styles, { data }) => {
    const type =
      data.type === 'country'
        ? {
            fontWeight: 'bold',
            fontFamily: 'Calibre',
            fontSize: '1rem',
          }
        : {
            fontSize: '1rem',
            fontFamily: 'Calibre',
            paddingLeft: '1.2rem',
          }

    return {
      ...styles,
      ...type,
    }
  },

  control: styles => ({
    ...styles,
    border: '1px solid black',
    borderRadius: 0,
  }),

  menu: styles => {
    return {
      ...styles,
      marginTop: 0,
      borderRadius: 0,
    }
  },
}

const OPTION = props => (
  <div>
    {props.data.count && (
      <div
        style={{
          position: 'absolute',
          right: '1rem',
          lineHeight: 'calc(1rem + 16px)',
          color: props.isSelected ? '#fff' : '#000',
        }}
      >
        {props.data.count}
      </div>
    )}
    <components.Option {...props} />
  </div>
)

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const LocationSelectDropdown = props => {
  return (
    <div style={{ width: '240px', maxWidth: '100%', ...props.style }}>
      <Select
        options={props.options}
        onChange={value => props.onChange && props.onChange(value)}
        value={props.value}
        styles={STYLES}
        components={{ Option: OPTION }}
        isSearchable={false}
      />
    </div>
  )
}

export default LocationSelectDropdown
