import React from 'react'
import styled from 'styled-components'
import Flickity from 'react-flickity-component'

import { slugify } from 'utils'

import 'assets/css/flickity.css'

///////////////////////////////////////////////////////////////////////////////
// Constants

const flickitySettings = {
  freeScroll: true,
  prevNextButtons: false,
  contain: true,
  pageDots: false,
}

///////////////////////////////////////////////////////////////////////////////
// Styling

const NearbyOfficesWrapper = styled.div`
  padding: 0 ${props => props.theme.MOBILE.PAD};
  margin: 0.5rem 0;
`

const NearbyOffice = styled.div`
  position: relative;
  width: calc(50% - ${props => props.theme.MOBILE.PAD});
  height: 0;
  padding-top: 30vh;
  margin-right: ${props => props.theme.MOBILE.PAD};
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  background: url(${props => props.image});
  border-radius: ${props => props.theme.MOBILE.BORDER_RADIUS};
  overflow: hidden;

  a {
    text-decoration: none;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${props => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  :after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  }
`

const Details = styled.div`
  position: relative;
  z-index: 1;
  color: #ffffff;
  padding: 0.5rem;
  text-decoration: none;
`

const Building = styled.div`
  font-family: Acrom;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: -0.7px;
`

const Area = styled.div`
  font-family: Calibre;
  font-size: 0.8rem;
  white-space: pre-wrap;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const NearbyOffices = props => (
  <NearbyOfficesWrapper>
    <Flickity options={flickitySettings}>
      {props.nearbyOffices.map(nearbyOffice => (
        <NearbyOffice key={`nearby-office-${nearbyOffice.building}`}>
          <a href={slugify(`/m/offices/${nearbyOffice.building}`)}>
            <Background image={nearbyOffice.image.url} />

            <Details>
              <Building>{nearbyOffice.building}</Building>
              <Area>{nearbyOffice.area}</Area>
            </Details>
          </a>
        </NearbyOffice>
      ))}
    </Flickity>
  </NearbyOfficesWrapper>
)

export default NearbyOffices
