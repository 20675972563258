import React from 'react'
import styled from 'styled-components'

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  h1 {
    font-family: Acrom;
    font-size: 1.4rem;
    color: #183a33;
    letter-spacing: -2px;
    margin-bottom: 0.8rem;
  }

  p {
    color: #183a33;
    font-family: Calibre;
  }
`

const Submitted = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  color: #183a33;
  text-align: center;

  a {
    color: #183a33;
  }
`

const PromoClerksThankYouPage = () => {
  return (
    <Page>
      <Submitted id="thank-you">
        <h1>Thank you for your submission</h1>

        <p>You will be contacted by one of our partners soon!</p>

        <p>You may close this window</p>
      </Submitted>
    </Page>
  )
}

export default PromoClerksThankYouPage
