import React, { useState } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Flickity from 'react-flickity-component'

import LandingForm from './LandingForm'

import 'assets/css/flickity.css'

const flickitySettings = {
  groupCells: true,
  contain: true,
  pageDots: false,
  imagesLoaded: true,
}

///////////////////////////////////////////////////////////////////////////////
// Styling

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #ffffff;

  h1 {
    font-family: Acrom;
    font-weight: bold;
    font-size: 2.3rem;
    letter-spacing: -0.15rem;
  }

  h2 {
    font-family: Cheltenham;
    font-weight: normal;
    font-size: 1.5rem;
    letter-spacing: -0.03rem;
  }

  h3 {
    font-family: Acrom;
    font-weight: bold;
    font-size: 1.4rem;
    letter-spacing: 1px;
    text-align: center;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }

  .container {
    z-index: 5;
  }

  img {
    display: block;
    max-width: 100%;

    &.photo {
      border-radius: 20px;
    }
  }

  ul.terms {
    font-size: 0.95rem;
    line-height: 1.2rem;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.8rem;
    }
  }
`

const Header = styled.div`
  position: relative;
  width: 100%;
  padding-top: 1rem
  padding-bottom: 1rem;

  a {
    display: inline-block;

    img {
      max-height: 100%;
      max-width: 140px;
      width: auto;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`

const Footer = styled.footer`
  background: #151716;
  color: #58585a;
  padding: 1rem;
`

const FooterContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  font-size: 0.85rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  a,
  span {
    color: inherit;
    margin: 0 1rem;
  }

  a {
    margin-right: 3rem;
    text-decoration: none;

    &:hover {
      color: #fecd6f;
    }
  }

  @media (max-width: 768px) {
    span {
      width: 100%;
      margin-bottom: 1rem;
    }

    a {
      margin-right: none;
    }

    div {
      display: none;
    }
  }
`

const SeparatorImage = ({ src, backgroundColor = 'transparent' }) => (
  <img
    src={src}
    alt="-"
    className="noselect"
    draggable={false}
    style={{ position: 'relative', zIndex: 10, margin: '-2px 0', backgroundColor }}
  />
)

///////////////////////////////////////////////////////////////////////////////
// Functions

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class PromosFlexPage extends React.Component {
  state = {
    loading: null,
    error: null,
    submitted: false,
  }

  ////////////
  // Render //
  ////////////

  render() {
    return (
      <>
        <Helmet>
          <title>CG | Corporate Flex Solution</title>
          <style>{`
            html {
              font-size: 16px;
              background: #151716;
            }
          `}</style>
        </Helmet>

        <Page>
          <Header>
            <div className="container">
              <a href="https://www.commonground.work">
                <img src="/images/cg-logo.png" alt="Common Ground" />
              </a>
            </div>
          </Header>

          <LandingSection />

          <SeparatorImage src={require('./images/separatorwhite.png')} backgroundColor="#e9e9e9" />

          <DesignedForSection />

          <div style={{ marginBottom: '-5vw' }}>
            <SeparatorImage src={require('./images/separatorgrey.png')} />
          </div>

          <FacilitiesSection />

          <div style={{ marginTop: '-5vw', transform: 'rotateZ(180deg)' }}>
            <SeparatorImage src={require('./images/separatorwhite.png')} />
          </div>

          <ServicesSection />

          <SeparatorImage src={require('./images/separatorwhite.png')} backgroundColor="#e9e9e9" />

          <BenefitsSection />

          <SeparatorImage src={require('./images/separatorgrey.png')} backgroundColor="#ffffff" />

          <ContactSection />

          <SeparatorImage src={require('./images/separatorwhite.png')} backgroundColor="#19423b" />

          <FAQSection />
        </Page>

        <Footer>
          <FooterContainer>
            <span>© 2021 Common Ground Works Sdn Bhd. All rights reserved.</span>

            <div style={{ flex: 1 }} />

            <a href="https://www.commonground.work/terms-and-conditions">Terms & Conditions</a>

            <a href="https://www.commonground.work/privacy">Privacy Policy</a>
          </FooterContainer>
        </Footer>
      </>
    )
  }
}

export default PromosFlexPage

//
// ─── LANDING SECTION ────────────────────────────────────────────────────────────
//

const LandingSection = () => {
  return (
    <section id="landing">
      <div className="row no-gutters upper">
        <div className="container">
          <div className="col-12">
            <h1>Corporate Flex Solution</h1>

            <h2>Workspace on demand</h2>

            <div className="video-container">
              <video
                poster={require('./images/poster.jpg')}
                autoPlay={false}
                controls={true}
                muted
                loop
              >
                <source src={require('./images/introvideo.mp4')} />
              </video>
            </div>
          </div>
        </div>
      </div>

      <SeparatorImage src={require('./images/separatoryellow.png')} backgroundColor="#ffffff" />

      <div className="row no-gutters lower">
        <div className="container">
          <div className="row">
            <h2 className="w-100 text-center">
              On-demand workspaces, for how, when and where you need it.
              <br />
              Fully refundable and pay for what you use only.
            </h2>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <div className="carousel-container">
                <Flickity className="carousel" options={flickitySettings}>
                  <div className="carousel-cell">
                    <img src={require('./images/iphonestep1.png')} alt="Step 1" />
                    <p>Click the Flex Membership banner in the CG app</p>
                  </div>

                  <div className="carousel-cell">
                    <img src={require('./images/iphonestep2.png')} alt="Step 2" />
                    <p>Scan QR code</p>
                  </div>

                  <div className="carousel-cell">
                    <img src={require('./images/iphonestep3.png')} alt="Step 3" />
                    <p>You're checked in!</p>
                  </div>
                </Flickity>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <LandingForm />

              <ul className="perks">
                <li className="perk">No deposit</li>
                <li className="perk">No long-term commitment</li>
                <li className="perk">Terminate anytime</li>
                <li className="perk">Easy to manage</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        #landing {
          padding: 0 0 3rem 0;

          .row {
            &.upper {
              background-color: #fbca6f;
              padding-top: 3rem;
              margin-bottom: -15vw;

              .container {
                position: relative;
                z-index: 100;
              }

              .video-container {
                width: 100%;
                position: relative;
                z-index: 100;
                padding-top: 2rem;

                video {
                  width: 100%;
                  height: auto;
                  background-color: #111111;
                }
              }

              h1 {
                font-size: 3.8rem;
                letter-spacing: -4px;
                margin: 0;
              }

              h2 {
                font-size: 3.2rem;
                letter-spacing: -2px;
                margin: 0;
              }

              @media (max-width: 768px) {
                h1 {
                  font-size: 2.3rem;
                }

                h2 {
                  font-size: 2rem;
                }
              }
            }

            &.lower {
              background-color: #ffffff;
              padding-top: 10vw;

              h2 {
                font-size: 2rem;
              }

              .carousel-container {
                max-width: 400px;
                margin: auto;
              }

              @media (max-width: 768px) {
                h2 {
                  font-size: 1.4rem;
                }
              }
            }
          }

          .perks {
            font-size: 1.7rem;
            margin: 1rem 0.5rem;

            .perk {
              margin-bottom: 0.3rem;
            }
          }
        }
      `}</style>

      <style jsx global>{`
        #landing .carousel {
          width: 100%;

          .carousel-cell {
            width: 100%;
            height: auto;
            margin-right: 10px;

            p {
              margin: 0;
              margin-top: -1.5rem;
              font-weight: 800;
              text-align: center;
            }
          }

          .flickity-prev-next-button.previous {
            left: 0px;
            background: none;
            color: #111111;
          }

          .flickity-prev-next-button.next {
            right: 0px;
            background: none;
            color: #111111;
          }
        }
      `}</style>
    </section>
  )
}

//
// ─── DESIGNED FOR SECTION ───────────────────────────────────────────────────────
//

const DesignedForSection = () => {
  const items = [
    {
      image: require('./images/designedfor1.png'),
      description:
        'Companies that have staff working from home(WFH) but require a conducive and productive workspace to collaborate',
    },
    {
      image: require('./images/designedfor2.png'),
      description:
        'Companies that prefer staff to work near home to reduce commuting costs and to reduce risks of infection',
    },
    {
      image: require('./images/designedfor3.png'),
      description:
        'Companies who are reducing permanent office space to save on fixed costs (rental and maintenance)',
    },
    {
      image: require('./images/designedfor4.png'),
      description:
        "Companies that require a conducive workspace but need the flexibility to upsize/downsize and don't want to be tied down to Capex and long-term rental commitments",
    },
    {
      image: require('./images/designedfor5.png'),
      description:
        'Companies that want to stay engaged with their staff whilst diversifying workspace locations',
    },
    {
      image: require('./images/designedfor6.png'),
      description: 'Companies that are adopting Core-Flex workspace policies',
    },
  ]

  return (
    <section id="designed-for">
      <h3>CORPORATE FLEX IS DESIGNED FOR</h3>

      <Flickity className="carousel" options={{ ...flickitySettings, pageDots: true }}>
        {items.map((item, index) => (
          <div className="carousel-cell" key={`designed-for-${index}`}>
            <img src={item.image} alt={item.description} />
            <p>{item.description}</p>
          </div>
        ))}
      </Flickity>

      <style jsx>{`
        #designed-for {
          background-color: #e9e9e9;
          padding: 3rem 0;
          text-align: center;
        }
      `}</style>

      <style jsx global>{`
        #designed-for .carousel {
          width: calc(100% - 100px);
          margin-left: 50px;

          .carousel-cell {
            width: calc(33.33% - 10px);
            height: auto;
            margin-right: 10px;
            display: flex;
            flex-flow: column nowrap;

            img {
              height: 240px;
              object-fit: contain;
            }

            p {
              text-align: center;
              max-width: 80%;
              margin: auto;
            }
          }

          .flickity-prev-next-button.previous {
            left: 0px;
            background: none;
            color: #111111;
          }

          .flickity-prev-next-button.next {
            right: 0px;
            background: none;
            color: #111111;
          }
        }

        @media (max-width: 768px) {
          #designed-for .carousel {
            width: 94%;
            margin-left: 3%;
            margin-right: 3%;

            .carousel-cell {
              width: 100%;
            }
          }
        }
      `}</style>
    </section>
  )
}

//
// ─── FACILITIES SECTION ─────────────────────────────────────────────────────────
//

const FacilitiesSection = () => {
  const items = [
    {
      image: require('./images/facilities1.jpg'),
      title: 'Workspace on demand',
      description:
        'Get to work at any of the available desks in the allocated Hot Desk area in Common Ground. Check in at any venue and just pay-as-you-go.',
    },
    {
      image: require('./images/facilities2.jpg'),
      title: 'Meeting rooms',
      description:
        'From small rooms for a 5 person discussion to a 20 person board room meeting, bookings are just a few clicks away.',
    },
    {
      image: require('./images/facilities3.jpg'),
      title: 'In-house cafe',
      description:
        'Fuel your ambitions with the aroma of fresh coffee and a healthy snack, made by the finest baristas in town or enjoy our free-flow coffee and tea, available in the pantry.',
    },
    {
      image: require('./images/facilities4.jpg'),
      title: 'The Grounds',
      description:
        'A common area for members to hang out, attend events or simply just to network.',
    },
    {
      image: require('./images/facilities5.jpg'),
      title: 'The Loungery',
      description:
        'A comfortable public space for all Common Ground members to spend time resting and relaxing.',
    },
    {
      image: require('./images/facilities6.jpg'),
      title: 'Reception',
      description:
        'Front desk staff are available for all administrative matters as an added service while community managers are there for all other enquiries.',
    },
  ]

  return (
    <section id="facilities">
      <Flickity className="carousel" options={flickitySettings}>
        {items.map((item, index) => (
          <div className="carousel-cell" key={`facility-${index}`}>
            <img src={item.image} alt={item.title} />

            <div className="dark-overlay" />

            <div className="content-container">
              <div className="content">
                <h3>FACILITIES</h3>
                <h1>{item.title}</h1>
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Flickity>

      <style jsx global>{`
        #facilities .carousel {
          width: 100%;

          .carousel-cell {
            width: 100%;
            height: auto;
            display: flex;
            flex-flow: column nowrap;

            img {
              width: 100%;
              height: 75vh;
              object-fit: cover;
            }

            .dark-overlay {
              position: absolute;
              background-color: #000000;
              opacity: 0.4;
              width: 100%;
              height: 100%;
            }

            .content-container {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
              align-items: center;

              .content {
                max-width: 600px;
                color: #ffffff;
                text-align: center;
                padding: 0 2rem;

                h1 {
                  margin: 0;
                }

                p {
                  margin: 0;
                  font-size: 1.7rem;
                  line-height: 1.7;
                }
              }
            }
          }

          .flickity-prev-next-button.previous {
            left: 0px;
            background: none;
            color: #ffffff;
          }

          .flickity-prev-next-button.next {
            right: 0px;
            background: none;
            color: #ffffff;
          }
        }
      `}</style>
    </section>
  )
}

//
// ─── SERVICES SECTION ───────────────────────────────────────────────────────────
//

const ServicesSection = () => (
  <section id="services">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6">
          <img src={require('./images/services1.jpg')} alt="Printing and Photocopying" />
        </div>

        <div className="col-12 col-md-6 pt-2 pt-md-0 texts">
          <h1>Printing and Photocopying</h1>
          <p>
            Once at a Common Ground outlet, printing and photocopying is as easy as clicking a few
            buttons on the CG app. We are able to track exactly how many pages are printed or copied
            and you will be charged for only what has been used.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 order-0 order-md-1">
          <img
            src={require('./images/services2.jpg')}
            alt="Stationeries, courier and delivery services"
          />
        </div>

        <div className="col-12 col-md-6 order-1 order-md-0 pt-2 pt-md-0 texts">
          <h1>Stationeries, courier and delivery services</h1>
          <p>
            Business support services such as stationery supplies, sending out a courier or delivery
            services are available. Just ask our friendly CG Community team on site for a quote.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <img src={require('./images/services3.jpg')} alt="Secretarial/Admin services" />
        </div>

        <div className="col-12 col-md-6 pt-2 pt-md-0 texts">
          <h1>Secretarial/Admin services</h1>
          <p>
            Need an office assistant to run an errand or carry out a task? No problem! This is
            available based on man-hour help needed.
          </p>
        </div>
      </div>
    </div>

    <style jsx>{`
      #services {
        background-color: #ffffff;
        padding: 2rem 0;

        .row {
          margin: 1rem 0;

          .texts {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            text-align: center;

            h1 {
              margin: 0;
            }
          }

          img {
            width: 100%;
            height: 100%;
            max-height: 300px;
            object-fit: cover;
          }
        }
      }
    `}</style>
  </section>
)

//
// ─── BENEFITS SECTION ───────────────────────────────────────────────────────────
//

const BenefitsSection = () => {
  const items = [
    {
      image: require('./images/benefits1.png'),
      title: 'Work near home',
      description:
        'As Southeast Asia’s largest coworking network with 12 outlets in Malaysia, two in Thailand and four in The Philippines, all Common Ground outlets are strategically located near where the city’s workforce lives.',
    },
    {
      image: require('./images/benefits2.png'),
      title: 'Work in comfort',
      description:
        'Beautifully designed workspaces with ergonomic furniture, friendly customer service and a vibrant working environment. Enhanced safety standards create safe office spaces for teams to work productively.',
    },
    {
      image: require('./images/benefits3.png'),
      title: 'Save money',
      description:
        'Pay-as-you-go with no deposits, ability to terminate anytime, no minimum contract period and fully refundable credits.',
    },
    {
      image: require('./images/benefits4.png'),
      title: 'Reduce carbon footprint',
      description:
        "You not only minimise your business's carbon footprint due to shorter commutes, but you also improve employee morale by building a brand that is aligned with sustainability goals.",
    },
    {
      image: require('./images/benefits5.png'),
      title: 'Diversify pandemic risks',
      description:
        'Avoid total office shutdowns/closures by having work venue options across multiple locations. This mitigates ​risks of operational disruption and ​is a proactive plan to ensure ​business continuity.​',
    },
    {
      image: require('./images/benefits6.png'),
      title: 'CG App',
      description:
        "Enjoy all of Common Ground's perks and amenities, a feature-rich app with exclusive deals and discounts from over 500+ brands and one-stop platform to getting your best work done. Grow your network, engage with colleagues, assign daily tasks and even purchase gifts for your loved ones.​",
    },
  ]

  return (
    <section id="benefits">
      <h3>BENEFITS</h3>

      <Flickity className="carousel" options={{ ...flickitySettings, pageDots: true }}>
        {items.map((item, index) => (
          <div className="carousel-cell" key={`benefit-${index}`}>
            <img src={item.image} alt={item.description} />
            <p style={{ fontWeight: 800 }}>{item.title}</p>
            <p>{item.description}</p>
          </div>
        ))}
      </Flickity>

      <style jsx>{`
        #benefits {
          background-color: #e9e9e9;
          padding: 2rem 0;
          text-align: center;
        }
      `}</style>

      <style jsx global>{`
        #benefits .carousel {
          width: calc(100% - 100px);
          margin-left: 50px;

          .carousel-cell {
            width: calc(33.33% - 10px);
            height: auto;
            margin-right: 10px;
            display: flex;
            flex-flow: column nowrap;

            img {
              height: 240px;
              object-fit: contain;
            }

            p {
              text-align: center;
              max-width: 80%;
              margin: auto;
            }
          }

          .flickity-prev-next-button.previous {
            left: 0px;
            background: none;
            color: #111111;
          }

          .flickity-prev-next-button.next {
            right: 0px;
            background: none;
            color: #111111;
          }
        }

        @media (max-width: 768px) {
          #benefits .carousel {
            width: 94%;
            margin-left: 3%;
            margin-right: 3%;

            .carousel-cell {
              width: 100%;
            }
          }
        }
      `}</style>
    </section>
  )
}

//
// ─── CONTACT SECTION ────────────────────────────────────────────────────────────
//

const ContactSection = () => (
  <section id="contact">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <p>
            Sign up today for the Corporate Flex solution to future-proof your business.
            <br />
            Get a quote today!
          </p>

          <br />
          <br />
          <br />

          <p>
            <b>WhatsApp:</b> +6011 5631 9888
          </p>
          <p>
            <b>Email:</b> info@commonground.work
          </p>
        </div>

        <div className="col-12 col-md-6">
          <LandingForm />
        </div>
      </div>
    </div>

    <style jsx>{`
      #contact {
        background-color: #ffffff;
        padding: 3rem 0;

        p {
          font-size: 2rem;
          line-height: 1.5;
          margin: 0;
        }

        @media (max-width: 768px) {
          p {
            font-size: 1.6rem;
          }
        }
      }
    `}</style>
  </section>
)

//
// ─── FAQ SECTION ────────────────────────────────────────────────────────────────
//

const FAQSection = () => {
  const categories = [
    {
      category: 'Signing up',
      items: [
        {
          q:
            'If I sign up in Common Ground Malaysia, will my team have access to Common Ground Philippines and Thailand as well?',
          a: 'Yes! Charges will be based on the respective country’s pricing structures.',
        },
        {
          q: 'How long will it take to activate my account?',
          a:
            'Accounts will be activated within 24 hours of your HR/admin submitting the name list of eligible users.',
        },
        {
          q: 'Are we allowed to share our Common Ground membership access?',
          a:
            'No as each Common Ground membership account is uniquely registered to each individual’s IC, phone number and Common Ground App login. Your HR/Admin team will submit lists of eligible users to Common Ground to be registered.​',
        },
      ],
    },
    {
      category: 'Termination',
      items: [
        {
          q:
            'How long in advance should I notify Common Ground should I decide to terminate my account?',
          a: 'We require a 30-day notice period for account terminations.​',
        },
      ],
    },
    {
      category: 'Billings, credits and deposits',
      items: [
        {
          q: 'Do we need to pay a deposit?​',
          a:
            'No deposit payments are required. However there may be processing fee involved (for access cards, etc) depending on the individual building management policy.',
        },
        {
          q: 'What is the minimum amount of credits I can prepay or top up into my CG account?',
          a: 'The minimum amount for top-up credits is RM100.',
        },
        {
          q: 'Will our remaining credits be refunded at the end of my tenure?',
          a: 'Yes, we will refund any unused credits at the end of your tenure.',
        },
        {
          q: 'Can we make withdrawals of our credit during ​the tenure?',
          a: 'Credits cannot be withdrawn during your tenure.',
        },
        {
          q: 'Will my credits expire?​',
          a:
            'Credits do not expire and are available until termination of contract or end of tenure.',
        },
        {
          q:
            'Will my employees/colleagues be able to access Common Ground and its services if the company has finished using its credits?',
          a: 'Employees are able to make payment on their own at the CG outlet during check out.',
        },
        {
          q: 'Are there any hidden charges?',
          a:
            'No, credits are only deducted upon usage and you only need to pay for what you utilise. The updated price lists can be found in the Common Ground app.',
        },
        {
          q: 'What happens if we forget to check out upon leaving the CG outlets?',
          a:
            'If you forget to check out before leaving the outlet, you will automatically be checked out at the end of the day’s official operating hours (6PM), and you will only be charged up until 6PM. Do remember to check out every day after usage to ensure accurate billing.',
        },
      ],
    },
    {
      category: 'Common Ground operations',
      items: [
        {
          q: 'When can my employees access Common Ground ​and its services?',
          a:
            'Common Ground and its services are available within official operating hours. Our outlets are open from Monday to Friday, 9AM to 6PM excluding public holidays. Do refer to our list of outlets in the ‘Explore’ tab of the Common Ground App for more info.',
        },
        {
          q: 'Can we visit more than one venue on the same day?',
          a: 'Yes, you can!',
        },
        {
          q: 'Do we get a business address and mail handling services with this package?',
          a:
            'No, not with the Corporate Flex solution. However, special packages are available for you as add-on/an upgrade if you would require a business address and/or mail handling services. Check with our CG crew ​(info@commonground.work) for more information on our packages.',
        },
        {
          q: 'Can we reserve meeting rooms in advance?',
          a: 'Yes, you can via the Common Ground app.',
        },
        {
          q: 'Are we able to check seat availability ahead of time?',
          a:
            'Our list of locations can be found on the Common Ground app under the ‘Explore’ tab. Hot Desks are available based on a first come, first serve basis. Feel free to call in advance to check if they have available Hot Desks for you to work at.',
        },
        {
          q: 'What are the internet speeds in Common Ground?',
          a:
            'Every Common Ground Malaysia outlet is well-equipped with a leased line ​(a premium internet connectivity product) as well as a backup premium broadband line that is exclusive to Common Ground. Each user gets a minimum of 50mbps per IP. Once connected, taking concalls via Zoom, Skype or MS Teams are no issues for our Members.',
        },
        {
          q: 'Can we leave our personal belongings in the outlets?',
          a:
            'Our Corporate Flex Solution does not come with storage options therefore we do not encourage you to leave your belongings in the outlets. We also recommend that you always have your valuable items with you, at all times.',
        },
      ],
    },
  ]

  const [selectedCategory, setSelectedCategory] = useState(categories[0].category)

  return (
    <section id="faq">
      <h3>FAQs</h3>

      <div className="container">
        <div className="category-options">
          {categories.map(({ category }, index) => (
            <div
              className={`category-option ${selectedCategory === category ? 'selected' : ''}`}
              onClick={() => setSelectedCategory(category)}
              key={`category-option-${index}`}
            >
              <p>{category}</p>
            </div>
          ))}
        </div>

        {categories
          .filter(({ category }) => selectedCategory === category)
          .map(({ category, items }) => (
            <div className="category" key={`category-${category}`}>
              {items.map(({ q, a }, index) => (
                <div className="item" key={`category-${category}-item-${index}`}>
                  <p className="q">{q}</p>
                  <p className="a">{a}</p>
                </div>
              ))}
            </div>
          ))}
      </div>

      <style jsx>{`
        #faq {
          background-color: #19423b;
          color: #ffffff;
          padding: 3rem 0;
          min-height: 70vh;

          .category-options {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            margin-bottom: 2rem;

            .category-option {
              font-weight: 800;
              opacity: 0.4;
              cursor: pointer;

              p {
                margin: 0.5rem 0;
              }

              &.selected {
                opacity: 1;
              }
            }
          }

          @media (max-width: 768px) {
            .category-options {
              flex-flow: column nowrap;
            }
          }

          .category {
            text-align: center;

            .title {
              font-weight: 800;
              text-decoration: underline;
            }

            .item {
              margin-bottom: 2rem;

              p {
                margin: 0;
                line-height: 1.2;

                &.q {
                  font-weight: 800;
                }
                &.a {
                }
              }
            }
          }
        }
      `}</style>
    </section>
  )
}
