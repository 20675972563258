import React from 'react'
import styled from 'styled-components'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 2rem;
  background: ${props => props.background || '#fffbf5'};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;

  @media (max-width: 800px) {
    padding: 3rem 1rem;
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;
  max-width: ${props => props.maxWidth || '1000px'};
  margin: auto;

  ${props =>
    props.rowWrap &&
    `
    flex-flow: row wrap;
    align-items: center;

    @media (max-width: 800px) {
      flex-flow: column nowrap;
    }
  `}
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

// Accept {style} object so we have the full option to override styles
const WrappedContainer = ({ background, maxWidth, rowWrap = false, style = {}, children }) => (
  <Wrapper background={background} style={style.wrapper}>
    <Container maxWidth={maxWidth} rowWrap={rowWrap} style={style.container}>
      {children}
    </Container>
  </Wrapper>
)

export default WrappedContainer
