import axios from 'axios'
import querystring from 'querystring'
import { upperCase } from 'lodash'

import { toCamelCase, nest } from 'utils'

const ENVIRONMENTS = {
  PRODUCTION: {
    AMBITION_API_URL: 'https://ambition.commonground.work/v1',
    AMBITION_X_API_KEY: 'OEnRt57ttZ3sUuYZeB5ch13g2nKO28wy3hBRjyE5',
  },

  STAGING: {
    AMBITION_API_URL: 'https://ambition.commonground.work/staging',
    AMBITION_X_API_KEY: 'T8ECdsIW7s4krybzc8Xgh55FgpqHLtzC3hoVLQaA',
  },
}

export const getOfficeLocations = async (options = {}) => {
  try {
    const { env = 'production' } = querystring.parse(window.location.search.substr(1))
    const { AMBITION_API_URL, AMBITION_X_API_KEY } = ENVIRONMENTS[upperCase(env)]

    const response = await axios
      .get(`${AMBITION_API_URL}/venue?private=0&is_physical_location=1`, {
        headers: { 'X-Api-Key': AMBITION_X_API_KEY, 'Content-Type': 'application/json' },
      })
      .then(res => toCamelCase(res.data))

    ////////////////////////////////////////////////////
    // Group by country / states for easier iterating //
    ////////////////////////////////////////////////////
    const countryMap = {
      MY: 'Malaysia',
      TH: 'Thailand',
      PH: 'Philippines',
    }

    response.forEach(item => {
      item.country = countryMap[item.countrySlug]
      item.state = slugToCapitalized(item.stateSlug)
      item.name = item.name.replace('Common Ground ', '')
      item.venueImageUrl = item.venueImageUrl
        ? item.venueImageUrl.replace('/md/', '/lg/')
        : require('./images/missing.png')
    })

    const countries = nest(response, ['country', 'state'])

    return {
      countries: countries,
      offices: response,
    }
  } catch (err) {
    if (err.response.data) {
      throw err.response.data
    }
    throw err
  }
}

const slugToCapitalized = str => {
  try {
    return str
      .replace(/-/g, ' ')
      .replace(/^\w/g, upperCase)
      .replace(/\s\w/g, str => ' ' + upperCase(str))
  } catch (error) {
    return str
  }
}
