/*

If I search for 'Common Ground Jaya One' in Google Maps, I get the URL (with red pin):

 e.g.
 https://www.google.com
 /maps
 /place
 /Common+Ground,+Jaya+One
 /@3.1186853,101.6353362,17z
 /data=!4m5!3m4!1s0x31cc49899c8a2581:0x22e84c32c422c1a3!8m2!3d3.118455!4d101.6356849

There are two co-ordinates in the URL:
1. @3.1186853, 101.6353362, 17z --- the coords of current map position (including drag position)
2. !3d3.118455 !4d101.6356849 --- the coords of red pin

The second type of coordinate is more accurate, but requires particularly troublesome parsing

 */

export const parseGoogleMapsURL = url => {
  try {
    const parts = url.split('/')

    const data = parts[parts.length - 1]
    const coord = {}

    // Extract latitude & longitude
    const m1 = data.match(/(!3d)([0-9.]+)/)
    const m2 = data.match(/(!4d)([0-9.]+)/)

    coord.lat = m1[0] && parseFloat(m1[0].replace('!3d', ''))
    coord.lng = m2[0] && parseFloat(m2[0].replace('!4d', ''))

    // Extract place name
    const m3 = url.match(/\/place\/.+?\//)

    coord.place = m3 && m3[0] && m3[0].replace('/place/', '')

    if (!coord.lat || !coord.lng || !coord.place) {
      throw new Error('Error parsing provided URL')
    }

    return coord
  } catch (err) {
    console.error(err)
    return err
  }
}
