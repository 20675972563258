import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'

import LocationsListItem from 'components/Locations/LocationsListItem'
import WrappedContainer from 'components/Common/WrappedContainer'
import { H1, H3, P } from 'components/Common/Typography'

import LocationSelectDropdown from 'components/Locations/LocationSelectDropdown'

import { slugify } from 'utils'

///////////////////////////////////////////////////////////////////////////////
// Constants

const gutterWidth = '1rem'

///////////////////////////////////////////////////////////////////////////////
// Styling

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 0 -${gutterWidth};
  width: calc(100% + 2rem);
  margin-top: 80px; // account for fixed Heading

  > * {
    margin-left: ${gutterWidth};
    margin-right: ${gutterWidth};
  }

  @media (max-width: 800px) {
    margin-top: 0;
  }
`

const Heading = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(66% - 4rem);
  top: 65px; // Account for navbar
  z-index: 1;
  background: #fffbf5;
  padding: 1rem 0;
  margin: 0 2rem;
  border-bottom: 1px solid rgba(100, 100, 100, 0.1);

  ${H1} {
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    position: relative;
    width: 100%;
    justify-content: center;
    top: 0;
    margin: 2rem auto;
    background: transparent;
    border: none;

    ${H1} {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2rem;
      text-align: center;
    }
  }
`

const NoData = styled.div`
  width: 100%;
  min-height: 420px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  @media (max-width: 800px) {
    min-height: 300px;
  }
`

const ViewAll = styled(P)`
  margin: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #000000;
  cursor: pointer;
  transition: 0.2s ease all;

  :hover {
    background: #000000;
    color: #ffffff;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class LocationsList extends React.Component {
  state = {
    list: [],
    filterOptions: [],
    selectedFilter: null,
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  async componentDidMount() {
    await this.generateFilters()
    await this.updateFilterFromUrl()
  }

  async componentDidUpdate(prevProps) {
    try {
      if (prevProps.match !== this.props.match) {
        this.updateFilterFromUrl()
      }
    } catch (err) {
      console.error(err)
    }
  }

  ///////////////
  // Functions //
  ///////////////

  // Due to the structure of the data from API, we have to awkardly 'destructure' it
  // in order to determine what filters are available
  // todo: make this less traumatizing

  generateFilters() {
    const { offices } = this.props

    const countryFilters = []
    const cityFilters = []

    // Create filter hierarchy from the list of all offices
    for (let office of offices) {
      const country = office.city.country.name
      const city = office.city.name

      const countryFilterExists = countryFilters.find(filter => filter.label === country)
      if (!countryFilterExists) {
        countryFilters.push({
          type: 'country',
          label: country,
          value: slugify(`/locations/${country}`),
        })
      }

      const cityFilterExists = cityFilters.find(filter => filter.label === city)
      if (!cityFilterExists) {
        cityFilters.push({
          type: 'city',
          country,
          label: city,
          value: slugify(`/locations/${country}/${city}`),
          count: 1,
        })
      } else {
        cityFilterExists.count++
      }
    }

    // Flatten the filters into single-level array so the Select component
    // can parse it appropriately
    const filterOptions = [
      {
        type: 'all',
        label: 'All',
        value: `/locations`,
      },
    ]

    for (let countryFilter of countryFilters) {
      filterOptions.push(countryFilter)

      cityFilters
        .filter(cityFilter => cityFilter.country === countryFilter.label)
        .sort((a, b) => b.count - a.count)
        .map(cityFilter => filterOptions.push(cityFilter))
    }

    this.setState({ filterOptions })
  }

  // Parse the current URL to determine if the content should be filtered

  updateFilterFromUrl() {
    try {
      const { params } = this.props.match
      let slug = ''

      if (params.city) {
        slug = `/locations/${params.country}/${params.city}`
      } else if (params.country) {
        slug = `/locations/${params.country}`
      } else {
        slug = `/locations`
      }

      const option = this.state.filterOptions.find(option => option.value === slug)

      if (!option) throw new Error('Unable to filter based on URL')

      this.updateFilter(option)
    } catch (err) {
      console.error(err)
    }
  }

  async updateFilter(newFilter) {
    if (!newFilter || newFilter.value === '/') {
      this.setState({ filter: null })
      return this.props.history.push(`/locations`)
    }

    if (this.props.match.url !== `${newFilter.value}`) {
      await this.props.history.push(`${newFilter.value}`)
    }

    await this.setState({ selectedFilter: newFilter })

    await this.updateList()
  }

  async updateList() {
    const { offices } = this.props
    const { selectedFilter } = this.state

    // The filter is based on slug instead of city/office name because we wanna be able to
    // filter based on country as well, not just specific city
    // e.g. filter value of '/locations/malaysia' will return offices for all cities in Malaysia

    const list = selectedFilter
      ? offices.filter(office => {
          const slug = slugify(`/locations/${office.city.country.name}/${office.city.name}`)
          return slug.includes(selectedFilter.value)
        })
      : offices

    await this.setState({ list })

    // Trigger the Google Maps explorer to rerender with first location
    if (list.length > 0) this.props.onOfficeHover(list[0])
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { list, selectedFilter } = this.state

    return (
      <>
        <WrappedContainer maxWidth="1200px">
          <Heading>
            <H1>LOCATIONS</H1>

            <LocationSelectDropdown
              options={this.state.filterOptions}
              onChange={filter => this.updateFilter(filter)}
              value={selectedFilter}
            />
          </Heading>

          {list.length > 0 ? (
            <List>
              {list.map(office => (
                <LocationsListItem
                  {...office}
                  slug={slugify(
                    `/locations/${office.city.country.name}/${office.city.name}/${office.building}`
                  )}
                  updateFilter={filter => this.updateFilter(filter)}
                  onOfficeHover={office => this.props.onOfficeHover(office)}
                  key={`locations-list-item-${office.building}`}
                />
              ))}
              <LocationsListItem spacefill />
              <LocationsListItem spacefill />
              <LocationsListItem spacefill />
            </List>
          ) : (
            <NoData>
              <H3>No Data Found</H3>
              <ViewAll onClick={() => this.updateFilter(null)}>View All Locations</ViewAll>
            </NoData>
          )}
        </WrappedContainer>
      </>
    )
  }
}

export default withRouter(LocationsList)
