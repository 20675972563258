import React from 'react'
import styled, { css } from 'styled-components'

const modes = props => {
  switch (props.mode) {
    case 'outline':
      return css`
        border-radius: 30px;
        color: ${props.color};
        border: 1px solid ${props.color || '#ffffff'};
      `
    case 'text':
      return css`
        border: none;
        box-shadow: none;
      `
    default:
      return css`
        border: none;
        border-radius: 30px;
        background-color: ${props.color || (props.theme && props.theme.cgYellow) || '#19433a'};
      `
  }
}

/* Standard Button that would mostly be used for onClick events */
const NormalButton = styled.button`
  display: inline-block;
  color: ${props => props.textColor || (props.theme && props.theme.cgGunmetal) || '#000000'};
  background: transparent;
  padding: 1rem 3rem;
  max-width: 100%;
  font-family: Acrom;
  font-weight: bold;
  letter-spacing: 1px;
  box-shadow: ${props => props.shadow && '-2px 2px 6px rgb(0, 0, 0, 0.5)'};
  transition: 0.3s ease all;

  ${props => modes(props)}

  :hover {
    cursor: pointer;
    background-color: #eaaf36;
  }

  :disabled {
    background: #e5c993;
    cursor: default;
  }
`

/* This padding modification is necessary otherwise not the entire button in clickable */
export const LinkButton = styled(NormalButton)`
  padding: 0;

  a {
    display: block;
    text-decoration: none;
    color: ${props => props.textColor || props.color || '#000000'};
    padding: 1rem 3rem;
  }
`

const Button = props => {
  const { mode, href, openInNewTab, disabled, style = {}, children } = props

  return href ? (
    <LinkButton mode={mode} style={style}>
      <a
        href={href}
        target={openInNewTab && '_blank'}
        rel={openInNewTab && 'noopener noreferrer'}
        style={style.a}
      >
        {children}
      </a>
    </LinkButton>
  ) : (
    <NormalButton mode={mode} disabled={disabled} style={style}>
      {children}
    </NormalButton>
  )
}

export default Button
