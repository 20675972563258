import React from 'react'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'

import Pin from './Pin'

import { slugify, parseGoogleMapsURL } from 'utils'

///////////////////////////////////////////////////////////////////////////////
// Constants

// https://developers.google.com/maps/documentation/javascript/reference/#MapOptions
const googleMapOptions = {
  // scrollwheel: false,
  // draggable: false,
  disableDefaultUI: true,
  clickableIcons: false,
}

///////////////////////////////////////////////////////////////////////////////
// Styling

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1) inset;
    top: -16px;
    right: -16px;
    bottom: -16px;
    left: 0;
    pointer-events: none;
  }
`

const MapHeading = styled.div`
  position: absolute;
  top: 2rem;
  z-index: 1;

  font-family: Acrom;
  font-size: 1rem;
  color: #ffffff;
  background: ${props => props.theme.cgGunmetal};
  margin: 0;
  padding: 1rem;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class GoogleMapsExplorer extends React.Component {
  ////////////
  // Render //
  ////////////

  render() {
    const { selectedOffice } = this.props

    if (!selectedOffice) return null

    const officeCenter = parseGoogleMapsURL(selectedOffice.google_maps_url)

    return (
      <MapContainer>
        <MapHeading>
          {selectedOffice.city.country.name.toUpperCase()} /{' '}
          {selectedOffice.city.name.toUpperCase()}
        </MapHeading>

        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
          center={officeCenter}
          zoom={14.5}
          options={googleMapOptions}
        >
          <Pin
            bouncy
            {...officeCenter}
            href={slugify(
              `/locations/${selectedOffice.city.country.name}/${selectedOffice.city.name}/${
                selectedOffice.building
              }`
            )}
            text={selectedOffice.building}
          />
        </GoogleMapReact>
      </MapContainer>
    )
  }
}

export default GoogleMapsExplorer
