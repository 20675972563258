import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import WrappedContainer from 'components/Common/WrappedContainer'
import Layout from 'components/Common/Layout'
import { H1, H5, P } from 'components/Common/Typography'
import { Label, Input } from 'components/Common/Input'
import Button from 'components/Common/Button'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Left = styled.div`
  flex: 1;
  margin-right: 3rem;

  @media (max-width: 800px) {
    min-width: 100%;
    margin: auto auto 2rem auto;
  }
`

const Right = styled.div`
  flex: 1;
`

const Form = styled.form`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

const InputField = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2rem;
  width: calc(100% - 1rem);
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class AgentsPage extends React.Component {
  ///////////////
  // Functions //
  ///////////////

  handleSubmit(e) {
    e.preventDefault && e.preventDefault()

    alert("This doesn't do anything yet!")
  }

  ////////////
  // Render //
  ////////////

  render() {
    return (
      <Layout style={{ background: '#fff5e2' }}>
        <Helmet>
          <title>Agents | Common Ground</title>
          <style>{`
        #navbar.expanded {
          color: #000000;
        }
      `}</style>
        </Helmet>

        <section id="agents" style={{ minHeight: '100vh' }}>
          <WrappedContainer
            background="transparent"
            rowWrap
            style={{ wrapper: { minHeight: 'inherit' } }}
          >
            <Left>
              <H5>PARTNER WITH US</H5>
              <H1>A MUTUALLY BENEFICIAL RELATIONSHIP</H1>
              <P>
                Common Ground is actively looking for agents to partner with. We are redefining work
                spaces around Sotheast Asia and we need driven agents to help us realize our vision.
              </P>
              <P>
                Build a relationship with your clients and when they sign up with Common Ground, you
                get paid!
              </P>
            </Left>

            <Right>
              <Form onSubmit={e => this.handleSubmit(e)}>
                <InputField>
                  <Label>YOUR NAME</Label>
                  <Input required type="text" placeholder="Enter Your Name" />
                </InputField>

                <InputField>
                  <Label>COMPANY NAME</Label>
                  <Input required type="text" placeholder="Enter Company Name" />
                </InputField>

                <InputField>
                  <Label>PHONE NO.</Label>
                  <Input required placeholder="Phone Number" />
                </InputField>

                <InputField>
                  <Label>EMAIL</Label>
                  <Input required type="email" placeholder="Email Address" />
                </InputField>

                <Button style={{ margin: 'auto' }}>SIGN UP</Button>
              </Form>
            </Right>
          </WrappedContainer>
        </section>
      </Layout>
    )
  }
}

export default AgentsPage
