import React from 'react'

import LoadingRing from 'components/Common/Loading'

import { getOfficeLocations } from './api'

import theme from 'theme'

export class LocationsSection extends React.Component {
  state = {
    loading: false,
    countries: {},
    offices: [],

    selectedCountry: 'Malaysia',
    selectedState: 'Kuala Lumpur',
    selectedOffice: {},
  }

  async componentDidMount() {
    try {
      await this.setState({ loading: 'getOfficeLocations' })

      const locations = await getOfficeLocations()

      await this.setState({
        loading: false,
        countries: locations.countries,
        offices: locations.offices,
      })

      this._onSelectCountry('Malaysia')
    } catch (error) {
      await this.setState({ loading: false })
    }
  }

  //
  // ─── METHODS ────────────────────────────────────────────────────────────────────
  //

  _onSelectCountry = async country => {
    const { countries } = this.state

    await this.setState({ selectedCountry: country })
    await this._onSelectState(Object.keys(countries[country])[0])
  }

  _onSelectState = async state => {
    const { countries, selectedCountry } = this.state

    await this.setState({ selectedState: state })
    await this._onSelectOffice(countries[selectedCountry][state][0])
  }

  _onSelectOffice = async office => {
    await this.setState({ selectedOffice: office })
  }

  //
  // ─── RENDER ─────────────────────────────────────────────────────────────────────
  //

  render() {
    const { loading, offices } = this.state

    return (
      <section id="locations">
        <div className="container">
          <div className="row justify-content-center mb-2">
            <h1 className="m-0 text-center">Your new workspace locations</h1>
          </div>

          <div className="row justify-content-center mb-4">
            <h2 className="m-0 text-center">All the Common Ground locations you have access to.</h2>
          </div>
        </div>

        {!!loading
          ? this._renderLoading()
          : offices.length === 0
          ? this._renderEmpty()
          : this._renderLocations()}

        <style jsx>{`
          #locations {
            padding: 0 0 4rem 0;
          }
        `}</style>
      </section>
    )
  }

  _renderLoading = () => {
    return (
      <div className="loading">
        <LoadingRing color={theme.cgGreen} />

        <style jsx>{`
          .loading {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 60vh;
            background-color: ${theme.cgYellow};
            margin-top: calc(79px + 1rem); // Account for country/state tabs
          }
        `}</style>
      </div>
    )
  }

  _renderEmpty = () => {
    return (
      <div className="empty">
        <img src={require('./images/amenities2.jpg')} alt="Fallback" />

        <a href="https://commonground.work/locations" target="_blank" rel="noopener noreferrer">
          View Our Locations
        </a>

        <style jsx>{`
          .empty {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 33vh;
            background-color: #000000;
            overflow: hidden;

            a {
              position: relative;
              display: inline-block;
              z-index: 100;
              color: #ffffff;
              font-weight: 800;
              font-size: 2rem;
              padding: 3rem;
            }

            img {
              position: absolute;
              opacity: 0.5;
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        `}</style>
      </div>
    )
  }

  _renderLocations = () => {
    const { countries, offices, selectedCountry, selectedState, selectedOffice } = this.state

    const filtered = offices.filter(
      office => office.country === selectedCountry && office.state === selectedState
    )

    return (
      <>
        <div className="container">
          <div className="row countries">
            {Object.keys(countries).map(country => (
              <div
                className={`country ${country === selectedCountry && 'active'}`}
                onClick={() => this._onSelectCountry(country)}
                key={`country-${country}`}
              >
                {country}
              </div>
            ))}
          </div>

          <div className="row states">
            {countries[selectedCountry] &&
              Object.keys(countries[selectedCountry]).map(state => (
                <div
                  className={`state ${state === selectedState && 'active'}`}
                  onClick={() => this._onSelectState(state)}
                  key={`state-${state}`}
                >
                  {state}
                </div>
              ))}
          </div>
        </div>

        <div className="row no-gutters content">
          <div className="col-12 col-md-8">
            <div className="office-image">
              <img src={selectedOffice.venueImageUrl} alt={selectedOffice.place} />
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="office-list">
              {filtered.map((office, index) => {
                let cls = ''
                if (selectedOffice.id === office.id) cls += 'active '
                if (index === filtered.length - 1) cls += 'last '

                return (
                  <div
                    className={`office ${cls}`}
                    onClick={() => this._onSelectOffice(office)}
                    key={`office-${index}`}
                  >
                    <div className="title">{office.place}</div>
                    <div className="overview">{office.overview}</div>
                    {!office.isAvailable && (
                      <div className="unavailable">Temporarily unavailable during CMCO</div>
                    )}
                    <div className="url">
                      <a href={office.venueUrl} target="_blank" rel="noopener noreferrer">
                        Find out more
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <style jsx>{`
          .countries {
            justify-content: center;
            margin-bottom: 1rem;
            font-size: 1.3rem;

            .country {
              padding: 0.5rem 2rem;
              border-bottom: 1px solid #111111;
              opacity: 0.6;
              cursor: pointer;

              &.active {
                border-bottom: 2px solid #111111;
                opacity: 1;
              }
            }
          }

          .states {
            justify-content: center;
            margin-bottom: 1rem;

            .state {
              padding: 0.25rem 1rem;
              cursor: pointer;

              &.active {
                color: ${theme.cgYellow};
              }
            }
          }

          .content {
            min-height: 60vh;
            background-color: ${theme.cgYellow};

            .office-image {
              height: 100%;
              min-height: 200px;

              img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                background-color: rgba(255, 255, 255, 0.5);
              }
            }

            .office-list {
              padding: 1rem 2rem;

              .office {
                padding: 0.8rem;
                color: #a78038;
                border-bottom: 1px solid #a78038;
                cursor: pointer;

                .title {
                  font-size: 1.3rem;
                  font-weight: 800;
                }

                .overview {
                  display: none;
                  margin-top: 1rem;
                  margin-bottom: 1rem;
                }

                .unavailable {
                  display: none;
                  margin-bottom: 1rem;
                  color: red;
                }

                .url {
                  display: none;
                  margin-bottom: 1rem;

                  a {
                    display: inline-block;
                    padding: 0.75rem 1rem;
                    text-decoration: none;
                    color: ${theme.cgYellow};
                    background: ${theme.cgGreen};
                  }
                }

                &.active {
                  color: ${theme.cgGreen};

                  .overview,
                  .unavailable,
                  .url {
                    display: block;
                  }
                }

                &.last {
                  border-bottom: none;
                }
              }
            }
          }
        `}</style>
      </>
    )
  }
}

export default LocationsSection
