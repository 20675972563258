import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { isMobile } from 'react-device-detect'
import { IoIosCalendar } from 'react-icons/io'

import { Input } from './index'

// DatePicker is just a wrapper around react-datepicker to:
// 1. Initialize with some standard values and settings
// 2. Include a hidden <input> which contains the selected value (makes it easier for native forms)
// 3. Include a calendar icon w00t

class DatePicker extends React.Component {
  constructor() {
    super()

    // Initialize time and date values
    let minDate = new Date()
    let minTime = new Date().setHours(9, 0)
    let maxTime = new Date().setHours(18, 0)

    // If the current time is past the max allowed time, then don't
    // allow user to select today from DatePicker
    if (minDate.getHours() > new Date(maxTime).getHours()) {
      minDate = new Date(minDate).setDate(minDate.getDate() + 1)
    }

    this.state = {
      minDate: new Date(minDate),
      minTime: new Date(minTime),
      maxTime: new Date(maxTime),
      timeIntervals: 15, // Allow time selections in 15 minute increments
      selectedDate: null,
    }
  }

  ///////////////
  // Functions //
  ///////////////

  selectDate(date) {
    const { minTime, maxTime } = this.state

    // Don't allow hour selections outside the min/max time
    if (date.getHours() < minTime.getHours()) {
      date.setHours(minTime.getHours())
    }

    if (date.getHours() > maxTime.getHours()) {
      date.setHours(maxTime.getHours())
    }

    this.setState({ selectedDate: date })

    // Callback
    this.props.onChange && this.props.onChange(date)
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { required = true, name = 'selected_date', customInput = null, options } = this.props
    const { minDate, minTime, maxTime, timeIntervals, selectedDate } = this.state

    return (
      <>
        <ReactDatePicker
          selected={selectedDate}
          onChange={date => this.selectDate(date)}
          showTimeSelect
          minDate={minDate}
          minTime={minTime}
          maxTime={maxTime}
          timeIntervals={timeIntervals}
          dateFormat="dd/MM/yy h:mm aa"
          placeholderText="Pick A Time"
          className="date-picker"
          customInput={
            customInput || (
              // Padding right to account for the Calendar icon
              <Input style={{ cursor: 'default', paddingRight: '40px' }} />
            )
          }
          ref={el => el && el.input && (el.input.readOnly = true)} // Prevent user manual input / mobile keyboard
          withPortal={isMobile ? true : false}
          {...options}
        />

        {/* This 'hidden input field' is here so that any form using this component can
          easily access the value like any other input field. It doesn't use the 'hidden'
          prop because that prevents form validation */}
        <div style={{ position: 'relative' }}>
          <input
            required={required}
            name={name}
            onChange={() => true} // Add an onChange to suppress input warnings
            value={(selectedDate && selectedDate.toISOString && selectedDate.toISOString()) || ''}
            type="text"
            tabIndex="-99"
            autoComplete="new-password"
            onFocus={e => e.target.blur()} // Prevent keyboard popup on mobile if validation failed
            style={{
              height: '1px',
              marginTop: '-1px',
              border: 'none',
              padding: 0,
              background: 'transparent',
              position: 'absolute',
              bottom: 0,
              width: '100%',
              color: 'transparent',
              pointerEvents: 'none',
            }}
          />

          <IoIosCalendar
            style={{
              position: 'absolute',
              right: '9px',
              bottom: '5px',
              width: '22px',
              height: '22px',
              color: '#7c7c7c',
              pointerEvents: 'none',
            }}
          />
        </div>
      </>
    )
  }
}

export default DatePicker
