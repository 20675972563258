const mappings = [
  {
    code: 'CGDH',
    name: 'Common Ground Damansara Heights',
    country: 'MY',
  },
  {
    code: 'CGMK',
    name: 'Common Ground Mont Kiara',
    country: 'MY',
  },
  {
    code: 'CGTTDI',
    name: 'Common Ground TTDI',
    country: 'MY',
  },
  {
    code: 'CGBB',
    name: 'Common Ground Bukit Bintang',
    country: 'MY',
  },
  {
    code: 'CGAD',
    name: 'Common Ground Ara Damansara',
    country: 'MY',
  },
  {
    code: 'CGQS',
    name: 'Common Ground Q Sentral',
    country: 'MY',
  },
  {
    code: 'CGAP',
    name: 'Common Ground Ampang',
    country: 'MY',
  },
  {
    code: 'CGMV',
    name: 'Common Ground KL Eco City',
    country: 'MY',
  },
  {
    code: 'CGPGM',
    name: 'Common Ground Penang',
    country: 'MY',
  },
  {
    code: 'CGMD',
    name: 'Common Ground Mutiara Damansara',
    country: 'MY',
  },
  {
    code: 'CGPJ',
    name: 'Common Ground Petaling Jaya',
    country: 'MY',
  },
  {
    code: 'CGUEME',
    name: 'Common Ground UEM Edgenta',
    country: 'MY',
  },
  {
    code: 'CGBS',
    name: 'Common Ground Bangsar South',
    country: 'MY',
  },
  {
    code: 'CGGT',
    name: 'Common Ground G Tower',
    country: 'TH',
  },
  {
    code: 'CGCW',
    name: 'Common Ground Central World',
    country: 'TH',
  },
  {
    code: 'CGRW',
    name: 'Common Ground 8 Rockwell',
    country: 'PH',
  },
  {
    code: 'CGIBP',
    name: 'Common Ground IBP Tower',
    country: 'PH',
  },
  {
    code: 'CGACPT',
    name: 'Common Ground Arthaland Century Pacific Tower',
    country: 'PH',
  },
]

export default mappings
