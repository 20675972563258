import React from 'react'
import styled from 'styled-components'

import { P, H3 } from 'components/Common/Typography'
import Button from 'components/Common/Button'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Container = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex: 1;
  max-width: 50%;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2vw;
  padding: 0 0 2rem 0;

  @media (max-width: 800px) {
    max-width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 0 2rem 0;
  }
`

//// IMAGE ////
const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 2rem 0;
  height: auto;
  padding-bottom: 80%;
  overflow: hidden;
  line-height: 0;

  @media (max-width: 800px) {
    width: calc(100%);
    margin: 0 0 1rem 0;
    padding-bottom: 50%;
  }
`

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
`

//// DETAILS ////

const Details = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;

  @media (max-width: 800px) {
    padding: 0.5rem;
  }
`

const Type = styled(H3)``

const Pricing = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin-top: auto;
  margin-bottom: 2rem;
  align-self: center;
`

const Price = styled.div`
  font-weight: lighter;
  font-size: 4rem;
  line-height: 4rem;
  margin: 0 0.3rem 0 0.6rem;
`

const From = styled.div``

const Currency = styled.div``

const PerMonth = styled.div`
  align-self: flex-end;
  max-width: 62px;
`

const Description = styled.div`
  margin-bottom: 1rem;
`

const Checkmark = styled.div`
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 3px;

  :after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid #000;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
  }
`

const Perks = styled.div`
  margin-bottom: 1rem;

  @media (max-width: 800px) {
    display: none; // Disabling for now because it improved UX
    text-align: left;
  }
`

const Perk = styled.div`
  margin-bottom: 1rem;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Package = props => (
  <Container>
    <ImageContainer>
      <a href={props.url}>
        <Image src={props.image} />
      </a>
    </ImageContainer>

    <Details>
      <Type>{`${props.name}`.toUpperCase()}</Type>

      {props.description && (
        <Description>
          <P>{props.description}</P>
        </Description>
      )}

      {props.perks && (
        <Perks>
          {props.perks.map(perk => (
            <Perk key={`package-${props.type}-perk-${perk}`}>
              <Checkmark /> {perk}
            </Perk>
          ))}
        </Perks>
      )}

      <Pricing>
        <From>
          FROM
          <Currency>{props.currency}</Currency>
        </From>

        <Price>{props.price}</Price>

        <PerMonth>/ {`${props.cycle}`.toUpperCase()}</PerMonth>
      </Pricing>

      <Button href={props.url} style={{ alignSelf: 'center' }}>
        LEARN MORE
      </Button>
    </Details>
  </Container>
)

export default Package
