import React, { Component } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import TagManager from 'react-gtm-module'
import Modal from 'react-modal'
import ReactPixel from 'react-facebook-pixel'

import './assets/css/bootstrap-grid.css'
import './assets/css/bootstrap-spacing.css'
import './assets/css/react-tags.css'
import './App.scss'
import theme from 'theme'

import Home from 'views/home'
import LocationsPage from 'views/locations'
import OfficePage from 'views/locations/office'
import OfficePageMobile from 'views/locations/office.m'
import PackagesPage from 'views/packages'
import AgentsPage from 'views/agents'
import EnterprisePage from 'views/enterprise'

// import ThankYouPage from 'views/promos/thank-you' // added 29-01-2021
// import PromosAirasiaPage from 'views/promos/airasia-mycorporate'
// import PromosShortTermOfficePage from 'views/promos/anniversary/PromosShortTermOffice'
// import PromosGetYourBestWorkDonePage from 'views/promos/anniversary/PromosGetYourBestWorkDone'
// import PromosFixedDeskPage from 'views/promos/anniversary/PromosFixedDesk'
// import PromosCorporateFlexSolutionPage from 'views/promos/anniversary/PromosCorporateFlexSolution'
// import PromosVirtualOfficePage from 'views/promos/anniversary/PromosVirtualOffice'
// import PromosRamadhanCorporateFlexSolutionPage from 'views/promos/ramadhan/PromosRamadhanCorporateFlexSolutionPage'
// import PromosRamadhanVirtualOfficePage from 'views/promos/ramadhan/PromosRamadhanVirtualOfficePage'
// import PromosMoveInMoveUpPage from 'views/promos/move-in-move-up'
// import PromosPenangPage from 'views/promos/penang'
import PromosKakitanganPage from 'views/promos/kakitangan/PromosKakitangan'
// import PromosNewYearPage from 'views/promos/new-year'
// import PromosNewYearFixedDesk from 'views/promos/new-year/PromosNewYearFixedDesk'
// import PromosNewYearPrivateOffice from 'views/promos/new-year/PromosNewYearPrivateOffice'
// import PromosNewVenuesPage from 'views/promos/new-venues'
// import PromosZaloraPage from 'views/promos/zalora'
// import PromosBcpPage from 'views/promos/bcp'
// import PromosBInfinitePage from 'views/promos/b-infinite'

// import DigitalSmePage from 'views/promos/digitalsme' // added 20-05-2020
// import AuriPage from 'views/promos/auri' // added 17-06-2020
// import RecoveryPromotionPage from 'views/promos/recovery-promotion' // added 10-08-2020
// import FreebiesVOPromotionPage from 'views/promos/freebies-vo' // added 21-10-2020
// import FreebiesMembershipPromotionPage from 'views/promos/freebies-membership' // added 23-10-2020
import PhotoContestPage from 'views/promos/photo-contest' // added 26-10-2020
// import CnyPromotionPage from 'views/promos/cny' // added 25-01-2021
import FlexPromotionPage from 'views/promos/flex' // added 26-02-2021
import FlexOnboardingPage from 'views/promos/flex-onboarding' // added 26-02-2021

// Ideally CG Clerks should exist as a separate whitelabel website, but due to time/
// resource constrants, we are "hosting" it under this project
import PromosClerksPage from 'views/promos/clerks'
import PromosClerksThankYouPage from 'views/promos/clerks/thank-you'

import PromosClerksStagingPage from 'views/promos/clerks-staging'
import PromosClerksStagingThankYouPage from 'views/promos/clerks-staging/thank-you'
import WordPressRedirect from 'views/promos/wordpress-redirect' // added 17-05-2022

import Covid19HotlinePage from 'views/promos/covid19hotline' // added 08-04-2020
import Covid19CheckInPage from 'views/promos/covid19checkin' // added 13-05-2020
import Covid19CheckInPagePH from 'views/promos/covid19checkin/ph' // added 08-10-2020

import FaqPage from 'views/faq'
import TermsAndConditionsPage from 'views/terms-and-conditions'
import TermsAndConditionsMobilePage from 'views/terms-and-conditions.m'
import PrivacyPage from 'views/privacy'
import MissingPage from 'views/missing'
import Error404 from 'components/Error404'

///////////////////////////////////////////////////////////////////////////////
// Initializations

// Google tag manager
const { REACT_APP_GTM_ID } = process.env

if (REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: REACT_APP_GTM_ID,
  })
}

// Facebook pixel code for tracking page
const options = {
  autoConfig: true,
  debug: false,
}
ReactPixel.init(1537304689726247, null, options)

ReactPixel.pageView()

// Modal provider
Modal.setAppElement('#root')

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class App extends Component {
  ///////////////
  // Functions //
  ///////////////

  // Helper that is used to "forward" url search query for Redirects

  withUrlSearchQuery = pathname => {
    return { pathname: pathname, search: window.location.search }
  }

  // We conditionally render different routes depending on the URL subdomain.
  // This is because (at the time of writing this) we want specific subdomains to have
  // limited access to the front end.

  getSubdomainRoutes(subdomain) {
    switch (subdomain) {
      /////////////////////////////////////////////////////////
      // Specific path handling for promos.commonground.work //
      /////////////////////////////////////////////////////////
      case 'promos':
        /* Redirect all the promo pages to new wordpress site */
        return (
          <Switch>
            {/* PROMO KAKITANGAN ROUTES (WHITELIST)*/}
            <Route
              exact
              path="/big-wins-for-sme-memberships-kakitangan"
              component={PromosKakitanganPage}
            />
            {/* FLEX PROMOTION ROUTES (WHITELIST)*/}
            <Route exact path="/flex" component={FlexPromotionPage} />
            <Route exact path="/flex/onboarding" component={FlexOnboardingPage} />
            {/* LEADERBOARD ROUTES (WHITELIST)*/}
            <Redirect from="/leaderboard" to={this.withUrlSearchQuery('/name-the-pic')} />
            <Route exact path="/name-the-pic" component={PhotoContestPage} />
            {/* CG CLERKS (WHITELIST)*/}
            <Route exact path="/clerks" component={PromosClerksPage} />
            <Route exact path="/clerks/thank-you" component={PromosClerksThankYouPage} />
            <Route exact path="/clerks-staging" component={PromosClerksStagingPage} />
            <Route
              exact
              path="/clerks-staging/thank-you"
              component={PromosClerksStagingThankYouPage}
            />
            {/* PROMO RELATED ROUTES */}
            <Route component={WordPressRedirect} />
          </Switch>
        )
      // return (
      //   <Switch>
      //     <Redirect from="/airasiamycorporate" to="/airasia-mycorporate" />
      //     <Route exact path="/airasia-mycorporate" component={PromosAirasiaPage} />

      //     {/* PROMO ANNIVERSARY ROUTES */}
      //     <Route
      //       exact
      //       path="/5th-anniversary-short-term-office"
      //       component={PromosShortTermOfficePage}
      //     />
      //     <Route
      //       exact
      //       path="/5th-anniversary-get-your-best-work-done-coworking-packages"
      //       component={PromosGetYourBestWorkDonePage}
      //     />
      //     <Route exact path="/5th-anniversary-fixed-desk" component={PromosFixedDeskPage} />
      //     <Route exact path="/5th-anniversary-cfs" component={PromosCorporateFlexSolutionPage} />
      //     <Route
      //       exact
      //       path="/5th-anniversary-virtual-office"
      //       component={PromosVirtualOfficePage}
      //     />

      //     {/* PROMO RAMADHAN ROUTES */}
      //     <Route exact path="/ramadhan-cfs" component={PromosRamadhanCorporateFlexSolutionPage} />
      //     <Route
      //       exact
      //       path="/ramadhan-virtual-office"
      //       component={PromosRamadhanVirtualOfficePage}
      //     />

      //     <Redirect from="/movin" to="/move-in" />
      //     <Redirect from="/movein" to="/move-in" />
      //     <Route exact path="/move-in" component={PromosMoveInMoveUpPage} />
      //     <Route exact path="/penang" component={PromosPenangPage} />

      //     {/* <Redirect from="/promos/newyear" to="/promos/new-year/private-office" />
      //     <Redirect from="/promos/newyears" to="/promos/new-year/private-office" /> */}
      //     <Redirect from="/newyear/privateoffice" to="/new-year/private-office" />
      //     <Route exact path="/new-year/private-office" component={PromosNewYearPrivateOffice} />

      //     <Redirect from="/newyear/fixeddesk" to="/new-year/fixed-desk" />
      //     <Route from="/new-year/fixed-desk" component={PromosNewYearFixedDesk} />
      //     <Redirect from="/newvenues" to="/new-venues" />
      //     <Route exact path="/new-venues" component={PromosNewVenuesPage} />
      //     <Redirect from="/zalora" to="/commongroundxzalora" />
      //     <Route exact path="/commongroundxzalora" component={PromosZaloraPage} />
      //     <Route exact path="/bcp" component={PromosBcpPage} />
      //     <Route exact path="/b-infinite" component={PromosBInfinitePage} />
      //     <Route exact path="/covid19hotline" component={Covid19HotlinePage} />
      //     {/* FREEBIES PROMO */}
      //     {/* <Redirect from="/vo" to="/virtual-office" />
      //     <Route exact path="/virtual-office" component={FreebiesVOPromotionPage} />
      //     <Route exact path="/membership" component={FreebiesMembershipPromotionPage} /> */}
      //     {/* NEXEA DIGITAL SME */}
      //     <Redirect exact from="/digitalsme" to="/digitalsme/voucher/signup" />
      //     <Route exact path="/digitalsme/voucher/signup" component={DigitalSmePage} />
      //     {/* AURI X CG MASKS */}
      //     <Route exact path="/auri" component={AuriPage} />
      //     {/* RECOVERY PROMOTION */}
      //     <Route exact path="/recoverypromotion" component={RecoveryPromotionPage} />
      //     {/* CNY PROMOTION */}
      //     <Route exact path="/cny2022" component={CnyPromotionPage} />
      //     {/* THANK YOU PAGE */}
      //     <Route path="/:promo/thank-you" component={ThankYouPage} />
      //     <Route
      //       component={() => (
      //         <div style={{ display: 'flex', height: '100vh' }}>
      //           <Error404 noLinks />
      //         </div>
      //       )}
      //     />
      //   </Switch>
      // )

      //////////////////////////////////////////////////////////
      // Specific path handling for covid19.commonground.work //
      //////////////////////////////////////////////////////////
      case 'covid19':
        return (
          <Switch>
            <Route exact path="/" component={Covid19HotlinePage} />

            <Redirect from="/checking" to="/checkin" />
            <Redirect from="/check-in" to="/checkin" />
            <Route exact path="/checkin" component={Covid19CheckInPage} />

            <Redirect from="/checking/ph" to="/checkin/ph" />
            <Redirect from="/check-in/ph" to="/checkin/ph" />
            <Route exact path="/checkin/ph" component={Covid19CheckInPagePH} />

            <Route
              component={() => (
                <div style={{ display: 'flex', height: '100vh' }}>
                  <Error404 noLinks />
                </div>
              )}
            />
          </Switch>
        )

      /////////////////////////////////////////////////
      // Default path handling for commonground.work //
      /////////////////////////////////////////////////
      default:
        return (
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/m/offices/:office" component={OfficePageMobile} />
            <Route exact path="/m/terms-and-conditions" component={TermsAndConditionsMobilePage} />
            <Route exact path="/locations" component={LocationsPage} />
            <Route exact path="/locations/:country" component={LocationsPage} />
            <Route exact path="/locations/:country/:city" component={LocationsPage} />
            <Route exact path="/locations/:country/:city/:office" component={OfficePage} />
            {/* PACKAGE RELATED ROUTES */}
            <Route exact path="/packages" component={PackagesPage} />
            <Route exact path="/packages/:package" component={PackagesPage} />
            {/* PROMO RELATED ROUTES (WHITELIST) */}
            <Route
              exact
              path="/promos/big-wins-for-sme-memberships-kakitangan"
              component={PromosKakitanganPage}
            />
            <Route exact path="/promos/flex" component={FlexPromotionPage} />
            <Route exact path="/promos/flex/onboarding" component={FlexOnboardingPage} />
            <Redirect from="/promos/leaderboard" to={this.withUrlSearchQuery('/name-the-pic')} />
            <Route exact path="/promos/name-the-pic" component={PhotoContestPage} />
            <Route exact path="/promos/clerks" component={PromosClerksPage} />
            <Route exact path="/promos/clerks/thank-you" component={PromosClerksThankYouPage} />
            <Route exact path="/promos/clerks-staging" component={PromosClerksStagingPage} />
            <Route
              exact
              path="/promos/clerks-staging/thank-you"
              component={PromosClerksStagingThankYouPage}
            />
            {/* Redirect all the promo pages to new wordpress site */}
            <Route exact path="/promos/*" component={WordPressRedirect} />
            {/* COVID 19 CHECK IN */}
            <Redirect from="/covid19/checking" to="/covid19/checkin" />
            <Redirect from="/covid19/check-in" to="/covid19/checkin" />
            <Route exact path="/covid19/checkin" component={Covid19CheckInPage} />
            <Redirect from="/covid19/checking/ph" to="/covid19/checkin/ph" />
            <Redirect from="/covid19/check-in/ph" to="/covid19/checkin/ph" />
            <Route exact path="/covid19/checkin/ph" component={Covid19CheckInPagePH} />
            {/* MISC ROUTES */}
            <Route exact path="/agents" component={AgentsPage} />
            <Route exact path="/enterprise" component={EnterprisePage} />
            <Route exact path="/faq" component={FaqPage} />
            <Route exact path="/terms-and-conditions" component={TermsAndConditionsPage} />
            <Route exact path="/privacy" component={PrivacyPage} />
            <Route component={MissingPage} />
          </Switch>
        )
    }
  }

  ////////////
  // Render //
  ////////////
  render() {
    const { hostname } = window.location
    const uris = hostname.split('.')
    let subdomain = uris[0]

    // If the URL is in dev environment, we care about the uri *after* dev.
    // e.g. dev.promos.commonground.work -- we care about the 'promos' part
    if (subdomain === 'dev') subdomain = uris[1]

    const routes = this.getSubdomainRoutes(subdomain)

    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>{routes}</BrowserRouter>
      </ThemeProvider>
    )
  }
}

export default App
