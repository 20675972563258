import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import Benefits from 'components/Benefits'
import Tenant from 'components/Tenant'

import WrappedContainer from 'components/Common/WrappedContainer'
import Layout from 'components/Common/Layout'
import { H1, H2, H3, H5, P } from 'components/Common/Typography'

///////////////////////////////////////////////////////////////////////////////
// Styling

const LeftBlock = styled.div`
  flex: 1;
  margin-top: 100px;
  margin-right: 3rem;

  @media (max-width: 800px) {
    min-width: 100%;
    margin: auto auto 2rem auto;
  }
`

//// INTRO ////

const Intro = styled.div`
  flex: 1;
  position: relative;
  max-width: 560px;
`

const IntroGraphic = styled.div`
  flex: 1;
  margin-right: -3rem;

  img {
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-right: unset;
  }
`

//// SERVICES  /////

const Services = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  margin: 0 -1rem;

  @media (max-width: 800px) {
    flex-flow: column nowrap;
    margin: 0 auto;
  }
`

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-right: 2rem;
  margin-left: -2rem;

  @media (max-width: 800px) {
    order: 2;
    margin: 2rem 0 0 0;
  }
`

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
`

const Service = styled.div`
  width: 400px;
  max-width: 100%;
  margin: 1rem;
  margin-left: ${props => props.offset};

  @media (max-width: 800px) {
    margin-left: unset;
  }
`

const Image = styled.div`
  position: relative;
  height: 0;
  margin: 0.5vw;
  margin-top: ${props => props.offset};
  flex: 1;
  padding-bottom: 66%;

  img {
    max-width: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 800px) {
    margin-top: unset;
  }
`

//// BENEFITS ////

const BenefitsDescription = styled.div`
  ${P} {
    max-width: 800px;
  }
`

//// TENANTS ////

const Tenants = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class EnterprisePage extends React.Component {
  ////////////
  // Render //
  ////////////

  render() {
    return (
      <Layout style={{ background: '#fff5e2' }}>
        <Helmet>
          <title>Enterprise Solutions | Common Ground</title>
          <style>{`
            #navbar.expanded {
              color: #000000;
            }
          `}</style>
        </Helmet>

        <section id="header">
          <WrappedContainer
            background="transparent"
            style={{
              wrapper: { minHeight: '50vh' },
              container: { flexFlow: 'row wrap', alignItems: 'center' },
            }}
          >
            <LeftBlock>
              <H5>POWER YOUR AMBITIONS</H5>
              <H1 style={{ marginBottom: '0.5rem' }}>ENTERPRISE SOLUTIONS</H1>
              <P style={{ marginBottom: '0' }}>Build the perfect office for your company</P>
            </LeftBlock>
          </WrappedContainer>
        </section>

        <section id="intro">
          <WrappedContainer maxWidth="1000px" rowWrap>
            <Intro>
              <H3>GROWING YOUR COMPANY?</H3>
              <H3>LOOKING TO BUILD THE HQ OF YOUR DREAMS?</H3>

              <H3>
                <span style={{ color: '#faca66' }}>COMMON GROUND</span> CAN HELP.
              </H3>

              <P>
                Create the ideal office space for your enterprise, tailored and customized to fit
                your exact business needs. Our enterprise solutions are designed to power large
                teams of 50 or more, helping companies save on office space costs and providing
                flexibility in a fast-changing business world.
              </P>
            </Intro>
            <IntroGraphic>
              <img src="/images/enterprise/temp.png" alt="Grow with CG" />
            </IntroGraphic>
          </WrappedContainer>
        </section>

        <section id="services">
          <WrappedContainer maxWidth="1000px" rowWrap>
            <H2>CATERED TO YOUR NEEDS</H2>

            <Services>
              <Left>
                <Image>
                  <img src="images/enterprise/move-in-ready.jpg" alt="Move in Ready" />
                </Image>
                <Image offset="4rem">
                  <img src="images/enterprise/custom-buildouts.jpg" alt="Custom Buildouts" />
                </Image>
                <Image offset="8rem">
                  <img src="images/enterprise/powered-by.jpg" alt="Powered By CG" />
                </Image>
              </Left>
              <Right>
                <Service>
                  <H3>MOVE-IN READY SERVICED OFFICES</H3>
                  <P>
                    Gain flexibility to expand and change locations as your team grows, without a
                    long-term contract that impacts your cash flow.
                  </P>
                </Service>

                <Service offset="4rem">
                  <H3>CUSTOM BUILDOUTS</H3>
                  <P>Save costs on renovation and your employees.</P>
                </Service>

                <Service offset="8rem">
                  <H3>POWERED BY COMMON GROUND</H3>
                  <P>
                    Let our experts take care of the office, so you and your team can focus on doing
                    your best work.
                  </P>
                </Service>
              </Right>
            </Services>
          </WrappedContainer>
        </section>

        <section id="benefits">
          <WrappedContainer maxWidth="1000px">
            <BenefitsDescription>
              <H2>GET MORE FROM YOUR OFFICE SPACE</H2>
              <P>
                At Common Ground, we believe the office should be more than just a place to work.
              </P>
              <P>
                As a member, you'll become part of a wider network of businesses from a diverse
                range of industries and can collaborate with our partners and community.
              </P>
              <P>
                You and your employees will also enjoy these additional amenities and support
                services.
              </P>
            </BenefitsDescription>

            <Benefits />
          </WrappedContainer>
        </section>

        <section id="partners">
          <WrappedContainer maxWidth="1000px">
            <H2>OUR FRIENDS</H2>

            <Tenants>
              {EXAMPLE_DATA.map((tenant, index) => (
                <Tenant {...tenant} key={`tenant-${index}`} />
              ))}
            </Tenants>
          </WrappedContainer>
        </section>
      </Layout>
    )
  }
}

export default EnterprisePage

// todo: actually fetch from API (maybe?)
// or it might be okay to hard code this

const EXAMPLE_DATA = [
  {
    name: 'Petronas',
    url: null,
    image: 'https://cgs3-storage.commonground.work/directus/uploads/originals/petronas.png',
  },
  {
    name: 'GoGet',
    url: null,
    image: 'https://cgs3-storage.commonground.work/directus/uploads/originals/goget.png',
  },
  {
    name: 'Extra Space',
    url: null,
    image:
      'https://cgs3-storage.commonground.work/directus/uploads/originals/extra-space-landscape.png',
  },
  {
    name: 'F45',
    url: null,
    image: 'https://cgs3-storage.commonground.work/directus/uploads/originals/f45.png',
  },
  {
    name: 'Fitness First',
    url: null,
    image: 'https://cgs3-storage.commonground.work/directus/uploads/originals/fitness-first.png',
  },
  {
    name: 'Honestbee',
    url: null,
    image: 'https://cgs3-storage.commonground.work/directus/uploads/originals/honestbee.png',
  },
  {
    name: 'AirAsia My Corporate',
    url: null,
    image: 'https://cgs3-storage.commonground.work/directus/uploads/originals/my-corporate.png',
  },
]
