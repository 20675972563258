import React from 'react'
import styled from 'styled-components'

import Navbar from 'components/Common/Layout/Navbar'
import Footer from 'components/Common/Layout/Footer'

const PageWrap = styled.div``

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;

  // Simulate padding if filledNav prop is given
  min-height: 100vh;
  padding-top: ${props => (props.filledNav ? '120px' : '0')};
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

export const Layout = props => (
  <>
    {/* filledNav will fill the nav bar with background color and appropriately
    simulate "padding" for the content below it */}
    <Navbar filledNav={props.filledNav} forceCollapse={props.forceCollapse} />

    {/* pageWrap is used by the mobile burger menu to 'push' content off screen */}
    <PageWrap id="page-wrap">
      <Content id="content" filledNav={props.filledNav} style={props.style}>
        {props.children}
      </Content>

      {/* Footer needs to be inside pageWrap, otherwise it won't 'push' off screen */}
      <Footer />
    </PageWrap>
  </>
)

export default Layout
