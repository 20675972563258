import React from 'react'
import styled from 'styled-components'

///////////////////////////////////////////////////////////////////////////////
// Styling

const PerksWrapper = styled.div``

const Perk = styled.div`
  font-family: Calibre;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const Perks = props => (
  <PerksWrapper>
    {props.perks.map(perk => (
      <Perk>- {perk}</Perk>
    ))}
  </PerksWrapper>
)

export default Perks
