import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'

import Slide from 'components/Carousel/Slide'

import 'assets/css/slick.css'
import 'assets/css/slick-theme.css'

///////////////////////////////////////////////////////////////////////////////
// Constants

const minimumSlideHeight = '420px'

// Available settings here:
// https://react-slick.neostack.com/docs/api
const defaultSliderSettings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 7000,
}

const depthModeSliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: 0,
  dots: false,
  // responsive: [
  //   {
  //     breakpoint: 800,
  //     settings: {
  //       centerMode: false,
  //       slidesToShow: 2,
  //       slidesToScroll: 2,
  //     },
  //   },
  // ],
}

///////////////////////////////////////////////////////////////////////////////
// Styling

const StyledSlider = styled(Slider)`
  background: ${props => props.background || props.theme.cgGreen};
  height: ${props => props.height || '100vh'};
  min-height: ${minimumSlideHeight};
`

///////////////////////////////////////////////////////////////////////////////
// Component Definitions

const Carousel = ({ slides, height, background, depthMode, style = {} }) => {
  if (!slides) slides = []

  let sliderSettings = {}

  sliderSettings = Object.assign({}, defaultSliderSettings)
  if (depthMode) sliderSettings = Object.assign(sliderSettings, depthModeSliderSettings)

  return (
    <StyledSlider
      className={depthMode && 'depth-mode'}
      fade={!depthMode}
      height={height}
      background={background}
      {...sliderSettings}
      style={style.slider}
    >
      {slides.map((slide, index) => (
        <Slide
          {...slide}
          height={height}
          minHeight={minimumSlideHeight}
          key={`landing-carousel-slide-${index}`}
        />
      ))}
    </StyledSlider>
  )
}

export default Carousel
