import React from 'react'
import styled from 'styled-components'

import LeadForm from 'components/Forms/Lead'
import { H1, H5, P } from 'components/Common/Typography'

import { sleep } from 'utils'

///////////////////////////////////////////////////////////////////////////////
// Styling

const Wrapper = styled.div`
  position: fixed;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  top: 0;
  right: -100%;
  background: #fff0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease all;
  color: #000000;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0;
  overflow-y: auto;

  ${props =>
    props.active &&
    `
    right: 0;
  `}
`

const CloseButton = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  svg {
    width: inherit;
    height: inherit;
    stroke: #000000;
    stroke-width: 1;
  }

  div {
    font-size: 0.8rem;
    font-family: Acrom;
    margin: 0.5rem 0;
  }
`

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  margin: auto;
  padding: 3rem;
  display: flex;
  flex-flow: row wrap;

  @media (max-width: 800px) {
    flex-flow: column nowrap;
    padding: 1rem;
    padding-top: 5rem;
  }
`

const DescriptionContainer = styled.div`
  flex: 1;
  margin: 1rem;
`

const FormContainer = styled.div`
  flex: 1;
  margin: 1rem;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class BookingSidebar extends React.Component {
  state = {
    active: false,
    submitted: false,
  }

  ///////////////////////
  // Lifecycle methods //
  ///////////////////////

  componentDidMount() {
    window.addEventListener('keydown', e => this.handleKeydown(e))
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', e => this.handleKeydown(e))
  }

  ///////////////
  // Functions //
  ///////////////

  handleKeydown(e) {
    const { active } = this.state


    // Allow user to press Esc key to dismiss the form
    if (active && e.keyCode === 27) {
      // If Esc was pressed while these inputs were in focus, do not close the form
      if (e.target.className.includes('datepicker')) return false // DatePicker
      if (e.target.className.includes('selected-flag')) return false // CountrycodePicker
      if (e.target.id.includes('office-picker')) return false // OfficePicker

      this.toggle()
    }
  }

  async toggle() {
    try {
      const { active, submitted } = this.state

      this.setState({ active: !active })

      // Add no-scroll to <body> to disable content scrolling
      active
        ? document.body.classList.remove('no-scroll')
        : document.body.classList.add('no-scroll')

      // The user opened the BookingSidebar again, even though they've
      // submitted a form already - show the form again anyway
      if (active && submitted) {
        await sleep(1000) // Delay a bit because of hiding animation
        this.setState({ submitted: false })
      }
    } catch (err) {
      console.error(err)
    }
  }

  handleSubmitSuccess() {
    this.setState({ submitted: true })
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { active, submitted } = this.state

    return (
      <Wrapper active={active}>
        {!submitted ? (
          <Container>
            <DescriptionContainer>
              <H5>BOOK A TOUR WITH US</H5>
              <H1>WE'RE GRATEFUL FOR YOU INTEREST IN COMMON GROUND!</H1>
              <P>Fill up this form and we'll be in touch soon.</P>
            </DescriptionContainer>

            <FormContainer>
              <LeadForm onSubmitSuccess={data => this.handleSubmitSuccess(data)} />
            </FormContainer>
          </Container>
        ) : (
          <Container>
            {/* todo: do this lollerbears */}
            <DescriptionContainer style={{ textAlign: 'center' }}>
              <H1>THX LOL!</H1>
              <H5>THIS PAGE IS OBVIOUSLY NOT DONE YET :3</H5>
            </DescriptionContainer>
          </Container>
        )}

        <CloseButton onClick={() => this.toggle()}>
          <svg viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <line x1="5" x2="45" y1="5" y2="45" />
            <line x1="45" x2="5" y1="5" y2="45" />
          </svg>
          <div>ESC</div>
        </CloseButton>
      </Wrapper>
    )
  }
}

export default BookingSidebar
