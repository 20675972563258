import React from 'react'
import styled from 'styled-components'
import Flickity from 'react-flickity-component'
import Modal from 'react-modal'

///////////////////////////////////////////////////////////////////////////////
// Constants

const flickitySettings = {
  freeScroll: true,
  prevNextButtons: false,
  contain: true,
  pageDots: false,
  cellAlign: 'left',
}

///////////////////////////////////////////////////////////////////////////////
// Styling

// import from common
const Heading = styled.div`
  font-family: Acrom;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: -0.7px;
  margin-bottom: 0.5rem;
`

// import from common
const Text = styled.div`
  font-family: Calibre;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
`

const FeaturesWrapper = styled.div`
  padding: 0 ${props => props.theme.MOBILE.PAD};
  margin: 0.5rem 0;
`

const NearbyFeature = styled.div`
  width: calc(${props => props.theme.MOBILE.ICON_WIDTH});
  margin-right: ${props => props.theme.MOBILE.PAD};
  display: flex;
  flex-flow: column nowrap;
  word-break: break-all;
  text-align: center;
  align-items: center;
  font-size: 0.9rem;
`

const Icon = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background: ${props => props.theme.cgYellow};
  border-radius: 50%;
  margin-bottom: 0.5rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  margin: 3px;

  :after {
    content: '';
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    background: url(${props => props.src});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  ${props =>
    props.transparent &&
    `
    background: none;
    box-shadow: none;
    margin: 0;
  `}

  ${props =>
    props.width &&
    `
    width: ${props.width};
    padding-bottom: ${props.width};

    :after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `}
`

//// MODAL ////

// https://github.com/reactjs/react-modal
const modalStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  content: {
    background: 'none',
    border: 'none',
    borderRadius: 0,
    padding: 0,
    width: 'auto',
    // maxWidth: 'calc(100% - 3rem)', // 1.5rem * 2
    maxWidth: '100%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const NearbyFeatureModal = styled.div`
  background: #ffffff;
  padding: ${props => props.theme.MOBILE.PAD};
  border-radius: 3px;
  font-family: Calibre;
  color: ${props => props.theme.cgGreen};
  width: calc(100vw - ${props => props.theme.MOBILE.PAD} * 2);
`

const Head = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 1rem;
`

const Name = styled(Heading)`
  margin-left: 0.5rem;
  margin-bottom: 0;
`
const List = styled.div``
const ListItem = styled(Text)``

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class Features extends React.Component {
  state = {
    modalIsOpen: false,
    activeFeature: null,
  }

  ///////////////
  // Functions //
  ///////////////

  showFeature(feature) {
    this.setState({ activeFeature: feature })
    this.toggleModal()
  }

  toggleModal(state = null) {
    switch (state) {
      case 'open':
      case 'opened':
        return this.setState({ modalIsOpen: true })

      case 'close':
      case 'closed':
        return this.setState({ modalIsOpen: false })

      default:
        return this.setState({ modalIsOpen: !this.state.modalIsOpen })
    }
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { modalIsOpen, activeFeature } = this.state

    return (
      <>
        <FeaturesWrapper>
          <Flickity options={flickitySettings}>
            {this.props.features.map(feature => (
              <NearbyFeature
                key={`office-feature-${feature.name}`}
                onClick={() => this.showFeature(feature)}
              >
                <Icon src={feature.icon} />
                {feature.name}
              </NearbyFeature>
            ))}
          </Flickity>
        </FeaturesWrapper>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => this.toggleModal('close')}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          shouldReturnFocusAfterClose
          style={modalStyles}
        >
          {activeFeature && (
            <NearbyFeatureModal>
              <Head>
                <Icon src={activeFeature.icon} width="2rem" transparent />
                <Name>{activeFeature.name}</Name>
              </Head>
              <List>
                {activeFeature.list.split(',').map(item => (
                  <ListItem key={`active-feature-list-item-${item.trim()}`}>{item.trim()}</ListItem>
                ))}
              </List>
            </NearbyFeatureModal>
          )}
        </Modal>
      </>
    )
  }
}

export default Features
