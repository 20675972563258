import React from 'react'
import Helmet from 'react-helmet'
import querystring from 'querystring'
import AnimateHeight from 'react-animate-height'
import { IoIosArrowDown } from 'react-icons/io'
import { clamp } from 'lodash'

import { ReactComponent as CrownLogo } from './images/crown.svg'

import { getLeaderboardUsers } from './api'

//
// ─── CONSTANTS ──────────────────────────────────────────────────────────────────
//

const CG_YELLOW = '#ffcd65'
const CG_GREEN = '#194239'

const TERMS = [
  'This contest is open to Common Ground’s active members in Malaysia only. If your membership with Common Ground Malaysia expires and is not renewed by the time the contest ends, you will not qualify to participate in this contest.',
  'One person is entitled to one prize only.',
  'Contest period starts at 2:00 PM on 12th November 2020 and ends at 6:00 PM on 2nd December 2020.',
  'All CG Points earned will be transferred to winners within 24 hours.',
  <span>
    CG Points rewarded will expire by <b>January 31st, 2021, 11:59 pm</b>.
  </span>,
  'Winners will be announced one week after the contest closes and the results are not disputable.',
  'Winners will be decided based on number of pictures guessed correctly. If there is a tie, whoever submits all their answers first amongst the tie, will be the winner.',
  'Common Ground reserve the right to substitute any prize with another of equivalent value without giving notice.',
  'Any images or pictures are for illustrative purposes only and do not represent actual prize given.',
  'Common Ground members are not entitled to win two consecutive app contests in a row.',
  'Common Ground employees are not eligible to enter the contest. Common Ground will determine eligibility at its sole discretion upon entering the contest.',
  'By participating in this contest, you agree for Common Ground to contact you in order to process and award you the prizes, should you be one of the winners.',
]

//
// ─── CHANGE ─────────────────────────────────────────────────────────────────────
//

const Change = ({ direction = '', size = '6px' }) => {
  return <div style={{ width: '1rem' }} />

  // return (
  //   <>
  //     <div className={`change ${direction}`} />

  //     <style jsx>{`
  //       .change {
  //         border: solid transparent;
  //         border-width: ${`${size}`};
  //       }

  //       .up {
  //         display: inline-block;
  //         border-bottom: solid green;
  //         border-bottom-width: ${`calc(${size} * 1.5)`};
  //         margin-top: ${`-${size}`};
  //         margin-bottom: ${`${size}`};
  //       }

  //       .down {
  //         display: inline-block;
  //         border-top: solid red;
  //         border-top-width: ${`calc(${size} * 1.5)`};
  //       }
  //     `}</style>
  //   </>
  // )
}

//
// ─── TOP USERS ──────────────────────────────────────────────────────────────────
//

const TopUsers = ({ users = [] }) => (
  <div className="top-users">
    <div className="second">
      <TopUser {...users[1]} rank={2} imageSize="30vw" />
    </div>

    <div className="first">
      <TopUser {...users[0]} rank={1} imageSize="35vw" />
      <div className="spacer" />
    </div>

    <div className="third">
      <TopUser {...users[2]} rank={3} imageSize="30vw" />
    </div>

    <style jsx>{`
      .top-users {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: flex-end;

        .first {
          z-index: 30;

          .spacer {
            height: 5vw;
            max-height: 2.5rem;
          }
        }

        .second {
          z-index: 20;
          margin-right: -5vw;
        }

        .third {
          z-index: 10;
          margin-left: -5vw;
        }
      }
    `}</style>
  </div>
)

//
// ─── TOP USER ───────────────────────────────────────────────────────────────────
//

const TopUser = ({
  name = 'TBD',
  points = 0,
  rank = '-',
  direction = '',
  imageUrl,
  imageSize = '100px',
}) => {
  return (
    <div className="top-user">
      <div className="rank">{rank}</div>

      <Change direction={direction} />

      {rank === 1 && (
        <div className="crown-container">
          <CrownLogo style={{ maxWidth: '100px', width: '15vw', height: 'auto' }} />
        </div>
      )}

      <div className="image-container">
        <img src={imageUrl || require('./images/placeholder-user.png')} alt="User" />
      </div>

      <div className="name">
        <span className="max-lines-2">{name}</span>
      </div>

      <div className="points">
        <Points value={points} />
      </div>

      <style jsx>{`
        .top-user {
          position: relative;
          display: inline-flex;
          flex-flow: column nowrap;
          align-items: center;
          text-align: center;
          font-weight: bold;
          font-family: Acrom;

          .rank {
            font-size: 1.3rem;
          }

          .crown-container {
          }

          .image-container {
            width: ${imageSize};
            height: ${imageSize};
            max-width: 200px;
            max-height: 200px;
            overflow: hidden;
            border-radius: 50%;
            border: 5px solid ${CG_YELLOW};
            background: #ffffff;
            margin-bottom: 0.5rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .name {
            display: flex;
            justify-content: center;
            align-items: center;
            width: ${`calc(${imageSize} * 0.8)`};
            max-width: 160px;
            min-height: 2rem;

            span {
              font-size: 0.85rem;
              letter-spacing: -1px;
              line-height: 1;
            }
          }

          .points {
            font-family: Calibre;
            font-size: 1rem;
          }
        }
      `}</style>
    </div>
  )
}

//
// ─── USER LIST ──────────────────────────────────────────────────────────────────
//

const UserList = ({ users }) => {
  const SHOW_COLLAPSER_THRESHOLD = 5
  const SINGLE_ROW_HEIGHT = 62

  const [collapsed, setCollapsed] = React.useState(true)
  const collapsedRowsVisible = clamp(users.length, 0, SHOW_COLLAPSER_THRESHOLD)

  return (
    <>
      <AnimateHeight
        duration={200}
        height={collapsed ? collapsedRowsVisible * SINGLE_ROW_HEIGHT : 'auto'}
        className="height-animator"
      >
        <div
          className={`user-list ${
            users.length > SHOW_COLLAPSER_THRESHOLD && collapsed ? 'collapsed' : 'expanded'
          }`}
        >
          {users.map(({ name, rank, imageUrl, points, direction }, index) => (
            <div className="user" key={`user-list-item-${index}`}>
              <div className="rank">
                <span>{rank}</span>
                <Change direction={direction} />
              </div>

              <div className="image-container">
                <img src={imageUrl || require('./images/placeholder-user.png')} alt="User" />
              </div>

              <div className="name max-lines-2">{name}</div>

              <div className="spacer" />

              <div className="points">
                <Points value={points} />
              </div>
            </div>
          ))}
        </div>
      </AnimateHeight>

      {users.length > SHOW_COLLAPSER_THRESHOLD && (
        <div className="collapse-toggler">
          <button onClick={() => setCollapsed(!collapsed)}>
            <IoIosArrowDown size={16} />
          </button>
        </div>
      )}

      <style jsx global>{`
        .height-animator {
          margin: 0 -1rem; // handle shadows being clipped
        }
      `}</style>

      <style jsx>{`
        .user-list {
          position: relative;
          overflow: hidden;
          padding: 0 1rem; // handle shadows being clipped

          .user {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            background-color: #ffffff;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            padding: 0.5rem 0.75rem;
            margin-bottom: 0.66rem;
            font-family: Acrom;
            font-weight: bold;

            .rank {
              display: flex;
              flex-flow: column nowrap;
              align-items: center;
              font-size: 1.2rem;
              margin-right: 0.5rem;
            }

            .image-container {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              overflow: hidden;
              background-color: #ffffff;
              flex-shrink: 0;
              margin-right: 0.5rem;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .name {
              font-size: 0.95rem;
              line-height: 1;
              letter-spacing: -1px;
              margin-right: 1rem;
            }

            .spacer {
              flex: 1;
            }

            .points {
              font-family: Calibre;
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              background-color: ${CG_YELLOW};
            }
          }
        }

        .collapse-toggler {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          &:before {
            content: '';
            position: absolute;
            top: -50px;
            left: 0;
            right: 0;
            height: 50px;
            background: ${collapsed
              ? `linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0.75), rgba(255,255,255, 0.01))`
              : 'transparent'};
          }

          button {
            padding: 0.33rem 1.5rem;
            border: none;
            background: none;
            color: inherit;
            transition: 0.4s ease all;
            transform: ${collapsed ? 'none' : 'rotateZ(180deg)'};
          }
        }
      `}</style>
    </>
  )
}

//
// ─── POINTS ─────────────────────────────────────────────────────────────────────
//

const Points = ({ value = 0, size = '1rem' }) => (
  <div className="points">
    <img src={require('./images/points.png')} alt="Points icon" />

    <div className="value">{value}</div>

    <style jsx>{`
      .points {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        img {
          width: ${size};
          height: ${size};
          margin-right: 3px;
        }

        .value {
          height: ${size};
          line-height: 1;
          font-size: ${size};
          font-weight: 800;
          opacity: 0.7;
        }
      }
    `}</style>
  </div>
)

//
// ─── TERMS ──────────────────────────────────────────────────────────────────────
//

const Terms = () => {
  const [collapsed, setCollapsed] = React.useState(true)

  return (
    <>
      <AnimateHeight duration={200} height={collapsed ? 300 : 'auto'}>
        <ul className={`terms ${collapsed ? 'collapsed' : 'expanded'}`}>
          {TERMS.map((term, index) => (
            <li key={`terms-item-${index}`}>{term}</li>
          ))}
        </ul>
      </AnimateHeight>

      <div className="collapse-toggler">
        <button onClick={() => setCollapsed(!collapsed)}>
          <IoIosArrowDown size={16} />
        </button>
      </div>

      <style jsx>{`
        .terms {
          position: relative;
          overflow: hidden;
          margin: 0;

          li {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .collapse-toggler {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          &:before {
            content: '';
            position: absolute;
            top: -50px;
            left: 0;
            right: 0;
            height: 50px;
            background: ${collapsed
              ? `linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0.75), rgba(255,255,255, 0.01))`
              : 'transparent'};
          }

          button {
            padding: 0.33rem 1.5rem;
            border: none;
            background: none;
            color: inherit;
            transition: 0.4s ease all;
            transform: ${collapsed ? 'none' : 'rotateZ(180deg)'};
          }
        }
      `}</style>
    </>
  )
}

//
// ─── FLOATING ASSISTANT ─────────────────────────────────────────────────────────
//

const FloatingAssistant = ({ disabled = false }) => {
  if (disabled || !window) return null

  const HEIGHT = '85px'

  const onClickTakeMeToMissions = () => {
    try {
      if (window) {
        const message = JSON.stringify({
          deeplink: 'https://commonground.work/me/missions',
        })

        if (window.postMessage) {
          window.postMessage(message, '*')
        }

        // Support newer versions of react-native-webview
        if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
          window.ReactNativeWebView.postMessage(message)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="floating-assistant">
      <div className="empty-space" />

      <div className="panel">
        <button onClick={onClickTakeMeToMissions}>
          <p>Take me to missions</p>
        </button>
      </div>

      <style jsx>{`
        .floating-assistant {
          position: relative;
          margin-top: -1rem;

          .empty-space {
            height: ${HEIGHT};
          }

          .panel {
            position: fixed;
            height: ${HEIGHT};
            width: 100%;
            max-width: 1000px;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;

            button {
              width: 100%;
              height: 100%;
              padding: 0;
              border: none;
              background: ${CG_YELLOW};
              color: ${CG_GREEN};
              border-radius: 3px;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

              &:active {
                opacity: 0.8;
                cursor: pointer;
              }
            }
          }
        }
      `}</style>
    </div>
  )
}

//
// ─── COMPONENT DEFINITION ───────────────────────────────────────────────────────
//

class PhotoContestPage extends React.Component {
  constructor(props) {
    super(props)

    const urlSearchParams = querystring.parse(props.history.location.search.substr(1))

    this.state = {
      error: null,
      loading: false,
      users: [],
      env: urlSearchParams.env || 'production',
      showButton: urlSearchParams.showButton || false,
    }
  }

  ///////////////
  // Lifecycle //
  ///////////////

  async componentDidMount() {
    try {
      const { env } = this.state

      await this.setState({ loading: 'getLeaderboardUsers' })

      const { users } = await getLeaderboardUsers({ env: env })

      await this.setState({ loading: false, users: users })
    } catch (error) {
      console.error(error)

      await this.setState({ loading: false, users: [] })
    }
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { users, loading, showButton } = this.state

    return (
      <>
        <Helmet>
          <title>Benefits | Common Ground</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
          <style>{`
            html {
              font-size: 16px;
              color: ${CG_GREEN};
              background: #ffffff;
            }

            h2 {
              font-family: Acrom;
              letter-spacing: -1px;
            }

            span,
            p {
              font-size: 1rem;
              line-height: 1.1;
            }

            ul {
              padding-left: 1.5rem;
            }

            li {
              font-size: 1rem;
              line-height: 1.1;
              margin-bottom: 1rem;
            }

            button:focus {
              outline: 0;
            }
          `}</style>
        </Helmet>

        <div style={{ backgroundColor: '#ea4f38' }}>
          <div className="container">
            <img alt="banner" src={require('./images/banner.jpg')} style={{ maxWidth: '100%' }} />
          </div>
        </div>

        <div className="container">
          <div className="content">
            <TopUsers users={users} />

            <div className="pb-4" />

            <UserList users={users} />

            <div className="pb-3" />

            <h2>How to Play?</h2>

            <ul>
              <li>Complete a mission under My Mission.</li>

              <li>Click “Claim Prize” to unlock a pop-up image.</li>

              <li>
                Name the object in the image. You’ll have to guess within the number of letters
                corresponding to the number of boxes below the image. You'll have one try per image.
              </li>

              <li>
                Take caution as to not close the popup as <b>all progress will be lost</b>, and to
                retry the challenge you’ll have to do another mission.
              </li>

              <li>One (1) contest point is given for every correct picture you name.</li>

              <li>The more pictures you name, the more contest points you earn.</li>

              <li>
                The more missions you complete, the more opportunities you get to name the pic.
              </li>

              <li>
                Contest points of top scorers will be displayed on the scoreboard that tracks live
                update of contest points earned from the ‘Name the pic’ contest.
              </li>

              <li>Have fun competing alongside the community!</li>
            </ul>

            <div className="pb-2" />

            <h2>Prize Structure</h2>

            <p>1 winner x 40,000 Points Grand Prize</p>

            <p>2 winners x 20,000 Points per Winner</p>

            <p>5 Winners x 8,000 Points per Winner</p>

            <p>20 Winners x 2,000 Points per Winner</p>

            <div className="pb-2" />

            <h2>Terms & Conditions</h2>

            <Terms />
          </div>

          <div className={`loading-overlay ${loading === 'getLeaderboardUsers' ? '' : 'hidden'}`} />

          <FloatingAssistant disabled={!showButton} />
        </div>

        <style jsx>{`
          .container {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            max-width: 1000px;
            flex-flow: column nowrap;
            padding: 0;
          }

          .content {
            padding: 1.33rem;
          }

          .rewards {
            td {
              padding-bottom: 0.5rem;
            }

            td:nth-child(2) {
              padding-left: 2.5rem;
            }
          }

          .loading-overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #ffffff;
            z-index: 99999;
            opacity: 1;
            transition: 0.66s ease all;

            &.hidden {
              opacity: 0;
              pointer-events: none;
            }
          }
        `}</style>
      </>
    )
  }
}

export default PhotoContestPage
