module.exports = {
  cgYellow: '#ffcd66', // Sunshine yellow (Pantatone 141u)
  cgGreen: '#194239', // Tropical green (Pantatone 561u)
  cgGunmetal: '#0F1322', // Dark gunmetal
  cgGunmetalLight: '#333333', // 80% K

  MOBILE: {
    PAD: '16px',
    BORDER_RADIUS: '3px',
    ICON_WIDTH: '60px',
  },
}
