import React from 'react'
import styled from 'styled-components'

import WrappedContainer from 'components/Common/WrappedContainer'
import { H1, H3, H5 } from 'components/Common/Typography'

///////////////////////////////////////////////////////////////////////////////
// Styling

const LeftBlock = styled.div`
  flex: 1;
  margin-right: 3rem;

  @media (max-width: 800px) {
    min-width: 100%;
    margin: auto auto 2rem auto;
  }
`

const RightBlock = styled.div`
  position: relative;
  flex: 3;

  @media (max-width: 800px) {
    text-align: center;
  }
`

const Image = styled.div`
  width: 210px;
  height: 210px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
    display: inline-block;
    object-fit: cover;
    background: #d3d3d3;
  }

  @media (max-width: 800px) {
    width: 180px;
    height: 180px;
  }
`

const Title = styled(H1)``

const Text = styled.div`
  font-family: Calibre;
  font-size: 1.1rem;
  line-height: 1.8rem;
`

const P = styled.div`
  margin: 1rem 0;
  white-space: pre-line;
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

const TextBlock = ({ maxWidth, image, pretitle, title, subtitle, text, style = {}, children }) => (
  <WrappedContainer
    maxWidth={maxWidth}
    style={{ container: { flexFlow: 'row wrap', ...style.container } }}
  >
    {image && (
      <LeftBlock>
        <Image>
          <img src={image} alt={title || pretitle || subtitle} />
        </Image>
      </LeftBlock>
    )}

    <RightBlock>
      {pretitle && <H5 style={style.pretitle}>{pretitle}</H5>}

      {title && <Title style={style.title}>{title}</Title>}

      {subtitle && <H3 style={style.subtitle}>{subtitle}</H3>}

      {text && (
        <Text style={style.text}>
          {Array.isArray(text) ? (
            text.map((paragraph, index) => (
              <P
                style={style.paragraph}
                key={`text-block-${pretitle || subtitle || index}-${title}`}
              >
                {paragraph}
              </P>
            ))
          ) : (
            <P style={style.paragraph}>{text}</P>
          )}
        </Text>
      )}

      {children}
    </RightBlock>
  </WrappedContainer>
)

export default TextBlock
