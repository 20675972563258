import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Layout from 'components/Common/Layout'
import LocationsList from 'components/Locations/LocationsList'
import GoogleMapsExplorer from 'components/Locations/GoogleMapsExplorer'

///////////////////////////////////////////////////////////////////////////////
// Styling

const ListingContainer = styled.div`
  width: 100%;
  max-width: 66%;

  @media (max-width: 800px) {
    max-width: unset;
  }
`

const GoogleMapsContainer = styled.div`
  position: fixed;
  top: 65px; // account for navbar
  bottom: 0;
  right: 0;
  width: 34%;

  @media (max-width: 800px) {
    display: none;
  }
`

///////////////////////////////////////////////////////////////////////////////
// Component Definition

class LocationsPage extends React.Component {
  constructor() {
    super()

    this.state = {
      offices: null,
      selectedOffice: null,
    }
  }

  ///////////////////////
  // Lifecycle Methods //
  ///////////////////////

  async componentDidMount() {
    try {
      let { data } = await fetch(`${process.env.REACT_APP_API_URL}/custom/offices`).then(res =>
        res.json()
      )

      if (!data || data.length <= 0) {
        throw new Error('Error fetching office list')
      }

      this.setState({ offices: data, selectedOffice: data[0] })
    } catch (err) {
      console.error(err)
    }
  }

  ///////////////
  // Functions //
  ///////////////

  onOfficeHover(office) {
    this.setState({ selectedOffice: office })
  }

  ////////////
  // Render //
  ////////////

  render() {
    const { offices, selectedOffice } = this.state

    return (
      <Layout forceCollapse>
        <Helmet>
          <title>Locations | Common Ground</title>
          <style>{`
            #navbar {
              background: #ffffff;
            }
            #navbar>div {
              max-width: unset;
            }
          `}</style>
        </Helmet>

        {/* todo: handle if failed to load offices (some failed indication) */}
        <section id="locations-list">
          <ListingContainer>
            {offices && (
              <LocationsList
                offices={offices}
                onOfficeHover={location => this.onOfficeHover(location)}
              />
            )}
          </ListingContainer>
        </section>

        <section id="locations-explorer">
          <GoogleMapsContainer>
            <GoogleMapsExplorer selectedOffice={selectedOffice} />
          </GoogleMapsContainer>
        </section>
      </Layout>
    )
  }
}

export default LocationsPage
